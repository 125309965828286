/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2019-11-26 19:47:10
 */
import React from "react";
import { Tree, message } from "antd";
import { get } from "@/requests/index";
import "./menuTree.less";

class MenuTree extends React.Component {
    state = {
        treeData: [],
        checkedKeys: [],
        // 解决选中的bug
        parentKeys: [],
        // 初始化数据
        initKeys: false,
        // 初始化半选
        halfCheckedKeys: [],
    };
    getTreeDataByRoleId = (id) => {
        get("auth/menu/tree" + (id ? "/" + id : "")).then((res) => {
            if (res.code) {
                const { children } = res.data;
                // 格式化数据
                const targetData = this.formatTreeData(children);
                const idsArr = [];
                const parentKeys = [];
                this.getAllCheckedNode(idsArr, targetData, parentKeys);
                this.setState({
                    treeData: targetData,
                    checkedKeys: idsArr,
                    parentKeys,
                    initKeys: true,
                });
                this.props.onChange(idsArr);
            } else {
                message.error(res.data || "请求数据失败");
            }
        });
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.roleId === this.props.roleId) {
            return;
        }
        const roleId = nextProps.roleId;
        this.getTreeDataByRoleId(roleId);
    }
    componentDidMount() {
        this.props.onRef(this);
        const roleId = this.props.roleId;
        this.getTreeDataByRoleId(roleId);
    }
    formatTreeData = (data) => {
        let targetData = [];
        data &&
            data.forEach((ele) => {
                ele.key = ele.value = ele.id;
                ele.title = ele.text;
                if (ele.children && ele.children.length) {
                    this.formatTreeData(ele.children);
                }
                targetData.push(ele);
            });

        return targetData;
    };
    // 获取所有节点checked为true的节点id
    getAllCheckedNode = (targetIds, data, parentKeys) => {
        data &&
            data.forEach((ele) => {
                // 如果chiidren 的长度为0，并且checked为true才加入选中数据（单个菜单）
                if (ele.checked && !ele.children.length) {
                    targetIds.push(ele.id);
                }
                // 将选中的父级挑选出来
                if (ele.checked && parentKeys) {
                    parentKeys.push(ele.id);
                }

                if (ele.children && ele.children.length) {
                    this.getAllCheckedNode(targetIds, ele.children);
                }
            });
    };

    onCheck = (checkedKeys, info) => {
        this.setState({
            checkedKeys: checkedKeys,
            initKeys: false,
            halfCheckedKeys: info.halfCheckedKeys,
        });
        // 将选择的数据返回到父组件
        this.props.onChange([...checkedKeys, ...info.halfCheckedKeys]);
    };
    getMenuIds = () => {
        let { parentKeys, checkedKeys, initKeys, halfCheckedKeys } = this.state;
        if (initKeys) {
            // 将选中的父级和子级合并并去重复 （解决）
            let concatArr = new Set(
                parentKeys.concat(checkedKeys).concat(halfCheckedKeys)
            );
            // 将set集合转换成arr
            concatArr = Array.from(concatArr);
            // 将选择的数据返回到父组件
            this.props.onChange(concatArr);
        } else {
            // 合并半选和全选
            this.props.onChange([...checkedKeys, ...halfCheckedKeys]);
        }
    };
    render() {
        const { treeData, checkedKeys } = this.state;
        return (
            <Tree
                className="nms-menu-tree"
                checkable
                treeData={treeData}
                onCheck={this.onCheck}
                checkedKeys={checkedKeys}
            />
        );
    }
}
export default MenuTree;
