/* eslint-disable no-unused-vars */
/**
 * Created by chenll
 * DATE: 2019/09/03
 * Description: 通用工具类
 */
 import React from 'react';
 import { portReg, IpReg } from '@/utils/validateReg';
 import {
     Select,
     Input,
     TreeSelect,
     message,
     InputNumber,
     DatePicker,
 } from 'antd';
 import { get } from '@/requests';
 
 const { Option } = Select;
 // 告警状态映射
 export const ALARM_LEVEL = {
     '1': '轻微',
     '2': '警告',
     '3': '严重',
     '4': '故障',
     '5': '灾难',
 };
 // 处理状态(1未分配，2待处理，3已领受(处理中)，4.已回退，5已完成)
 // 告警处理状态映射
 export const DEAL_STATUS = {
     '1': '未分配',
     '2': '待处理',
     '3': '已领受',
     '4': '已回退',
     '5': '已完成',
     '0': '待审批',
     '-1': '审批拒绝',
 };
 // 将 #01ff64 转成 rgba(1,255,100,
 // "#01ff64".toRgba() + '.5)'
 String.prototype.toRgba = function () {
     let color = this.toLowerCase();
     let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
     if (reg.test(color)) {
         if (color.length === 4) {
             let newColor = '#';
             for (let i = 1; i < 4; i++) {
                 newColor += color.slice(i, i + 1).concat(color.slice(i, i + 1));
             }
             color = newColor;
         }
         let changeColor = [];
         for (let i = 1; i < 7; i += 2) {
             changeColor.push(parseInt('0x' + color.slice(i, i + 2)));
         }
         return `rgba(${changeColor.join(',')},`;
     } else return color;
 };
 // 将 rgb(0, 255, 100) 的格式转为 #01ff64
 // "rgb(0, 255, 100)".toHex()
 String.prototype.toHex = function () {
     let reg = /^(RGB|rgb)/;
     let color = this;
     if (reg.test(color)) {
         let strHex = '#';
         let colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',');
         for (let i = 0; i < colorArr.length; i++) {
             let hex = Number(colorArr[i]).toString(16);
             if (hex.length === 1) hex = '0' + hex;
             if (hex === '0') {
                 hex += hex;
             }
             strHex += hex;
         }
         return strHex;
     } else return String(color);
 };
 
 // 转hash
 String.prototype.toHash = function () {
     let hash = 0, i, chr;
     if (this.length === 0) return hash;
     for (i = 0; i < this.length; i++) {
         chr = this.charCodeAt(i);
         hash = ((hash << 5) - hash) + chr;
         hash |= 0;
     };
     return hash;
 };
 
 // eslint-disable-next-line no-extend-native
 Date.prototype.format = function (fmt) {
     let o = {
         'M+': this.getMonth() + 1, // 月份
         'd+': this.getDate(), // 日
         'h+': this.getHours(), // 小时
         'm+': this.getMinutes(), // 分
         's+': this.getSeconds(), // 秒
         'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
         S: this.getMilliseconds(), // 毫秒
     };
     if (/(y+)/.test(fmt)) {
         fmt = fmt.replace(
             RegExp.$1,
             (this.getFullYear() + '').substr(4 - RegExp.$1.length)
         );
     }
     for (let k in o) {
         if (new RegExp('(' + k + ')').test(fmt)) {
             fmt = fmt.replace(
                 RegExp.$1,
                 RegExp.$1.length === 1
                     ? o[k]
                     : ('00' + o[k]).substr(('' + o[k]).length)
             );
         }
     }
     return fmt;
 };
 
 //数字泛化
 export const normalize = (val, max, min) => {
     if (max - min === 0) return 1;
     return (val - min) / (max - min);
 };
 
 export const arrayChunk = (arr, size) => {
     let newArr = [];
     for (let i = 0; i < arr.length; i += size) {
         newArr.push(arr.slice(i, i + size));
     }
 
     return newArr;
 };
 
 /**
  * @description: 节流
  * @param {*} fn
  * @param {*} delay
  * @return {*}
  */
 export function throttle(fn, delay) {
     let prev = Date.now();
     return function () {
         let ctxt = this;
         let args = arguments;
         let now = Date.now();
         if (now - prev >= delay) {
             fn.apply(ctxt, args);
             prev = Date.now();
         };
     };
 };
 export const generateGUID = (len = 12, _radix = 16) => {
     const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
         ''
     );
     const uuid = [];
     let radix = _radix || chars.length;
 
     if (len) {
         // Compact form
         for (let i = 0; i < len; i++)
             uuid[i] = chars[0 | (Math.random() * radix)];
     } else {
         // rfc4122, version 4 form
         let r;
 
         // rfc4122 requires these characters
         uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
         uuid[14] = '4';
 
         // Fill in random data.  At i==19 set the high bits of clock sequence as
         // per rfc4122, sec. 4.1.5
         for (let i = 0; i < 36; i++) {
             if (!uuid[i]) {
                 r = 0 | (Math.random() * 16);
                 uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
             }
         }
     }
 
     return uuid.join('');
 };
 
 export const getUrlkey = url => {
     let params = {},
         arr = url.split('?');
     if (arr.length <= 1) return url;
     arr = arr[1].split('&');
     for (let i = 0, l = arr.length; i < l; i++) {
         let a = arr[i].split('=');
         params[a[0]] = a[1];
     }
     return params;
 };
 
 export const encodeUri = data => {
     let arr = [];
     for (let item in data) {
         arr.push(item + '=' + data[item]);
     }
     return arr.join('&');
 };
 
 export const stringDivider = (str, width, spaceReplacer) => {
     if (str.length > width) {
         let p = width;
         while (p > 0 && str[p] !== ' ' && str[p] !== '-') {
             p--;
         }
         if (p > 0) {
             let left;
             if (str.substring(p, p + 1) === '-') {
                 left = str.substring(0, p + 1);
             } else {
                 left = str.substring(0, p);
             }
             let right = str.substring(p + 1);
             return (
                 left +
                 spaceReplacer +
                 stringDivider(right, width, spaceReplacer)
             );
         }
     }
     return str;
 };
 
 export const setReqDateParam = days => {
     let endDateValue = new Date();
     let startDateValue = new Date(
         endDateValue.getTime() - days * 24 * 60 * 60 * 1000
     );
     return {
         start: new Date(startDateValue).format('yyyy-MM-dd hh:mm:ss'),
         end: new Date(endDateValue).format('yyyy-MM-dd hh:mm:ss'),
     };
 };
 
 export const replaceSpecialChar = str => {
     const specialCharArr = ['\\+', '/', '\\?', '%', '#', '&', '=', ' '];
     specialCharArr.forEach(item => {
         let obj = {
             '+': '%2B',
             '/': '%2F',
             '?': '%3F',
             '%': '%25',
             '#': '%23',
             '&': '%26',
             '=': '%3D',
             ' ': '%20',
         };
         const reg = new RegExp(item, 'i');
         str = str.toString().replace(new RegExp(reg, 'g'), obj[item]);
     });
     return str;
 };
 
 // 设置cookie
 export const setCookie = (name, value, day) => {
     let date = new Date();
     date.setDate(date.getDate() + day);
     // document.cookie = name + '=' + value + ';expires=' + date;
     localStorage.setItem(name,value)
 };
 
 //删除cookie
 export const delCookie = name => {
     setCookie(name, null, -1);
 };
 
 //获取cookie
 export const getCookie = name => {
     return localStorage.getItem(name)
     // let reg = RegExp(name + '=([^;]+)');
     // // let arr = document.cookie.match(reg);
     // let arr = localStorage.getItem('cookie')?.match(reg) ?? [];
     // console.log('bbb', arr)
     // if (arr) {
     //     return arr[1];
     // } else {
     //     return '';
     // }
 };
 // 获取端口
 export const getPort = () => {
     return window.location.port;
 };
 // 获取表格高度
 export const getTableH = () => {
     const bodyH = window.document.documentElement.offsetHeight;
     const topToolBarHeight = 65; //顶部工具栏的固定高度
     const containerHeight = bodyH - topToolBarHeight; //右侧容器的计算高度
     const containerTitleH = 30; //右侧容器顶部标题栏高度
 
     return containerHeight - containerTitleH - 150;
 };
 
 // 获取数组中的id 并返回数组id
 export const getIdArray = arr => {
     let targetArr = [];
     arr &&
         arr.forEach(element => {
             element.id && targetArr.push(element.id * 1);
         });
     return targetArr;
 };
 
 // 格式化时间函数 20191021181351
 export const formatterDate = dateStr => {
     try {
         if (!dateStr || (dateStr + '').length < 14) {
             return '--';
         }
         dateStr = dateStr + '';
         // 年
         let years = dateStr.slice(0, 4);
         // 月
         let month = dateStr.slice(4, 6);
         // 日
         let day = dateStr.slice(6, 8);
         // 时
         let hours = dateStr.slice(8, 10);
         // 分
         let minutes = dateStr.slice(10, 12);
         // 秒
         let seconds = dateStr.slice(12, 14);
         return (
             years +
             '-' +
             month +
             '-' +
             day +
             ' ' +
             hours +
             ':' +
             minutes +
             ':' +
             seconds
         );
     } catch (e) {
         return '--';
     }
 };
 
 
 // 生成树形菜单
 export const transTreeData = (data, rootId) => {
     if (data.length > 0) {
         let curPid = rootId; //curPid=0，为最上层节点 ，即无父节点
         let parent = findChild(data, curPid); //数组
         return parent;
     } else {
         return [];
     }
 };
 
 //找子节点
 const findChild = (data, curPid) => {
     let _arr = [];
     let items = data;
     let length = items.length;
     for (let i = 0; i < length; i++) {
         if (String(items[i].pId) === String(curPid)) {
             let _obj = items[i];
             _obj.children = findChild(data, _obj.templateNo);
             _arr.push(_obj);
         }
     }
     return _arr;
 };
 // 在扩展字段里面获取对应值
 export const getValueFromExpand = (record, fieldKey) => {
     let { expandList } = record;
     if (!expandList) {
         return '--';
     }
     let expandListObj = JSON.parse(expandList);
     let targetAttr = expandListObj.find(item => item.key === fieldKey);
     if (!targetAttr) {
         return '--';
     }
     let result = targetAttr.value;
     try {
         if (Object.prototype.toString.call(JSON.parse(result)) === '[object Array]') {
             result = JSON.parse(result).join(',');
         }
         if (Object.prototype.toString.call(JSON.parse(result)) === '[object object]') {
             result = JSON.parse(result).label;
         }
     } catch (e) { }
 
     return result;
 };
 // 判断扩展字段里面有没有某特定字段
 export const hasFieldInExpandList = (expandList = {}, fieldName) => {
     let allArr = [];
     Object.keys(expandList).forEach(ele => {
         allArr.push(...expandList[ele]);
     });
     return !!allArr.find(ele => ele.key === fieldName);
 };
 
 // 根据字段名称在资产数据里面找到对应的值
 export const getValueFormInfo = (fieldName, record) => {
     if (Object.keys(record).length === 0) return '';
     // 所有属性
     let allAttr = {};
 
     let { baseEntity, expandEntityMap } = record;
     let expandAttrArr = expandEntityMap && Object.values(expandEntityMap);
     expandAttrArr &&
         expandAttrArr.forEach(item => {
             item.forEach(ele => {
                 allAttr[ele.key] = ele.value;
             });
         });
     allAttr = { ...baseEntity, ...allAttr };
     return allAttr[fieldName];
 };
 // 根据扩展字段生成表格数据
 export const generateExpandTable = (list, fieldArray, expandName) => {
     let expandArray = fieldArray;
     let dataSource = [];
     if (list.length) {
         list.forEach((item, index) => {
             dataSource[index] = {
                 key: index,
             };
             for (let key in item) {
                 if (expandArray.includes(key)) {
                     dataSource[index][key] = item[key];
                 }
             }
             if (expandName) {
                 let expandList = JSON.parse(item[expandName] || '[]');
                 expandList.forEach(value => {
                     if (expandArray.includes(value.key)) {
                         dataSource[index][value.key] = value.value;
                     }
                 });
             }
         });
     }
 
     return dataSource;
 };
 
 // 获取元素相对位置
 export const getElementPosition = ele => {
     let l = ele.offsetLeft;
     let t = ele.offsetTop;
 
     let parent = ele.offsetParent;
 
     while (parent.tagName !== 'BODY') {
         l += parent.clientLeft + parent.offsetLeft;
         t += parent.clientTop + parent.offsetTop;
         parent = parent.parentElement;
     }
 
     return {
         l,
         t,
     };
 };
 
 // 生成告警等级组件
 export const getAlarmLevel = alarmLevel => {
     return ALARM_LEVEL[alarmLevel] ? (
         <span state={alarmLevel} className=" state-btn state">
             <i
                 className="fa kdfont fa-exclamation-triangle"
                 title={ALARM_LEVEL[alarmLevel]}
             ></i>
             {ALARM_LEVEL[alarmLevel]}
         </span>
     ) : (
         ''
     );
 };
 
 export const alarmLegend = [
     { title: '不告警', value: 0, color: '#f0f0f0' },
     { title: '轻微', value: 1, color: '#30bbbb' },
     { title: '告警', value: 2, color: '#aaaa0f' },
     { title: '严重', value: 3, color: '#d99818' },
     { title: '故障', value: 4, color: '#da5225' },
     { title: '灾难', value: 5, color: '#b40b0b' },
 ];
 
 /**
  * 全屏方法 兼容canvas
  * @param {HTMLElement} element 原生dom元素
  */
 export const requestFullScreen = async element => {
     if (!element) return;
     if (element.requestFullscreen) {
         await element.requestFullscreen();
     } else if (element.msRequestFullscreen) {
         await element.msRequestFullscreen();
     } else if (element.mozRequestFullScreen) {
         await element.mozRequestFullScreen();
     } else if (element.webkitRequestFullscreen) {
         await element.webkitRequestFullscreen();
     }
 };
 
 /**
  * 退出全屏 兼容canvas
  */
 export const exitFullScreen = () => {
     if (document.cancelFullScreen) {
         document.cancelFullScreen();
     } else if (document.mozCancelFullScreen) {
         document.mozCancelFullScreen();
     } else if (document.webkitCancelFullScreen) {
         document.webkitCancelFullScreen();
     } else if (document.msExitFullscreen) {
         document.msExitFullscreen();
     }
 };
 
 // 两位数补零
 export const zeroFill = num => {
     if (num >= 10) {
         return '' + num;
     } else {
         return 0 + '' + num;
     }
 };
 // 单位转换
 export const unitConversion = (value, unit, precision = 1) => {
     if (typeof value !== 'number') return value;
     if (value < 1) return value + ' ' + unit;
     // 次方
     let num = Math.pow(10, precision);
     // unit是必传项,如果没有则直接返回
     if (!unit) {
         // 这样做的目的，超过精度保留传递的精度，没有超过则不补零
         return Math.round(value * num) / num;
     }
     let k = 1024;
     let result = 0;
     let sizes = [];
     let i;
     switch (unit.toLocaleUpperCase()) {
         case 'MB':
             k = 1024;
             sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
             i = Math.floor(Math.log(value) / Math.log(k));
             result = value / Math.pow(k, i);
             return Math.round(result * num) / num + ' ' + sizes[i];
         case 'B':
             k = 1024;
             sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
             i = Math.floor(Math.log(value) / Math.log(k));
             result = value / Math.pow(k, i);
             return Math.round(result * num) / num + ' ' + sizes[i];
         case 'PA':
             k = 1000;
             sizes = ['Pa', 'hPa', 'kPa', 'MPa'];
             i = Math.floor(Math.log(value) / Math.log(k));
             result = value / Math.pow(k, i);
             return Math.round(result * num) / num + ' ' + sizes[i];
         case 'BPS':
             k = 1024;
             sizes = ['bps', 'Kbps', 'Mbps', 'KMbps'];
             i = Math.floor(Math.log(value) / Math.log(k));
             result = value / Math.pow(k, i);
             return Math.round(result * num) / num + ' ' + sizes[i];
         case '条':
         case '个':
             k = 10000;
             sizes = [unit, '万', '亿'];
             i = Math.floor(Math.log(value) / Math.log(k));
             result = value / Math.pow(k, i);
             return (
                 Math.round(result * num) / num +
                 ' ' +
                 sizes[i] +
                 (i === 0 ? '' : '条')
             );
         default:
             return Math.round(value * num) / num + unit;
     }
 };
 // 判断字符串是否是json格式
 export const isJSONStr = str => {
     if (typeof str == 'string') {
         try {
             let obj = JSON.parse(str);
             if (typeof obj == 'object' && obj) {
                 return true;
             } else {
                 return false;
             }
         } catch (e) {
             console.log('error：' + str + '!!!' + e);
             return false;
         }
     }
 };
 // 根据告警处理状态生成对应组件
 export const getDealStatuHtm = (status, isBtn = false) => {
     let statusText = DEAL_STATUS[status];
     return (
         <div className="deal-state" title={isBtn ? "点击查看详情" : ""} state={status}>
             {
                 isBtn ? <span className="deal-state-btn">
                     {statusText}
                 </span> : <span>  {statusText}</span>
             }
 
         </div>
     );
 };
 
 // 动态搜索框合并默认字段和动态字段的
 export const mergeSearchField = (defaultField, dynamicField) => {
     let targetArr = [...defaultField];
     dynamicField.forEach(ele => {
         let key = ele.fieldKey;
         let hasExit = !!targetArr.find(e => e.fieldKey === key);
         if (!hasExit) targetArr.push(ele);
     });
     return targetArr;
 };
 // 生成系统字符串
 export const createSystemStr = (record, short) => {
     if (!record) return '--';
     let { subSystemName, systemName } = record;
     if (!systemName) return '--';
     if (!subSystemName) return systemName;
     if (short) return subSystemName;
     return systemName + '-' + subSystemName;
 };
 // 给每个值添加value
 const addValueToNode = data => {
     if (!data || !data.length) return;
     data.forEach(ele => {
         ele.value = ele.key;
         addValueToNode(ele.children);
     });
 };
 // 获取treeData
 const getTreeSelectData = async itemData => {
     let result = [];
     const { urlKey, label } = itemData;
     if (!urlKey) {
         return result;
     }
     let dataRes = await get(`config/common/${urlKey}`);
     if (!dataRes.code) {
         message.error(`获取${label}数据失败`);
         return [];
     }
     try {
         result = JSON.parse(dataRes.data);
         addValueToNode(result);
     } catch (error) {
         // message.error('解析数据出错');
     }
     return result;
 };
 export const getEditTypeByAttr = attrItem => {
     //     0: '字符串',
     //     1: '数字',验证数字
     //     2: '端口', 验证端口
     //     3: '时间', 事件格式
     //     4: 'IP',验证ip
     //     5: '字典',不验证
     const { urlKey, valueType, htmlType, valueRange } = attrItem;
     // 如果valueType为5，是ajax请求，则认为是下拉树菜单
     let _node = null
     switch (valueType) {
         // ajax情况
         case 5: {
             // 请求数据
             let treeData = getTreeSelectData(attrItem);
             _node = (
                 <TreeSelect
                     className="nms-select-bottom"
                     dropdownStyle={{
                         maxHeight: 400,
                         overflow: 'auto',
                     }}
                     labelInValue
                     treeData={treeData}
                     placeholder="请选择"
                     treeDefaultExpandAll
                 />
             );
             break;
         }
         // 字符串情况
         case 0: {
             if (htmlType === 0) {
                 _node = (
                     <Input placeholder="请输入" className="nms-input-bottom" />
                 );
             } else {
                 let valueRangeArr = [];
                 // 将字符串的值转化为数组
                 try {
                     valueRangeArr = JSON.parse(valueRange) || [];
                 } catch (error) {
                     valueRangeArr = [];
                 }
                 _node = (
                     <Select className="nms-select-bottom" placeholder="请选择">
                         {valueRangeArr.map((item, index) => (
                             <Option key={index} value={item}>
                                 {item}
                             </Option>
                         ))}
                     </Select>
                 );
             }
             break;
         }
         // 数字情况
         case 1: {
             _node = (
                 <InputNumber
                     style={{ width: '100%' }}
                     className="nms-input-bottom"
                     placeholder="请输入数字"
                 />
             );
             break;
         }
         // 日期情况
         case 3: {
             _node = (
                 <DatePicker
                     style={{ width: '100%' }}
                     className="nms-input-bottom"
                     placeholder="请选择日期"
                 />
             );
             break;
         }
         default:
             _node = <Input placeholder="请输入" className="nms-input-bottom" />;
     }
 
     return _node
 };
 // 获取动态验证规则
 export const getRules = attrs => {
     // valueType值类型
     //     0: '字符串',
     //     1: '数字',验证数字
     //     2: '端口', 验证端口
     //     3: '时间', 事件格式
     //     4: 'IP',验证ip
     //     5: '字典',不验证
 
     // htmlType编辑方式
     //     0: '输入框',
     //     1: '下拉框',
 
     let rules = [];
     // 是否必填字段
     const { notNull, valueType } = attrs;
     // 验证数字
     valueType === 1 && rules.push({ type: 'number', message: '请输入数字' });
     // 验证端口
     valueType === 2 &&
         rules.push({
             pattern: portReg,
             transform: value => {
                 return value + '';
             },
             message: '请输入正确的端口'
         });
     // 验证ip
     valueType === 4 &&
         rules.push(...IPValidator);
 
     notNull &&
         rules.push({
             required: true,
             message: '该字段为必填项',
         });
     return rules;
 };
 
 export const previewReportByWindow = record => {
     let name = record.modelName;
     let range = record.timeRange;
     let arr = range && range.indexOf(',') >= 0 ? range.split(',') : [];
     let beginTime = '',
         endTime = '';
     if (arr.length === 2) {
         beginTime = arr[0];
         endTime = arr[1];
     }
     let url =
         'ureport/preview?_u=' +
         name +
         '&_t=5,6&beginTime=' +
         beginTime +
         '&endTime=' +
         endTime;
     let iWidth = 600; //弹出窗口的宽度;
     let iHeight = 850; //弹出窗口的高度;
     //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
     var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
     var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
 
     window.open(
         url,
         '报表',
         'height=' +
         iHeight +
         ',,innerHeight=' +
         iHeight +
         ',width=' +
         iWidth +
         ',innerWidth=' +
         iWidth +
         ',top=' +
         iTop +
         ',left=' +
         iLeft +
         ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no'
     );
 };
 
 // 判断js对象的类型
 export const typeObj = obj => {
     let type = Object.prototype.toString.call(obj);
     if (type === '[object Array]') {
         return 'Array';
     } else if (type === '[object Object]') {
         return 'Object';
     } else {
         return 'obj is not object or array';
     }
 };
 
 // 监控频率时分秒转换
 export const formatF = (num, unit) => {
     let delayNum = 0;
     if (!!unit) {
         let timeObj = {
             秒: 1,
             分: 60,
             时: 3600,
         };
         return num * timeObj[unit];
     } else {
         if (num < 60) {
             delayNum = num;
             unit = '秒';
         } else if (num >= 60 && num < 3600) {
             delayNum = num / 60;
             unit = '分';
         } else if (num >= 3600) {
             delayNum = num / 3600;
             unit = '时';
         }
         return {
             delayNum,
             unit,
         };
     }
 };
 
 export const getNodeId = () => {
     let currentTree = JSON.parse(sessionStorage.getItem('currentTree'));
     if (!currentTree) {
         return '';
     }
     return currentTree.nodeId;
 };
 
 // 在树形结构中找到key相同的值
 export const getValLabel = (key, data) => {
     let title;
     if (!data) return '--';
     const findDateById = (key, data) => {
         data.forEach(ele => {
             if (ele.key === key) {
                 title = ele.title;
             } else if (ele.children) {
                 findDateById(key, ele.children);
             }
         });
     };
     findDateById(key, data);
     return title;
 };
 // 递归去找是否含有该节点
 export const findHasTarget = (key, data) => {
     let tag;
     if (!data || !data.length) return false;
     let target = data.find(ele => ele.key === key)
     if (target) {
         tag = true;
     } else {
         data.forEach(ele => {
             if (ele.children && ele.children.length) {
                 findHasTarget(key, ele.children);
             }
         });
     }
     return tag
 }
 // tyh: 是否是测试版本
 export const isTestVersion = true;
 
 // ip新增验证规则 排除 0.0.0.0 127.0.0.1 255.255.255.255
 export const IPValidator = [
     {
         validator: (_, value, callback) => {
             if (value === '') return callback();
             const reg = new RegExp(IpReg);
             // IP不能以“0，127，224-255开头”
             const reg2 = new RegExp('^(0|127|22[4-9]|25[0-5]|2[3-4][0-9])');
             if (!value || value === undefined) {
                 return callback();
             }
             if (reg.test(value) && !reg2.test(value)) {
                 return callback();
             } else {
                 callback('请输入正确的IP');
             };
         },
     }
 ];
 
 // 将 平级结构数据转化为树形结构 arr是传过来的平级数据 id 是平级机构的唯一标识符，pId 是父级的指引
 export const renderTree = (arr, id, pId) => {
 
     if (!arr) return [];
     let map = {};
     let res = [];
     arr.forEach(item => {
         item.children = [];
         map[item[id]] = item;
     })
     arr.forEach(item => {
         let p = map[item[pId]];
         if (p) {
             p.children.push(item)
         } else {
             res.push(item)
         }
     })
     return res;
 }
 // 任务状态映射
 export const enumTaskStatus = {
     a: "待审批",
     b: "拒绝",
     c: "待执行",
     d: "执行中",
     e: "已停止",
     f: "执行失败",
     g: "成功结束"
 };