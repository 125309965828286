/*
 * @Author: zhangq
 * @Date: 2019-11-14 16:41:40
 * @Last Modified by: thy
 * @Last Modified time: 2020-11-07 14:26:54
 */
import React from "react";
import AddHomeView from "./AddHomeView";
import { Link } from "react-router-dom";
import Star from "./Stars";
import { get, post } from "@/requests/index";
import { withRouter } from "react-router-dom";
import { requestFullScreen, exitFullScreen, delCookie, getAlarmLevel } from "@/utils";
import { connect } from "react-redux";
import { Menu, Collapse, Layout, message, Modal, Button, Badge } from "antd";
import { getPort } from "@/utils";
import AboutUsModal from "@/components/ablout/AboutUsModal";
import MainHeaderTime from "./MainHeaderTime";
import "./headerCustom.less";

const { confirm } = Modal;
const { Header } = Layout;
const { SubMenu, ItemGroup } = Menu;
// const { Panel } = Collapse;
// 映射组件状态
const mapStateToProps = (state) => {
    let { userManager, homeLayout } = state;
    return {
        ...userManager,
        ...homeLayout,
    };
};

// 映射dispatch
const mapDispatchToProps = (dispatch) => ({
});

/**
 * @feature 头部导航
 * @export HeaderCustom
 * @class HeaderCustom
 * @extends {React.Component}
 */
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class HeaderCustom extends React.Component {
    state = {
        user: "",
        visible: false,
        enabled: true,
        fullScreen: false,
        alarmData: [],
        alarmLength: 0,
        aboutUsVisible: false,
    };
    // 记录上一次的告警数据 与本次告警数量做对比，如果有新增则做语音提示
    alarmTotal = 0;
    // 第一次请求的时候不语音播报
    isFirstFetchAlarm = true;
    alarmTimer;
    componentWillMount() {
    }

    componentDidMount() {
        // 全屏对象
        document.addEventListener("fullscreenchange", this.handleScreenChange);
    }
    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.handleScreenChange);
        clearInterval(this.alarmTimer);
    }

    // 语音播报
    voiceReport = () => {
        let newAlarmLength = this.state.alarmData.length;
        // 第一次请求 或者告警数量一致 不播报
        if (this.isFirstFetchAlarm || this.alarmTotal < newAlarmLength) {
            this.alarmTotal = newAlarmLength;
            return;
        }
        // 新增数量
        let num = newAlarmLength - this.alarmTotal;
        if (!window.SpeechSynthesisUtterance) return;
        var msg = new window.SpeechSynthesisUtterance(`新增告警${num}条`);
        msg.voiceURI = "native";
        msg.volume = 1; // 0 to 1
        msg.rate = 0.7; // 0.1 to 10
        msg.pitch = 1; //0 to 2
        msg.lang = "zh-cn"; // en-US, en-GB, en-ZA, and others work also
        window.speechSynthesis.speak(msg);
        this.alarmTotal = this.state.alarmData.length;
    };
    // 语音播报告警数量
    handleScreenChange = () => {
        // 退出全屏的时候
        if (!document.fullscreen) {
            this.setState({
                fullScreen: false,
            });
        }
    };
    menuClick = (e) => {
        e.key === "logout" && this.logout();
        e.key === "inIntro" && this.props.showIntro();
        // e.key === "about" && this.aboutUs();
    };

    logout = async () => {
        let res = await get("logout");

        if (!res.code) {
            message.error("退出失败");
            return;
        }

        // 删除用户信息
        delCookie("token-" + getPort());
        window.location.hash = "/client/login";
    };

    aboutUs = () => {
        this.setState({
            aboutUsVisible: true,
        });
    };
    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };

    destroyAll() {
        Modal.destroyAll();
    }

    // 新增页面弹窗确定事件
    onOk = () => {
        let { validateFields, resetFields } = this.addHomeViewForm;

        // 验证表单
        validateFields(async (err, values) => {
            if (err) return;
            let params = {
                ...values,
            };

            // 页面名称
            const layoutName = values.layoutName;

            // 保存新增页面
            let res = await post("config/home/save", params);

            if (!res.code) {
                message.error(res.data || "新增页面失败");
                return;
            }

            message.success("新增页面成功");

            // 重置表单
            resetFields();
        });
    };

    // 新增页面弹窗取消事件
    onCancel = () => {
        let { resetFields } = this.addHomeViewForm;

        // 重置表单
        resetFields();
    };
    // 跳转到演示系统
    jumpToDemo = () => {
        window.open("https://www.baidu.com");
    };
    // 全屏控制
    handleScreen = () => {
        let { fullScreen } = this.state;
        // 全屏对象
        let fullScreenDom = document.querySelector(".ant-layout-content");
        // 全屏
        fullScreen ? exitFullScreen() : requestFullScreen(fullScreenDom);
        this.setState({
            fullScreen: !fullScreen,
        });
    };
    changeView = (id, type, layoutName) => {
        let { isHome } = this.props;
        // 首页逻辑
        if (isHome) {
            this.changeHomeView({
                currentLayoutId: id,
                currentLayoutType: type,
                layoutName,
            });
        } else {
            this.changeHomeView({
                currentLayoutId: id,
                currentLayoutType: type,
                layoutName,
            });
            // 获取首页信息 并跳转到首页
            this.props.history.push({
                pathname: "/client/home",
            });
        }
    };

    render() {
        let {  addModalVisible } = this.props;
        let { collapsed, toggle, userInfo, currentLayoutId } = this.props;
        return (
            <Header className="custom-theme header">
                {/* 页面小星星 */}
                {[...".".repeat(100)].map((item, index) => {
                    return (
                        <Star
                            top={Math.random() * 43}
                            left={Math.random() * window.innerWidth}
                            key={index}
                        />
                    );
                })}

                {/* 页面收缩按钮 */}
                <i
                    style={{ fontSize: 16 }}
                    className="fa kdfont fa-bars header__trigger custom-trigger"
                    title={collapsed ? "展开菜单" : "收起菜单"}
                    onClick={toggle}
                />

                {/* 页面logo */}
                <span
                    className="logo"
                    onClick={() =>
                        this.props.history.push({
                            pathname: "/client/home",
                        })
                    }
                >
                    <i
                        style={{ width: 24, marginRight: 6 }}
                        className="fa kdfont fa-star-of-david"
                        alt="logo"
                        title="后台管理系统"
                    />
                    <span className="logo-name">后台管理系统</span>
                </span>

                {/* 页面头部工具栏 */}
                <Menu
                    mode="horizontal"
                    onClick={this.menuClick}
                    className="header-menu"
                    selectedKeys={[currentLayoutId]}
                >
                    {
                        [ 
                            <SubMenu
                                key={3}
                                title={
                                    <span className="avatar" title="用户中心">
                                        <i
                                            className="fa kdfont fa-user"
                                            aria-hidden="true"
                                        />
                                        <i className="badge on bottom b-white" />
                                    </span>
                                }
                                popupClassName="user-list"
                            >
                                <ItemGroup title={userInfo.user.userName}>
                                    {window.location.hash === "#/client/home" && (
                                        <Menu.Item title="新手引导" key="inIntro">
                                            <i className="fa kdfont fa-map-signs" />
                                            新手引导
                                        </Menu.Item>
                                    )}
                                    <Menu.Item key="logout" title="退出登录">
                                        <span className="logout-btn">
                                            <i className="fa kdfont fa-sign-out-alt" />
                                            退出登录
                                        </span>
                                    </Menu.Item>
                                    {/* <Menu.Item key="about" title="关于我们">
                                        <span className="logout-btn">
                                            <i className="fa kdfont fa-lightbulb" />
                                            关于我们
                                        </span>
                                    </Menu.Item> */}
                                </ItemGroup>
                            </SubMenu>,
                        ]
                    }
                </Menu>
                <MainHeaderTime />
                {/* 新建页面表单 */}
                <AddHomeView
                    visible={addModalVisible}
                    onOk={this.onOk}
                    onCancel={this.onCancel}
                    ref={(ref) => {
                        this.addHomeViewForm = ref;
                    }}
                />
                {/* 关于我们弹窗 */}
                <AboutUsModal
                    title="关于我们"
                    onCancel={() => {
                        this.setState({
                            aboutUsVisible: false,
                        });
                    }}
                    visible={this.state.aboutUsVisible}
                />
            </Header>
        );
    }
}
export default HeaderCustom;
