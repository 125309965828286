/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-06-30 11:55:29
 */
import React from "react";
import RoleModal from "./RoleModal";
import PowerModal from "./PowerModal";
import BaseTable from "@/components/BaseTable/BaseTable";
import InnerPage from "@/components/InnerPage/InnerPage";
import { message, Button, Popconfirm } from "antd";
import { post } from "@/requests/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./roleManager.less";

const roleListUrl = "auth/role/page";
/**
 * @feature 角色权限管理
 * @class RoleManager
 * @extends {React.Component}
 */

// const { user: userInfo = {} } = useSelector((state) => state.userManager.userInfo);
// console.log(userInfo);

// 映射状态到属性
const mapStateToProps = (state) => {
    let { user: userInfo = {} } = state.userManager.userInfo;
    return {
        userInfo,
    };
};

@connect(mapStateToProps)
class RoleManager extends React.Component {
    state = {
        roleManagerVisible: false, // 编辑和新增模态框
        powerVisible: false, // 编辑权限弹框
        type: "add",
        roleInfo: {},
        loading: false,
    };
    columns = [
        {
            title: "序号",
            dataIndex: "index",
            render: (name, record, index) => `${index + 1}`,
            width: "20%",
        },
        {
            title: "角色名",
            dataIndex: "roleName",
            width: "20%",
        },
        {
            title: "备注",
            dataIndex: "remark",
            width: "20%",
            render: (value, record, index) => `${value || "--"}`,
        },
        {
            title: "权限",
            dataIndex: "roleSign",
            width: "25%",
            render: (value, record, index) => `${value || "--"}`,
        },
        {
            title: "操作",
            dataIndex: "actions",
            width: "15%",
            render: (value, record, index) => {
                let { userInfo } = this.props;
                return record.isDel === 1 ? (
                    userInfo?.userName === "admin" ? (
                        // <i
                        //     title="权限管理"
                        //     className="fa kdfont fa-key icon-btn"
                        //     onClick={() => this.editPowerModal(record)}
                        // />
                        <span title="非超级管理员禁止编辑">--</span>
                    ) : (
                        <span title="非超级管理员禁止编辑">--</span>
                    )
                ) : (
                    <div className="actions-buttons">
                        <i
                            title="编辑角色"
                            className="fa kdfont fa-edit icon-btn"
                            onClick={this.editRoleModal.bind(this, record)}
                        />
                        {/* <i
                            title="权限管理"
                            className="fa kdfont fa-key icon-btn"
                            onClick={() => this.editPowerModal(record)}
                        /> */}
                        <Popconfirm
                            title="确定删除该角色?"
                            onConfirm={() => this.deleteRoleById(record.id)}
                        >
                            <i
                                className="fa kdfont fa-trash-alt icon-btn"
                                title="删除角色"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    // 获取表格数据
    getTableData = (params = {}) => {
        // 获取设备数据
        this.baseTable && this.baseTable.getTableData(params);
    };
    // 编辑角色
    editRoleModal = (roleInfo) => {
        if (roleInfo.roleName === "超级管理员") return;
        this.setState({
            roleManagerVisible: true,
            type: "edit",
            roleInfo,
        });
    };
    // 编辑权限
    editPowerModal = (roleInfo) => {
        this.setState({
            powerVisible: true,
            roleInfo,
        });
    };
    hiddenPower = () => {
        this.setState({
            powerVisible: false,
            roleInfo: {},
        });
    };
    // 删除角色 通过id
    deleteRoleById = async (id) => {
        let resData = await post("auth/role/remove/" + id);
        if (!resData.code) {
            message.error(resData.data || "删除失败");
            return;
        }

        message.success("删除成功");
        // 刷新表格
        this.getTableData({ isDelete: true });
    };

    addRoleModal = () => {
        this.setState({
            roleManagerVisible: true,
            roleInfo: {},
            type: "add",
        });
    };
    handleCancel = () => {
        this.setState({
            roleManagerVisible: false,
            // roleInfo: {},
        });
        const { form } = this.formRef.props;
        // 重置表单
        form.resetFields();
    };

    handleCreate = () => {
        this.setState({ loading: true });
        const { form, type, roleInfo } = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            // 重置表单
            form.resetFields();
            let urlStr = "auth/role/save";
            let params = values;
            const resultTips = type === "add" ? "添加" : "修改";
            if (type === "edit") {
                urlStr = "auth/role/update";
                params.id = roleInfo.id;
            }
            // 请求参数
            let resData = await post(urlStr, params);
            if (!resData.code) {
                message.error(resData.data || resultTips + "失败");
                return;
            } else {
                this.setState({ loading: false });
            }
            this.getTableData();
            // 保存参数
            message.success(resultTips + "成功");

            this.setState({ roleManagerVisible: false });
        });
    };
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    };

    getButtonList = () => {
        return (
            <Button title="新增角色" className="nms-button" onClick={this.addRoleModal}>
                <i className="fa kdfont fa-plus"></i>
                增加
            </Button>
        );
    };
    render() {
        const {
            roleManagerVisible,
            type,
            roleInfo,
            powerVisible,
            loading,
        } = this.state;
        return (
            <InnerPage {...this.props} buttonList={this.getButtonList()}>
                <div className="user-manager table-wraper">
                    <BaseTable
                        onRef={(ref) => (this.baseTable = ref)}
                        url={roleListUrl}
                        axiosType="get"
                        allColumn={this.columns}
                        height="100%"
                        onRow={(record) => {
                            return {
                                onDoubleClick: () => this.editRoleModal(record),
                            };
                        }}
                    />
                    <RoleModal
                        type={type}
                        roleInfo={roleInfo}
                        visible={roleManagerVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        wrappedComponentRef={this.saveFormRef}
                        loading={loading}
                    />
                    <PowerModal
                        roleInfo={roleInfo}
                        visible={powerVisible}
                        onOk={this.hiddenPower}
                        onCancel={this.hiddenPower}
                    />
                </div>
            </InnerPage>
        );
    }
}
export default RoleManager;
