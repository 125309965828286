/*
 * @Author: yek
 * @Date: 2019-12-10 18:11:43
 * @Last Modified by: yek
 * @Last Modified time: 2020-06-30 18:18:42
 */

import React from "react";
import PropTypes from "prop-types";
import ResponsiveTable from "@/components/ResponsiveTable/ResponsiveTable";
import TableColumnsList from "@/components/TableColumnsList/TableColumnsList";
import { message } from "antd";
import { post, get } from "@/requests";
import { getValueFromExpand } from "@/utils";
import styled from "styled-components";

const ResponseTime = styled.div`
    position: absolute;
    bottom: 16px;
    left: 20px;
    color: #767676;
    font-size: 12px;
`;

/**
 * @feature 设备属性页面
 * @export BaseTable
 * @class BaseTable
 * @extends {React.Component}
 */
class BaseTable extends React.Component {
    state = {
        data: [],
        loading: false,
        // 分页数据
        pagination: {
            className: "nms-pagination",
            pageSizeOptions: ["10", "20", "100", "200", "1000"],
            showSizeChanger: true,
            defaultPageSize: 20,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
        },
        responseTime: "--", //响应时间
    };

    // 分页信息
    params = {
        page: 1,
        pageSize: 20,
    };

    componentWillMount() {
        let { onRef, showColumnConfig, defaultPageSize } = this.props;
        if (defaultPageSize) {
            this.setState(
                {
                    pagination: {
                        className: "nms-pagination",
                        pageSizeOptions: ["10", "20", "100", "200", "1000"],
                        showSizeChanger: true,
                        defaultPageSize,
                        showTotal: (total) => {
                            return `共 ${total} 条`;
                        },
                    },
                },
                () => {
                    this.getTableData();
                    onRef && onRef(this);
                    // 先判断是否加载列配置 再请求配置参数
                    showColumnConfig && this.getColumnData();
                }
            );
            return;
        }
        this.getTableData();
        onRef && onRef(this);
        // 先判断是否加载列配置 再请求配置参数
        showColumnConfig && this.getColumnData();
    }
    componentWillReceiveProps(nextProps) {
        let { showColumnConfig, templateNo: oldTemplateNo } = this.props;
        // let { query } = nextProps;
        let { templateNo } = nextProps;
        if (oldTemplateNo !== templateNo) {
            // 加载列数据
            showColumnConfig && this.getColumnData(nextProps);
        }
    }

    // 获取初始化列表数据
    getColumnData = async (nextProps) => {
        let { templateNo, allColumn, setColumnData } = nextProps || this.props;
        const params = {
            templateNo,
        };
        let resData = await get("config/common/user/getColumn", params);
        if (!resData.code) {
            message.error("获取默认列失败");
            this.setState({
                currentColumn: allColumn,
            });
            return;
        }
        // 解析数据
        let configDataStr = resData.data || "[]";
        let configDataObj,
            currentColumn = [];
        try {
            configDataObj = resData?.data?.split(",");
            allColumn.forEach((item) => {
                // 遍历所有列数据，如果含有defaultShow字段或者在配置项数据里面
                if (!!item.defaultShow || configDataObj.indexOf(item.dataIndex) > -1) {
                    currentColumn.push(item);
                }
            });
        } catch (error) {
            console.error("数据解析出错");
            this.setState({
                currentColumn: allColumn,
            });
            return;
        }
        // 设置状态
        setColumnData && setColumnData(currentColumn);
    };
    // 表格分页
    handleTableChange = (pagination) => {
        let { current, pageSize } = pagination;
        this.params = {
            ...this.params,
            page: current,
            pageSize,
        };
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            pagination: pager,
        });

        // 请求数据
        this.getTableData(this.params);
    };

    /**
     *
     *query为临时查询的参数
     * @param {*} query
     * @returns
     */
    getTableData = async (query) => {
        let { data } = this.state;
        let { query: propsQuery = {} } = this.props;
        let startTime = new Date().valueOf();
        this.setState({ loading: true });
        let { url, hasPage, showSizeChanger, axiosType, callBack } = this.props;
        if (!url) return;

        // 重置的时候删去参数
        if (!query || !Object.keys(query).length) {
            const { page, pageSize } = this.params;
            this.params = {
                page,
                pageSize,
            };
            let { defaultPageSize } = this.props;
            if (defaultPageSize) {
                this.params = {
                    page: 1,
                    pageSize: defaultPageSize,
                };
            }
        }
        //拼接参数
        let params = {
            ...propsQuery,
            ...this.props.query,
            ...this.params,
            ...query,
        };
        // 如果不分页，则将pagesize设置为10000
        params.pageSize = !hasPage ? 10000 : params.pageSize;
        let { isDelete, page } = params;
        // 此处的逻辑用来判断是否
        if (isDelete && data.length === 1 && page > 1) {
            params.page = this.params.page = page - 1;
            delete params.isDelete;
        }

        //ToDo 配置查询参数
        let resData;
        if (axiosType === "post") {
            resData = await post(url, params);
        } else {
            resData = await get(url, params);
        }
        if (!resData.code) {
            this.setState({ loading: false });
            message.error(resData.data || "请求数据失败");
            return;
        }
        console.log('111111')

        let endTime = new Date().valueOf();
        const result = resData.data;
        const { list } = result;
        const pagination = { ...this.state.pagination };

        // 页码数据
        this.params.page = params.page;
        this.params.pageSize = params.pageSize;

        pagination.total = result.total;
        pagination.current = params.page;
        pagination.showSizeChanger = showSizeChanger;
        // 渲染页面
        await this.setState({
            loading: false,
            data: list,
            pagination,
            responseTime: endTime - startTime,
        });
        // 将每页的数据传递出去，在外面处理
        callBack && callBack(list);
    };

    // 格式化列
    formatterColumns = () => {
        let { columns = [], allColumn = [], showColumnConfig } = this.props;
        let newColumns;
        // 判断是否显示列配置项，如果显示就用columns，如果不显示就默认显示所有字段
        if (showColumnConfig) {
            newColumns = [...columns];
        } else {
            newColumns = [...allColumn];
        }
        let listData = this.state.data;
        return newColumns.map((ele) => {
            ele.callBack && ele.callBack(listData);
            if (ele.render) return ele;
            // 如果没有数据，则直接
            let { dataIndex } = ele;
            // 先在数据最外层找该字段，如果没有，则到expandlist字段里面找
            ele.render = (value, record, index) => {
                return record[dataIndex] || getValueFromExpand(record, dataIndex);
            };
            return ele;
        });
    };

    // 保存列数据
    saveColumnsList = async (checklist) => {
        if (!checklist) return;
        let { templateNo } = this.props;
        if (!templateNo) {
            console.error("缺少参数");
            return;
        }
        const params = {
            confValue: JSON.stringify(checklist),
            templateNo,
        };
        let resData = await post("config/common/user/updateColumn", params);
        if (!resData.code) {
            message.error(resData.data || "保存列数据失败");
            return;
        }
        message.success("保存列数据成功");
    };
    // 动态设置列数据
    setTableColumns = (checklist = []) => {
        let currentColumn = [];
        const { allColumn, setColumnData } = this.props;
        allColumn.forEach((item) => {
            // 遍历所有列数据，如果含有defaultShow字段或者在配置项数据里面
            if (!!item.defaultShow || checklist.indexOf(item.dataIndex) > -1) {
                currentColumn.push(item);
            }
        });
        // 设置状态
        setColumnData && setColumnData(currentColumn);
    };
    render() {
        const { data, pagination, loading, responseTime } = this.state;
        // currentColumn 要显示的所有列数据
        let {
            hasPage,
            query,
            url,
            height,
            allColumn,
            columns: currentColumn,
            showColumnConfig,
            rowKeys,
            onRef,
            noResponTime,
            ...reset
        } = this.props;
        // 生成列
        let newColumns = this.formatterColumns();
        return (
            <div style={{ height: height || "auto" }} className="table-wraper">
                <ResponsiveTable
                    columns={newColumns}
                    rowKey={(record) => {
                        // list id不存在或者指定其他的key ;rowKey:[]
                        if (rowKeys && rowKeys.length > 0) {
                            return [record, ...rowKeys].reduce((i, j) => i[j]);
                        }
                        return record.id;
                    }}
                    dataSource={data}
                    pagination={hasPage ? pagination : false}
                    loading={loading}
                    onChange={this.handleTableChange}
                    {...reset}
                />
                {!noResponTime && (
                    <ResponseTime>响应：{responseTime} 毫秒</ResponseTime>
                )}
                {showColumnConfig && (
                    <TableColumnsList
                        allColumn={allColumn}
                        checkList={currentColumn}
                        setTableColumns={this.setTableColumns}
                        saveColumnsList={this.saveColumnsList}
                    />
                )}
            </div>
        );
    }
}

// 参数验证
BaseTable.propTypes = {
    // 列数据
    columns: PropTypes.array.isRequired,
    query: PropTypes.object,
    hasPage: PropTypes.bool,
    url: PropTypes.string.isRequired,
    showSizeChanger: PropTypes.bool,
    axiosType: PropTypes.string,
    callBack: PropTypes.func,
    showColumnConfig: PropTypes.bool,
};
// 默认值
BaseTable.defaultProps = {
    axiosType: "post",
    showSizeChanger: true,
    columns: [
        {
            title: "序号",
            dataIndex: "index",
            render: (name, record, index) => `${index + 1}`,
            width: "4%",
        },
    ],
    hasPage: true,
    query: {},
    showColumnConfig: false,
    // 当不需要配置列的时候仅需传该字段
    allColumn: [],
};
export default BaseTable;
