const NODE_DATA_MAPTALKS = 'NODE_DATA_MAPTALKS';
const initialState = {
    nodes: [],
    links: [],
};

const r_NodeDataMaptalks = (state = initialState, action) => {
    switch (action.type) {
        case NODE_DATA_MAPTALKS:
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};

export const r_SetNodeDataMaptalks = data => {
    return (dispatch, getState) => {
        dispatch({
            type: NODE_DATA_MAPTALKS,
            data,
        });
    };
};

export default r_NodeDataMaptalks