import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import { CustomUpload } from "@/components/FormItem/DynamicFromItem"
import { telReg } from "@/utils/validateReg";
import { Form } from "@ant-design/compatible";
import { post } from "@/requests/index";
import { Input, Radio, message, TreeSelect, InputNumber, Select } from "antd";

// @Form.create()
class GoodsModal extends React.Component {
    state = {
        shopData: [], //商店,
        categoryData: [],
        shopId: null
    };
    handOk = () => {
        this.props.onCreate();
    };
    componentDidMount() {
        this.getShopData();
        this.getCategoryData();
    }
    //获取商店信息
    getShopData = async () => {
        let resData = await post("wxdc/shop/list");
        if (!resData.code) message.error(resData.data || "请求数据失败");
        const children = resData.data;
        //格式化数据
        const targetData = this.formatData(children);
        this.setState({
            shopData: targetData
        });
        console.log('shopData', this.state.shopData);
    };
    //获取分类信息
    getCategoryData = async () => {
        let resData = await post("wxdc/category/list");
        if (!resData.code) message.error(resData.data || "请求数据失败");
        const children = resData.data;
        //格式化数据
        const targetData = this.formatCategoryData(children);
        this.setState({
            categoryData: targetData
        });
        console.log('categoryData', this.state.categoryData);
    };
    //格式化数据
    formatData = (data) => {
        let targetData = [];
        data && data.forEach((ele) => {
            ele.key = ele.value = ele.id;
            ele.title = ele.name;
            ele.label = ele.name;
            // ele.isLeaf = false;
            targetData.push(ele);
        });
        return targetData;
    };
    //格式化数据
    formatCategoryData = (data) => {
        let result = {};
        data && data.forEach((ele) => {
            ele.key = ele.value = ele.id;
            ele.title = ele.name;
            ele.label = ele.name;
            if(!result[ele.shopId]){
                result[ele.shopId] = [];
            }
            result[ele.shopId].push(ele);
        });
        return result;
    };
    loadTestData = async selectedOptions => {
        console.log('selectedOptions', selectedOptions)
        const targetOption = selectedOptions[selectedOptions.length - 1];
        console.log('targetOption', targetOption)
        targetOption.loading = true;
        let res = await post("wxdc/category/list",{"shopId": ""});
        if (!res.code) return message.error(res.data || '货架分类失败');
        targetOption.loading = false;
        if (res.data.length === 0) {
            targetOption.isLeaf = true;
            targetOption.disabled = true;
        }
        targetOption.children = res.data.map(item => ({
            label: item.name,
            value: item.id,
            obj: item,
        }));
        this.setState({
            shopData: [...this.state.shopData],
        });
    };
    handleShopChange = (value) => {
        this.setState({
            shopId: value
        });
    };
    render() {
        const { shopData,categoryData, shopId } = this.state;
        const { visible, onCancel, form, goodsInfo, type } = this.props;
        const passwordVisible = type === "add";
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="user-manager"
                    title={(type === "add" ? "新增" : "编辑") + "商品"}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={this.handOk}
                >
                    <Form layout="horizontal" className="nms-form">
                        <Form.Item label="商品名称" {...formItemLayout}>
                            {getFieldDecorator('name', {
                                initialValue: goodsInfo.hasOwnProperty("name")
                                    ? goodsInfo.name
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入商品名称',
                                    },
                                    {
                                        max: 20,
                                        message: '商品名称不超过20个字符',
                                    },
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商品名称"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="所属商家" {...formItemLayout}>
                            {getFieldDecorator("shopId", {
                                initialValue: goodsInfo?.shopId,
                                rules: [{ required: true, message: "请选择商家" }],
                            })(
                                <TreeSelect
                                    className="nms-select-bottom"
                                    style={{ width: "100%" }}
                                    allowClear={true}
                                    dropdownClassName="nms-tree-select-dropdown"
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    treeData={shopData}
                                    placeholder="请选择商家"
                                    treeDefaultExpandAll
                                    onChange={this.handleShopChange}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="所属分类" {...formItemLayout}>
                            {getFieldDecorator("categoryId", {
                                initialValue: goodsInfo?.categoryId ,
                                rules: [{ required: true, message: "请选择分类" }],
                            })(
                                <TreeSelect
                                    className="nms-select-bottom"
                                    style={{ width: "100%" }}
                                    allowClear={true}
                                    dropdownClassName="nms-tree-select-dropdown"
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    treeData={categoryData[shopId? shopId : goodsInfo?.shopId]}
                                    placeholder="请选择分类"
                                    treeDefaultExpandAll
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="原价格" {...formItemLayout}>
                            {getFieldDecorator('originalPrice', {
                                initialValue: goodsInfo?.originalPrice,
                                rules: [
                                    { required: true, message: "请输入价格" }
                                ],
                            })(
                                <InputNumber
                                    prefix="￥"
                                    min={0}
                                    className="nms-input-bottom"
                                    placeholder={`请输入价格`}
                                    style={{ width: "100%" }}
                                    type="number"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="是否支持砍价" {...formItemLayout}>
                            {getFieldDecorator("isKanjia", {
                                initialValue: goodsInfo.hasOwnProperty("isKanjia")
                                    ? goodsInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        支持
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        不支持
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="砍价价格" {...formItemLayout}>
                            {getFieldDecorator('kanjiaPrice', {
                                initialValue: goodsInfo?.kanjiaPrice,
                                rules: [
                                ],
                            })(
                                <InputNumber
                                    prefix="￥"
                                    min={0}
                                    className="nms-input-bottom"
                                    placeholder={`请输入砍价价格`}
                                    style={{ width: "100%" }}
                                    type="number"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="最低价格" {...formItemLayout}>
                            {getFieldDecorator('minPrice', {
                                initialValue: goodsInfo?.minPrice,
                                rules: [
                                ],
                            })(
                                <InputNumber
                                    prefix="￥"
                                    min={0}
                                    className="nms-input-bottom"
                                    placeholder={`请输入最低价格`}
                                    style={{ width: "100%" }}
                                    type="number"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="最大使用优惠卷数" {...formItemLayout}>
                            {getFieldDecorator('maxCoupons', {
                                initialValue: goodsInfo?.maxCoupons,
                                rules: [
                                ],
                            })(
                                <InputNumber
                                    className="nms-input-bottom"
                                    min={0}
                                    max={3}
                                    placeholder={`请输入最大使用优惠卷数`}
                                    style={{ width: "100%" }}
                                    type="number"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="最低购买数量" {...formItemLayout}>
                            {getFieldDecorator('minBuyNumber', {
                                initialValue: goodsInfo?.minBuyNumber,
                                rules: [
                                ],
                            })(
                                <InputNumber
                                    prefix="￥"
                                    className="nms-input-bottom"
                                    min={1}
                                    placeholder={`请输入最低购买数量`}
                                    style={{ width: "100%" }}
                                    type="number"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="上传图片" {...formItemLayout}>
                            {getFieldDecorator('pic', {
                                initialValue: goodsInfo?.pic,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请上传图片',
                                    },
                                ],
                            })(
                                <CustomUpload
                                    placeholder="请上传图片"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="是否推荐商品" {...formItemLayout}>
                            {getFieldDecorator("recommendStatus", {
                                initialValue: goodsInfo.hasOwnProperty("recommendStatus")
                                    ? goodsInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        推荐
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        不推荐
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="状态" {...formItemLayout}>
                            {getFieldDecorator("status", {
                                initialValue: goodsInfo.hasOwnProperty("status")
                                    ? goodsInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        正常
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        禁用
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        )
    }
}

export default Form.create()(GoodsModal);