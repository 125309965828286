/*
 * @Author: zhangq
 * @Date: 2019-11-06 10:17:41
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-12 17:27:08
 */
import React from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Input, Button } from 'antd';

/**
 * @feature 公告查询表单
 * @export DictionarySearchForm
 * @class DictionarySearchForm
 * @extends {React.Component}
 */
@Form.create()
class DictionarySearchForm extends React.Component {
    // 重置表单
    resetSearchForm = () => {
        const { form } = this.props;
        form.resetFields();
        this.handleSearch();
    };

    // 查询按钮事件
    handleSearch = () => {
        let { form, getTableList } = this.props;
        // 筛选表单数据
        let searchParams = form.getFieldsValue();
        // 查询数据
        getTableList && getTableList(searchParams);
    };
    render() {
        let { getFieldDecorator } = this.props.form;
        let formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 15 },
        };
        return (
            <Form className="nms-form">
                <Row span={24}>
                    <Col span={8}>
                        <Form.Item label="字典名称" {...formItemLayout}>
                            {getFieldDecorator('configName')(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入字典名称"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="字典KEY" {...formItemLayout}>
                            {getFieldDecorator('configKeyWord')(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入字典KEY"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Row span={8}>
                            <Button
                                onClick={this.resetSearchForm}
                                className="nms-button"
                            >
                                重置
                            </Button>
                            <Button
                                onClick={this.handleSearch}
                                className="nms-button"
                                htmlType='submit'
                            >
                                查询
                            </Button>
                    </Row>
                </Row>
            </Form>
        );
    }
}
export default DictionarySearchForm;
