// 自动寻找所有的reducer模块   文件名即为reducer名
const allModules = {}
const allModulesReq = require.context('./', true, /\.js$/);

allModulesReq.keys().reduce((allModules, mod) => {    
    const matches = mod.match(/\/([^/]+).js$/);
    // 排除index.js
    if (matches[1] === 'index') return allModules

    allModules[matches[1]] = allModulesReq(mod).default
    return allModules
}, allModules)

export default allModules