export default {
    "XH": "信号",
    "BW": "报文",
    "ZD": "阵地",
    "ZB": "装备",
    "ZC": "侦查",
    "WX": "卫星",
    "RW": "任务",
    "KS": "控守",
    "QS": "前哨",
};