// action类型
const SAVE_USER_INFO = 'SAVE_USER_INFO';

// 初始状态
const initialState = {
    userInfo: {},
    //权限 设备权限Map
    permissions: {
        // [sourceId]: {
        //     "sourceId": 30887647015112704,
        //     "isLookOver": 1,
        //     "isUpdate": 1,
        //     "isControl": 1
        // }
    }

};

// 保存用户信息 action creator
export const saveUserInfo = userInfo => dispatch => {

    dispatch({
        type: SAVE_USER_INFO,
        userInfo
    });
};

// 用户管理reducer
const userManager = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case SAVE_USER_INFO:

            let permissions = {}
            const { permission = [] } = action.userInfo;
            permission.forEach(el => {
                permissions[el.sourceId.toString()] = { ...el }
            });
            return {
                ...state,
                userInfo: action.userInfo,
                permissions,
            };
        default:
            return { ...state };
    }
};
export default userManager