/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import DragModal from '@/components/DragModal/DragModal';


export default class HelpModal extends Component {

    state = {
        loading: false,
        data: {},
        query: {},

    };
    // 列表的字段
    render = () => {
        const { loading } = this.state
        const { title, visible, onCancel, data } = this.props;


        return (
            <DragModal
                visible={visible}
                title={title}
                width="90%"
                height="90%"
                okButtonProps={{ loading }}
                cancelButtonProps={{ loading }}
                // onOk={() => this.handleOk()}
                onCancel={() => onCancel()}
                footer={null}

            >
                <div style={{ height: "100%" }}>
                    <iframe style={{ height: "100%", width: '100%' }} src={data} frameborder="0"></iframe>
                </div>


            </DragModal>
        );
    };
}
