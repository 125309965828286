import { useEffect, useState, useCallback } from 'react'
import { message } from 'antd'
import { post, get } from 'requests'

export function useTableFetch({
    axiosType,
    axiosUrl,
    query,
    callback,
    formatterData,
    defaultPageSize,
    finishCallback,
}) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(defaultPageSize)
    const [total, setTotal] = useState(0)
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    const getTableData = useCallback(
        async searchParams => {
            if (!axiosUrl) return
            const params = {
                pageSize,
                ...query,
                page,
                ...searchParams,
            }
            // now115
            // if (typeof params.roomId !== 'string') {
            //     if (params.roomId) {
            //         params.roomId = params.roomId[1]
            //     }
            // }
            let res = {}
            setLoading(true)
            if (axiosType === 'get') {
                res = await get(axiosUrl, params)
            } else {
                res = await post(axiosUrl, params)
            }
            const { code, data } = res
            let dataArr = data?.list ?? [],
                total = data?.total
            if (code === 1) {
                if (formatterData) {
                    let list = formatterData(data)
                    setTableData(list)
                    setTotal(data?.total ?? 0)
                    setLoading(false)
                    return
                }
                if (data instanceof Array) {
                    dataArr = data
                } else if (data?.list instanceof Array) {
                    dataArr = data?.list
                } else if (data?.dataList?.list instanceof Array) {
                    dataArr = data?.dataList?.list
                    total = data?.dataList?.total
                }
                if (Object.prototype.toString.call(data) === '[object Object]' && data?.files) {
                    const list = data.files ? JSON.parse(data.files) : []
                    setTableData(list.map((item, index) => ({ file: item, id: item + index })))
                } else {
                    setTableData(dataArr)
                }
                finishCallback && finishCallback(data)
                setTotal(total)
            } else {
                setTableData([])
                setTotal(0)
                message.error(data ?? '请求数据失败')
            }
            setLoading(false)
            callback && callback(dataArr)
            return dataArr
        },
        [axiosType, axiosUrl, query, page, pageSize],
    )

    const updateCurrentPage = () => {
        const currentPageLength = tableData.length
        // 当前分页条数如果大于1，说明删除后至少还有一条数据，则不用回到第一页
        if (currentPageLength > 1) {
            getTableData()
        } else {
            setPage(1)
            getTableData()
        }
    }

    const onPageChange = page => {
        setPage(page)
    }

    const onPageSizeChange = (_, pageSize) => {
        // 改变大小的时候默认回到第一页
        setPage(1)
        setPageSize(pageSize)
    }

    useEffect(() => {
        if (query) {
            setPage(query?.page || 1)
        }
    }, [query])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    return {
        page,
        total,
        tableData,
        loading,
        getTableData,
        updateCurrentPage,
        onPageChange,
        onPageSizeChange,
    }
}
