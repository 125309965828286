/*
 * @Author: zhangq
 * @Date: 2019-09-30 13:07:10
 * @Last Modified by: yek
 * @Last Modified time: 2020-03-27 16:51:31
 */
import React from 'react';

/**
 * @feature 子菜单
 * @export SubMenu
 * @class SubMenu
 * @extends {React.Component}
 */
export default class SubMenu extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const { hoverUnfold, collapsed } = nextProps;

        // hover 和 bars 都没有展开
        if (!hoverUnfold && collapsed) {
            return {
                unfold: false,
            };
        } else {
            return {};
        }
    }

    state = {
        unfold: false,
    };

    titleClick = e => {
        this.setState({
            unfold: !this.state.unfold,
        });
    };

    isActive() {
        const { name, pathname } = this.props;
        const reg = new RegExp(name, 'gi');
        return pathname.match(reg) ? 'active' : '';
    }
    // 用来判断该菜单
    isUnfold() {
        const { hoverUnfold, collapsed, name, pathname } = this.props;
        const reg = new RegExp(name, 'gi');
        if (!pathname.match(reg)) return false;

        if (hoverUnfold || !collapsed) {
            return true;
        } else {
            return false;
        }
    }

    showArrow() {
        const { unfold } = this.state;
        const { hoverUnfold, collapsed } = this.props;
        let arrowArray = ['menu-arrow', 'fa', 'kdfont'];

        if (unfold) {
            arrowArray.push('fa-angle-down');
        } else {
            arrowArray.push('fa-angle-right');
        }

        if (!hoverUnfold && collapsed) {
            arrowArray.push('hide');
        }
        return arrowArray;
    }

    render() {
        const { title, icon } = this.props;

        return (
            <li>
                <a
                    href="javascript:void(0)"
                    onClick={this.titleClick}
                    className={['sub-menu-title ', this.isActive()].join(' ')}
                >
                    <i className={'fa kdfont ' + icon}></i>
                    <span className="menu-word">{title}</span>
                    <i className={this.showArrow().join(' ')}></i>
                </a>
                <ul
                    style={{ paddingLeft: '10px' }}
                    className={[
                        'sub-menu-content',
                        this.state.unfold ? '' : 'hide',
                    ].join(' ')}
                >
                    {this.props.children}
                </ul>
            </li>
        );
    }
}
