import React from "react";
import { connect } from "react-redux";
import { saveUserInfo } from "@/redux/userManager/userManager";
import { bindActionCreators } from "redux";
import { post } from "@/requests/index";
import { getCookie, setCookie, delCookie, getPort } from "@/utils";
import { encrypt, decrypt } from "@/utils/secret"; // 密码加密
import { Form, Input, Button, Checkbox, message } from "antd";
import AboutUsModal from "@/components/ablout/AboutUsModal";

import "./login.less";

// 映射dispatch
const mapDispatchToProps = (dispatch) => ({
    // 保存用户信息
    saveUserInfoToStore: bindActionCreators(saveUserInfo, dispatch),
});

/**
 * @feature Login
 * @class Login
 * @extends {React.Component}
 */
@connect(null, mapDispatchToProps)
class Login extends React.Component {
    codeRef = React.createRef(null);

    state = {
        userName: "",
        password: "",
        remember: false,
        isloading: false,
        AboutUsVisible: false,
    };

    componentDidMount() {
        // 验证是否记住密码
        // this.getLoginInfo();
        // 删除用户信息
        delCookie("token-" + getPort());
        document.title = "后台管理系统 - 登录";
        // this.login(window.appConfig.userLogin)
    }

    getLoginInfo() {
        //页面初始化时，如果帐号密码cookie存在则填充
        let user = getCookie("user");
        let encryptPswd = getCookie("pswd");
        // 解密
        let pwd = decrypt(encryptPswd);
        let remember = getCookie("remember");

        this.setState({
            userName: user,
            password: pwd,
            remember: Boolean(remember),
        });
        this.formRef.current.setFieldsValue({
            userName: user,
            password: pwd,
            remember: Boolean(remember),
        });
    }
    formRef = React.createRef();
    // 登录事件
    login = async (values = {}) => {
        let { saveUserInfoToStore } = this.props;
        let { userName = "", password = "", remember = "" } = values;
        if (!remember) {
            delCookie("user");
            delCookie("pswd");
            delCookie("remember");
        } else {
            // 加密
            let encryptPswd = encrypt(password);

            //保存帐号到cookie，有效期7天
            setCookie("user", userName, 7);
            setCookie("pswd", encryptPswd, 7);
            setCookie("remember", remember, 7);
        }

        let params = {
            userName: userName,
            password: password,
        };
        this.setState({
            isloading: true,
        });
        try {
            let res = await post("login", params);
            if (!res.code) {
                this.setState({
                    isloading: false,
                });
                message.error(res.data || "请求错误");
                return;
            }

            let token = res.data.token;

            let userInfo = {
                permission: res.data.permission,
                menus: res.data.menus,
                user: res.data.user,
            };
            this.setState({
                isloading: false,
            });
            window.sessionStorage.removeItem("currentTree");

            // 保存token
            setCookie("token-" + getPort(), token);

            // 保存用户信息
            saveUserInfoToStore(userInfo);

            // 跳转到内页
            window.location.hash = "/";
        } catch (error) {
            this.setState({
                isloading: false,
            });
        }
    };

    // 键盘登录事件
    keyDownLogin = (e) => {
        if (e.keyCode === 13) {
            this.login();
        }
    };

    render() {
        let { isloading } = this.state;
        const layout = {
            wrapperCol: {
                span: 24,
            },
        };
        return (
            <div onKeyDown={this.keyDownLogin} className="login-content">
                <div className="login">
                    <span className="login-title">后台管理系统</span>
                    <Form
                        ref={this.formRef}
                        {...layout}
                        className="login-form"
                        onFinish={this.login}
                    >
                        <Form.Item
                            name="userName"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入用户名",
                                },
                            ]}
                        >
                            <Input
                                prefix={<i className="fa kdfont fa-user" />}
                                style={{ width: 330 }}
                                placeholder="用户名"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入密码",
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<i className="fa kdfont fa-lock" />}
                                style={{ width: 330 }}
                                type="password"
                                size="large"
                                placeholder="密码"
                            />
                        </Form.Item>
                        <div className="remember-wrap">
                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                style={{ marginBottom: 0 }}
                            >
                                <Checkbox value={true} className="remember">
                                    记住密码
                                </Checkbox>
                            </Form.Item>
                            <div
                                className="about-btn-wrap"
                                onClick={() => {
                                    // window.top.postMessage(
                                    //     {
                                    //         code: 'machineroom',
                                    //         roomId: '31043971850735616'
                                    //     },
                                    //     "http://93.1.6.6:3000"
                                    // )
                                    this.setState({
                                        aboutUsVisible: true,
                                    });
                                }}
                            >
                                关于我们
                            </div>
                        </div>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                size="large"
                                htmlType="submit"
                                block
                                loading={isloading}
                                className="login-btn"
                            >
                                登录
                            </Button>
                        </Form.Item>
                        {/* <div >
                            <div className="about-btn-wrap"
                                onClick={() => {
                                    this.setState({
                                        aboutUsVisible: true
                                    })
                                }}>关于我们</div>

                        </div> */}
                        {/* 关于我们弹窗 */}
                        
                        {/* <AboutUsModal
                            title="关于我们"
                            onCancel={() => {
                                this.setState({
                                    aboutUsVisible: false,
                                });
                            }}
                            visible={this.state.aboutUsVisible}
                        /> */}
                    </Form> 
                </div>
            </div>
        );
    }
}

export default Login;
