/*
 * @Author: lsl
 * @Date: 2023-09-18 11:25:40
 * @Last Modified by: lsl
 * @Last Modified time: 2023-09-18 11:25:40
 */
import React from 'react';
import OrderModal from './OrderModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import BaseTable from '@/components/BaseTable/BaseTable';
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable';
import { Form } from '@ant-design/compatible';
import { message, Button, Input, Popconfirm,Table } from 'antd';
import { post,get } from '@/requests/index';
import { formatterDate } from '@/utils';
import './orderManager.less';

const orderListUrl = 'wxdc/order/page';
@Form.create()
class OrderManager extends React.Component{
    state = {
        tableData: [],
        orderItemList: [],
        orderInfo: {},
        type: 'add'
    };
    //当前正在编辑的订单id
    currentEditOrderId = '';
    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            render: (name, record, index) =>`${index + 1}`,
            width: '10%',   
        },
        {
            title: '商店id',
            dataIndex: 'shopId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '图片路径',
            dataIndex: 'picUrl',
            align: 'center',
            render: (value, record, index) =>{return (<img style={{width:"40px",height:"40px"}} src={ value ? value : 'http://localhost/404.png'}></img>)},
            width: '10%',   
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: (value, record, index) =>`${value ? '正常' : '禁用'}`,
            width: '10%',   
        },
        {
            title: '创建用户',
            dataIndex: 'userId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            render: value => formatterDate(value),
            width: '10%',   
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '40%',
            render: (value, record, index) => {
                return (
                    <div className="actions-buttons">
                        <i
                            className="fa kdfont fa-edit icon-btn"
                            title="编辑订单"
                            onClick={this.editOrderModal.bind(this, record)}
                        />
                        <Popconfirm
                            title="确定删除该订单?"
                            onConfirm={() => this.deleteOrderById(record.id)}
                        >
                            <i
                                className="fa kdfont fa-trash-alt icon-btn"
                                title="删除订单"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },

    ];
    orderItemColumns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            render: (name, record, index) =>`${index + 1}`,
            width: '10%',   
        },
        {
            title: '商店id',
            dataIndex: 'shopId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '图片路径',
            dataIndex: 'picUrl',
            align: 'center',
            render: (value, record, index) =>{return (<img style={{width:"40px",height:"40px"}} src={ value ? value : 'http://localhost/404.png'}></img>)},
            width: '10%',   
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: (value, record, index) =>`${value ? '正常' : '禁用'}`,
            width: '10%',   
        },
        {
            title: '创建用户',
            dataIndex: 'userId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            render: value => formatterDate(value),
            width: '10%',   
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '40%',
            render: (value, record, index) => {
                return (
                    <div className="actions-buttons">
                        <i
                            className="fa kdfont fa-edit icon-btn"
                            title="编辑订单"
                            onClick={this.editOrderModal.bind(this, record)}
                        />
                        <Popconfirm
                            title="确定删除该订单?"
                            onConfirm={() => this.deleteOrderById(record.id)}
                        >
                            <i
                                className="fa kdfont fa-trash-alt icon-btn"
                                title="删除订单"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },

    ];

    // 挂载完成后初始化状态
    componentWillMount() {
        this.getOrderData();
    }

    //编辑订单
    editOrderModal = orderInfo => {
        this.setState({
            modalVisible: true,
            type: 'edit',
            orderInfo: orderInfo,
        });
    };

    //删除订单通过id
    deleteOrderById = async id => {
        let resData = await post('wxdc/order/remove/' + id);
        if (!resData.code){
            message.error(resData.data || '删除失败');
            return;
        }
        this.getTableData({isDelete: true});
        message.success('删除成功')
    };
    addOrderModal =() => {
        this.setState({
            modalVisible: true,
            orderInfo: {},
            type: 'add',
        });
    };
    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
        const { form } = this.formRef.props;
        //重置表单
        form.resetFields();
    };
    
    getOrderData = async () => {
        let resData = await get('wxdc/order/page');
        if (!resData.code) {
            message.error('获取订单失败');
            return;
        }
        this.setState({
            tableData: resData.data.list,
        });
    };
    // 表格扩展渲染页面
    expandedRowRender = record => {
        // 服务默认字段
        const orderItemField = [...this.orderItemColumns];
        // 服务集合
        let { orderItemList } = this.state;

        return (
            <Table
                className="child-table"
                rowKey="id"
                columns={orderItemField}
                dataSource={orderItemList}
                pagination={false}
                onRow={record => {
                    return {
                        onDoubleClick: event => {
                            this.onDoubleClick(record, 2);
                        },
                    };
                }}
            />
        );
    };

    getOrderItemList = async param => {
        // 请求后台机房列表
        let res = await post('wxdc/orderItem/page', param);
        if (!res.code) {
            message.error(res.data || '查询订单项列表失败');
            return;
        }
        this.setState({
            orderItemList: res.data.list,
        });
    };
    // 更改展开图标
    changeExpandIcon = props => {
        // 如果设备时服务不显示展开图标
        if (props.expanded) {
            return (
                <i
                    className="fa kdfont fa-caret-down"
                    onClick={e => {
                        props.onExpand(props.record, e);
                    }}
                ></i>
            );
        }
        return (
            <i
                className="fa kdfont fa-caret-right"
                onClick={e => {
                    props.onExpand(props.record, e);
                }}
            ></i>
        );
    };
    handleCreate = () => {
        const { form, type, orderInfo} = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            //重置表单
            form.resetFields();
            let urlStr = 'wxdc/order/save';
            let params = values;
            const resultTips = type === 'add' ? '添加' : '修改';
            if (type === 'edit'){
                urlStr = 'wxdc/order/update';
                params.id = orderInfo.id;
            } 
            //请求参数
            let resData = await post(urlStr, params);
            if(!resData.code){
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getTableData();
            message.success(resultTips + '成功');
            //保存参数
            this.setState({ modalVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getButtonList = () => {
        return (
            <Button
                title="新增订单"
                className="nms-button"
                onClick={this.addOrderModal}
            >
                <i className="fa kdfont fa-plus"/> 增加
            </Button>
        );
    };
    render() {
        let {
            modalVisible,
            tableData,
            orderItemList,
            orderInfo,
            type
        } = this.state;
        console.log('aaa', this.props)
        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="order-manager">
                <ResponsiveTable
                        rowKey={record => record.id}
                        dataSource={tableData}
                        columns={this.columns}
                        className="nms-table nms-modal-table"
                        pagination={false}
                        expandedRowRender={this.expandedRowRender.bind(this)}
                        expandIcon={this.changeExpandIcon}
                        defaultExpandAllRows={true}
                        onRow={record => {
                            return {
                                onDoubleClick: event => {
                                    this.onDoubleClick(record, 1);
                                },
                            };
                        }}
                    />
                    <OrderModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={modalVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        orderInfo={orderInfo}
                        type={type}
                    />
                </div>
            </InnerPage>
        );
    }
}


export default OrderManager;