import React from 'react';
import SiderCustom from '../SiderCustom/SiderCustom';
import Client from '@/Client';
import HeaderCustom from '@/components/HeaderCustom/HeaderCustom';
import Floor from '@/components/Floor/MainFloor';
import { withRouter } from 'react-router-dom';
import { get } from '@/requests/index';
import { connect } from 'react-redux';
import { saveUserInfo } from '@/redux/userManager/userManager';
import { Layout, message, Spin } from 'antd';
import { encrypt, decrypt } from '@/utils/secret'; // 密码加密
import { Steps } from 'intro.js-react';
import { getCookie, setCookie, getPort } from "@/utils";


const { Content, Sider } = Layout;

const mapStateToProps = state => {
    const { permission, menus, user } = state.userManager.userInfo;
    return {
        permission,
        menus,
        user,
    };
};

const mapDistchToProps = distch => {
    return {
        getUserInfo: userInfo => {
            distch(saveUserInfo(userInfo));
        },
    };
};

function flatLists(arr) {
    let result = [];
    arr.forEach(item => {
        const text = item.text;
        const url = item.attributes.url;
        result.push({ text, url });
        if (item.children && item.children.length > 0) {
            result = result.concat(flatLists(item.children));
        }
    });
    return result;
}

/**
 * @export IndexRoute
 * @class IndexRoute
 * @extends {React.Component}
 */
@withRouter
@connect(mapStateToProps, mapDistchToProps)
class IndexRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            isLoading: false,
            enabled: false,
            menuLists: [],
        };
    }
    toggle = () => {
        this.setState(
            {
                collapsed: !this.state.collapsed,
            },
            () => {
                setTimeout(() => {
                    window.dispatchEvent(window.divResize);
                }, 200);
            }
        );
    };
    componentWillMount() {
        const { getUserInfo } = this.props;
        this.setState({
            isLoading: true,
        });
        // 托盘模式; 后端url 带token 重写 cookie;
        if (window.location.href.match(/(\?token\=.*)/)) {
            var arr = window.location.href.match(/(\?token\=.*)/)

            function getQueryString(search) {
                let query = search.split('&')
                query = query.map(item => { return item.split('=') })
                if (query.length > 0) {
                    setCookie("token-" + getPort(), query[0][1]);
                    setCookie("JSESSIONID", query[0][1]);
                    let href = window.location.href;
                    let url = ''
                    let l = query.length - 1;
                    if (l > 0) {
                        query.slice(1).forEach(i => {
                            url += `${i[0]}=${i[1]}${i < (l - 1) ? '&' : ''}`
                        })

                    }
                    window.location.href = href.replace(/(\?token\=.*)/, '?' + url)
                }

            }
            getQueryString(arr[0].slice(1))

        }

        get('auth/user/getCurrentUser').then(res => {
            if (!res.code) {
                message.error(res.data || '请求菜单失败');
                return;
            }
            const { data } = res;
            if (data) {
                getUserInfo(data);
                // 密码加密存入缓存
                data.user.password = encrypt(data.user.password);
                // 密码解密 decrypt(data.user.password)
                let userInfo = {
                    permission: data.permission,
                    menus: data.menus,
                    user: data.user
                }
                // 缓存到浏览器
                sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                this.setState({
                    isLoading: false,
                });
                if (data.menus) {
                    const resList = flatLists(data.menus);
                    this.setState({
                        menuLists: resList
                    })
                }
            } else {
                // window.location.hash = '/client/login';
            }
        }).catch(e => {
            window.location.hash = '/client/login';
        });
    }

    componentDidMount = () => {
        if (!localStorage.getItem('isNewUser')) {
            this.setState({
                enabled: true,
            });
        }
    };

    onExit = e => {
        this.setState({
            enabled: false,
        });
        localStorage.setItem('isNewUser', 'no');
    };

    showIntro = () => {
        this.setState({
            enabled: true,
        });
    };

    render() {
        const { collapsed, enabled, menuLists } = this.state;
        const { isSinglePage } = window.appConfig
        if (menuLists.length !== 0) {
            const currentRoute = menuLists.find(item => item.url === this.props.location.pathname);
            if (currentRoute) {
                document.title = `后台管理系统 - ${currentRoute.text}`;
            }
        }
        if (this.state.isLoading) {
            return (
                <Spin size="large">
                    <Layout className="main-page" />
                </Spin>
            );
        }
        // className="ant-layout-has-sider" 为了在didmount时，获取dom宽高
        return (
            <Layout className="main-page">
                {/* 新用户引导组件 */}
                {
                    !isSinglePage && <>
                        <Steps
                            enabled={enabled}
                            initialStep={0}
                            onExit={this.onExit}
                            options={{
                                nextLabel: '下一步',
                                prevLabel: '上一步',
                                skipLabel: '跳过',
                                doneLabel: '知道了',
                                hidePrev: true,
                                hideNext: true,
                                exitOnOverlayClick: false,
                                overlayOpacity: 0.7,
                                tooltipClass: 'nms-intro-tooltip',
                                highlightClass: 'high-light-tooltip',
                                showStepNumbers: false,
                                showProgress: false,
                                scrollToElement: false,
                            }}
                            steps={[
                                {
                                    element: '.custom-trigger',
                                    intro: '点击这里可以收缩/展开菜单。',
                                },
                                {
                                    element: '.fa-user',
                                    intro: '点击这里可以进行用户退出、新手引导操作。',
                                },
                            ]}
                        />
                        <HeaderCustom
                            collapsed={collapsed}
                            toggle={this.toggle}
                            showIntro={this.showIntro}
                        />
                    </>
                }

                <Layout className="ant-layout-has-sider">
                    {
                        !isSinglePage && <Sider
                            className={
                                this.state.collapsed
                                    ? 'menu-unfold menu-sider'
                                    : 'menu-fold menu-sider'
                            }
                            trigger={null}
                            collapsible
                            collapsedWidth={50}
                            collapsed={this.state.collapsed}
                        >
                            <SiderCustom
                                {...this.props}
                                hoverSetCollapsed={this.hoverSetCollapsed}
                                hoverCollapsed={this.state.hoverCollapsed}
                                collapsed={this.state.collapsed}
                            />
                        </Sider>
                    }

                    <Content>
                        <Client />
                    </Content>
                </Layout>
                <Layout style={{height: '4%'}}>
                    <Floor />
                </Layout>
            </Layout>
        );
    }
}
export default IndexRoute;
