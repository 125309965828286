import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import { CustomUpload } from "@/components/FormItem/DynamicFromItem"
import { telReg } from "@/utils/validateReg";
import { Form } from "@ant-design/compatible";
import { post } from "@/requests/index";
import { Input, Radio, message, TreeSelect } from "antd";

// @Form.create()
class BannerModal extends React.Component {
    state = {
        shopData: [], //商店
    };
    handOk = () => {
        this.props.onCreate();
    };
    componentDidMount(){
        this.getShopData();
    }
    //获取商店信息
    getShopData = async() => {
        let resData = await post("wxdc/shop/list");
        if(!resData.code) message.error(resData.data || "请求数据失败");
        const children = resData.data;
        //格式化数据
        const targetData = this.formatData(children);
        console.log('targetData', targetData)
        this.setState({
            shopData: targetData,
        });
    };
    //格式化数据
    formatData = (data) => {
        let targetData = [];
        data && data.forEach((ele) => {
            ele.key = ele.value = ele.id;
            ele.title = ele.name;
            targetData.push(ele);
        });
        return targetData;
    };
    render() {
        const { shopData } = this.state;
        console.log('shopData',shopData)
        const { visible, onCancel, form, bannerInfo, type } = this.props;
        const passwordVisible = type === "add";
        const { getFieldDecorator} = form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="user-manager"
                    title={(type === "add" ? "新增" : "编辑") + "轮播图"}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={this.handOk}
                >
                    <Form layout="horizontal" className="nms-form">
                    <Form.Item label="所属商家" {...formItemLayout}>
                            {getFieldDecorator("shopId", {
                                initialValue: bannerInfo?.shopId,
                                rules: [{ required: true, message: "请选择商家" }],
                            })(
                                <TreeSelect
                                    className="nms-select-bottom"
                                    style={{ width: "100%" }}
                                    allowClear={true}
                                    dropdownClassName="nms-tree-select-dropdown"
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    treeData={shopData}
                                    placeholder="请选择商家"
                                    treeDefaultExpandAll
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="上传轮播图" {...formItemLayout}>
                            {getFieldDecorator('picUrl', {
                                initialValue:  bannerInfo?.picUrl,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请上传轮播图',
                                    },
                                ],
                            })(
                                <CustomUpload
                                    placeholder="请上传轮播图"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="状态" {...formItemLayout}>
                            {getFieldDecorator("status", {
                                initialValue: bannerInfo.hasOwnProperty("status")
                                    ? bannerInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        正常
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        禁用
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        )
    }
}

export default Form.create()(BannerModal);