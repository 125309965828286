/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-06-04 15:17:52
 */
import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import MenuTree from "@/components/MenuTree/MenuTree";
import { Form } from "@ant-design/compatible";
import { specialCharacters } from "@/utils/validateReg";
import { Input, Spin } from "antd";

class RoleModal extends React.Component {
    state = {
        // loading: false,
    };
    handOk = async () => {
        // 映射选中的菜单id
        this.child.getMenuIds();
        this.props.onCreate();
    };

    render() {
        const { visible, onCancel, form, type, roleInfo, loading } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="role-manager"
                    title={(type === "add" ? "新增" : "编辑") + "角色"}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={this.handOk}
                    height="65%"
                    okButtonProps={{ loading }}
                    cancelButtonProps={{ loading }}
                >
                    <Spin spinning={loading}>
                        <Form layout="horizontal" className="nms-form">
                            <Form.Item label="角色名" {...formItemLayout}>
                                {getFieldDecorator("roleName", {
                                    initialValue: roleInfo.roleName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请输入角色名",
                                        },
                                        {
                                            pattern: specialCharacters,
                                            message: "仅支持特殊字符 - _ ( )",
                                        },
                                        {
                                            max: 10,
                                            message: "角色名长度不能超过10个字符",
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="请输入角色名"
                                        className="nms-input-bottom"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="备注" {...formItemLayout}>
                                {getFieldDecorator("remark", {
                                    initialValue: roleInfo.remark,
                                    rules: [
                                        {
                                            max: 20,
                                            message: "备注字段不超过20个字符",
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="请输入备注"
                                        className="nms-input-bottom"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="权限" {...formItemLayout}>
                                {getFieldDecorator("roleSign", {
                                    initialValue: roleInfo.remark,
                                    rules: [
                                        {
                                            max: 20,
                                            message: "权限字段不超过20个字符",
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="请输入权限"
                                        className="nms-input-bottom"
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="菜单权限" {...formItemLayout}>
                                {getFieldDecorator("menuIds", {
                                    initialValue: roleInfo.menuIds || [],
                                })(
                                    <MenuTree
                                        onRef={(targrtCom) => {
                                            this.child = targrtCom;
                                        }}
                                        roleId={roleInfo.id}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </Spin>
                </DragModal>
            </div>
        );
    }
}
export default Form.create()(RoleModal);
