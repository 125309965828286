import React, { useState } from "react";
import HelpModal from "./HelpModal";

const HelpIcon = ({ helpText }) => {
    const [visible, setVisible] = useState(false);

    if (!helpText) {
        return null;
    }

    return (
        <span
            onClick={() => {
                setVisible(true);
            }}
            className="help-icon-wraper"
            style={{ paddingLeft: "8px", cursor: "pointer" }}
        >
            <i className="fa kdfont fa-question-circle"></i>

            <HelpModal
                title="帮助文档"
                data={helpText}
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </span>
    );
};

export default HelpIcon;
