import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import { CustomUpload } from "@/components/FormItem/DynamicFromItem"
import { telReg } from "@/utils/validateReg";
import { Form } from "@ant-design/compatible";
import { post } from "@/requests/index";
import moment from "moment";
import { Input, Radio, message, TreeSelect, DatePicker, Row, TimePicker } from "antd";
const { RangePicker } = DatePicker
// @Form.create()
class ShopModal extends React.Component {
    state = {
        shopData: [], //商店
    };
    handOk = () => {
        this.props.onCreate();
    };
    componentDidMount() {
        this.getShopData();
    }
    //获取商店信息
    getShopData = async () => {
        let resData = await post("wxdc/shop/list");
        if (!resData.code) message.error(resData.data || "请求数据失败");
        const { children = [] } = resData.data;
        //格式化数据
        const targetData = this.formatData(children);
        this.setState({
            shopData: targetData,
        });
    };
    //格式化数据
    formatData = (data) => {
        let targetData = [];
        data && data.forEach((ele) => {
            ele.key = ele.value = ele.id;
            ele.title = ele.name;
            targetData.push(ele);
        });
        return targetData;
    };
    render() {
        const { shopData } = this.state;
        const { visible, onCancel, form, shopInfo, type } = this.props;
        const passwordVisible = type === "add";
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="user-manager"
                    title={(type === "add" ? "新增" : "编辑") + "商店"}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={this.handOk}
                >
                    <Form layout="horizontal" className="nms-form">
                        <Form.Item label="商家名称" {...formItemLayout}>
                            {getFieldDecorator('name', {
                                initialValue: shopInfo.hasOwnProperty("name")
                                    ? shopInfo.name
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入商店名称',
                                    },
                                    {
                                        max: 20,
                                        message: '商店名称不超过20个字符',
                                    },
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商店名称"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="商店地址" {...formItemLayout}>
                            {getFieldDecorator('address', {
                                initialValue: shopInfo.hasOwnProperty("address")
                                    ? shopInfo.address
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入商店地址',
                                    }
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商店地址"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="经度" {...formItemLayout}>
                            {getFieldDecorator('longitude', {
                                initialValue: shopInfo.hasOwnProperty("longitude")
                                    ? shopInfo.longitude
                                    : "",
                                rules: [
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商店经度"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="纬度" {...formItemLayout}>
                            {getFieldDecorator('latitude', {
                                initialValue: shopInfo.hasOwnProperty("latitude")
                                    ? shopInfo.latitude
                                    : "",
                                rules: [
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商店纬度"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="联系电话" {...formItemLayout}>
                            {getFieldDecorator('linkPhone', {
                                initialValue: shopInfo.hasOwnProperty("linkPhone")
                                    ? shopInfo.linkPhone
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入商店联系电话',
                                    }
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入商店联系电话"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="是否显示联系方式" {...formItemLayout}>
                            {getFieldDecorator("linkPhoneShow", {
                                initialValue: shopInfo.hasOwnProperty("linkPhoneShow")
                                    ? shopInfo.linkPhoneShow
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        显示
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        不显示
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="开业时间" {...formItemLayout}>
                            {getFieldDecorator("openingHoursArray", {
                                initialValue:
                                    type === "add"
                                        ? [moment("00:00", "HH:mm"), moment("00:00", "HH:mm")]
                                        : [moment(shopInfo.openingHours.split('-')[0], "HH:mm"), moment(shopInfo.openingHours.split('-')[1], "HH:mm")],
                                rules: [
                                    {
                                        required: true,
                                        message: "请选择时间范围",
                                    },
                                ],
                            })(
                                <TimePicker.RangePicker
                                    style={{ width: "100%" }}
                                    className="nms-select-bottom"
                                    format={"HH:mm"}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="wifi账号" {...formItemLayout}>
                            {getFieldDecorator('wifiSsid', {
                                initialValue: shopInfo.hasOwnProperty("wifiSsid")
                                    ? shopInfo.wifiSsid
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入wifi账号',
                                    }
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入wifi账号"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="wifi密码" {...formItemLayout}>
                            {getFieldDecorator('wifiPass', {
                                initialValue: shopInfo.hasOwnProperty("wifiPass")
                                    ? shopInfo.wifiPass
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入wifi密码',
                                    }
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入wifi密码"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="wifi物理地址" {...formItemLayout}>
                            {getFieldDecorator('wifiBssid', {
                                initialValue: shopInfo.hasOwnProperty("wifiBssid")
                                    ? shopInfo.wifiBssid
                                    : "",
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入wifi物理地址',
                                    }
                                ],
                            })(
                                <Input
                                    className="nms-input-bottom"
                                    placeholder="请输入wifi物理地址"
                                    allowClear
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="工作状态" {...formItemLayout}>
                            {getFieldDecorator("workStatus", {
                                initialValue: shopInfo.hasOwnProperty("workStatus")
                                    ? shopInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" style={{ color: '#bccdd6' }} value={1}>
                                        营业中
                                    </Radio>
                                    <Radio className="nms-radio" style={{ color: '#bccdd6' }} value={0}>
                                        打烊了
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label="状态" {...formItemLayout}>
                            {getFieldDecorator("status", {
                                initialValue: shopInfo.hasOwnProperty("status")
                                    ? shopInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        正常
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        禁用
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        )
    }
}

export default Form.create()(ShopModal);