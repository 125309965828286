/* eslint-disable no-unused-vars */
import axios from 'axios';
import { message } from 'antd';
import { getCookie, delCookie, getPort } from '@/utils';
const base = '/api/';

// 登录超时定时器
let timer = null;
// 请求前拦截
axios.interceptors.request.use(
    config => {
        const token = getCookie('token-' + getPort());
        config.headers.token = token;
        return config;
    },
    err => {
        message.error('网络请求超时');
        return Promise.reject(err);
    }
);

// 返回后拦截
axios.interceptors.response.use(
    data => {
        return data.data;
    },
    err => {
        // 首先销毁之前的值
        message.destroy();
        let status = err?.response?.status ?? 0
        console.log(status);
        // let { response: { status = 0 } } = err
        switch (status) {
        
            case 502:
            case 504:
                // if (window.location.hash === '#/client/login') {
                message.error('服务器未启动或异常。', 1);
                // } else {
                // window.location.hash = '/client/login';
                // }
                break
            case 401:
                window.location.hash = '/client/login';
                message.warning('登录信息失效');
                // delCookie('token-' + getPort());
                break
            case 404:
                message.warning('请求资源已重定向', 1);
                break
            case 405:
                message.warning('请求方式错误', 1);
                break;
            default:
                break;
        }
        return Promise.reject(err);
    }
);

const getEncode = params => {
    for (const key in params) {
        if (typeof params[key] === 'string') {
            params[key] = encodeURIComponent(params[key]);
        }
    }
    return params;
};
// @RequestBody请求
export const post = (url, params = {}, baseUrl, options = {}) => {
    if (url?.startsWith('approve/')) {
        let userInfo = {}
        try {
            userInfo = JSON.parse(sessionStorage.getItem('userInfo')).user
        } catch (err) { userInfo = {} }
        params.loginUserId = userInfo?.id
        params.loginUserName = userInfo?.userName
        return axios({
            method: 'post',
            url,
            data: params,
            headers: {
                'Content-Type': 'application/json',
                charset: 'utf-8',
            },
            ...options
        });
    } else return axios({
        method: 'post',
        url: `${baseUrl || base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/json',
            charset: 'utf-8',
        },
        ...options
        // crossDomain:true,
        // xhrFields: { withCredentials: true }
    });
};

// @RequsetParam请求
export const postRequestParam = (url, params, baseUrl) => {
    return axios({
        method: 'post',
        url: `${baseUrl || base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const get = (url, params = {}, baseUrl) => {
    if (url?.startsWith('approve/')) {
        let userInfo = {}
        try {
            userInfo = JSON.parse(sessionStorage.getItem('userInfo')).user
        } catch (err) { userInfo = {} }
        params.loginUserId = userInfo?.id
        params.loginUserName = userInfo?.userName
        return axios({
            method: 'get',
            url: setParamsToUrl(url, params),
        });
    } else return axios({
        method: 'get',
        url: setParamsToUrl(`${baseUrl || base}${url}`, params),
    });
};

const setParamsToUrl = (url, params) => {
    let urlStr = url;
    if (!params) return urlStr;
    // 过滤掉不存在的字段
    let newParams = Object.keys(params).filter(ele => !!params[ele]);
    if (newParams && newParams.length) {
        urlStr += '?';
        newParams.forEach((item, index) => {
            if (params[item] === undefined || params[item] === null) {
                return;
            }
            // encodeURIComponent 转义特殊字符
            urlStr += item + '=' + encodeURIComponent(params[item]) + (index === newParams.length - 1 ? "" : '&');
        });
    }

    return urlStr;
};

// 发送表单数据
export const sendForm = (url, data) => {
    return axios({
        method: 'POST',
        url: `${url}`,
        data,
        headers: {
            charset: 'UTF-8',
            'Content-Type': 'application/x-www-form-urlencoded',
            'cache-control': 'no-cache',
            // 'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        },
        withCredentials: true,
    });
};
export const upload = (url, data, onUploadProgress) => {
    return axios({
        method: 'POST',
        url: `${base}${url}`,
        data,
        headers: {
            charset: 'UTF-8',
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
};

const multiple = function (requsetArray, callback) {
    axios.all(requsetArray).then(axios.spread(callback));
};
