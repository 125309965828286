/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-07-02 14:50:44
 */
import React from 'react';
import { connect } from 'react-redux';
import { TreeSelect } from 'antd';

// 映射组件状态
const mapStateToProps = state => {
    let { userManager } = state;
    return {
        ...userManager,
    };
};
/**
 *树形菜单下拉框自定义组件
 *
 * @class MenuTreeSelect
 * @extends {React.Component}
 */
@connect(mapStateToProps)
class MenuTreeSelect extends React.Component {
    getTreeData = data => {
        if (!data || !data.length) return [];
        let result = [];
        data.forEach(ele => {
            let {
                text,
                attributes: { url },
                children,
            } = ele;
            let singleData = {
                title: text,
                value: url,
                disabled: true,
                children: [],
            };
            children.forEach(item => {
                let {
                    text,
                    attributes: { url },
                } = item;
                singleData.children.push({
                    title: text,
                    value: url,
                });
            });
            result.push(singleData);
        });
        return result;
    };
    render() {
        let { userInfo, value, onChange } = this.props;
        let { menus = [] } = userInfo;

        const treeData = this.getTreeData(menus);
        return (
            <TreeSelect
                className="nms-select-bottom"
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                }}
                allowClear
                value={value}
                onChange={onChange}
                treeData={treeData}
                placeholder="请选择页面"
                treeDefaultExpandAll
            />
        );
    }
}
export default MenuTreeSelect;
