/*
 * @Author: lsl
 * @Date: 2023-09-18 11:25:40
 * @Last Modified by: lsl
 * @Last Modified time: 2023-09-18 11:25:40
 */
import React from 'react';
import BannerModal from './BannerModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import BaseTable from '@/components/BaseTable/BaseTable';
import DragModal from '@/components/DragModal/DragModal';
import { Form } from '@ant-design/compatible';
import { message, Button, Input, Popconfirm } from 'antd';
import { post } from '@/requests/index';
import { formatterDate } from '@/utils';
import './bannerManager.less';

const bannerListUrl = 'wxdc/banner/page';
@Form.create()
class BannerManager extends React.Component{
    state = {
        modalVisible: false, //modal是否可见
        type: 'add',
        bannerInfo: {}
    };
    //当前正在编辑的轮播图id
    currentEditBannerId = '';
    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            render: (name, record, index) =>`${index + 1}`,
            width: '5%',   
        },
        {
            title: '商店id',
            dataIndex: 'shopId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '5%',   
        },
        {
            title: '图片路径',
            dataIndex: 'picUrl',
            align: 'center',
            render: (value, record, index) =>{return (<img style={{width:"40px",height:"40px"}} src={ value ? value : 'http://localhost/404.png'}></img>)},
            width: '10%',   
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: (value, record, index) =>`${value ? '正常' : '禁用'}`,
            width: '5%',   
        },
        {
            title: '创建用户',
            dataIndex: 'userId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '5%',   
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            render: value => formatterDate(value),
            width: '5%',   
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '15%',
            fixed: 'right',
            render: (value, record, index) => {
                return (
                    <div className="actions-buttons">
                        <i
                            className="fa kdfont fa-edit icon-btn"
                            title="编辑轮播图"
                            onClick={this.editBannerModal.bind(this, record)}
                        />
                        <Popconfirm
                            title="确定删除该轮播图?"
                            onConfirm={() => this.deleteBannerById(record.id)}
                        >
                            <i
                                className="fa kdfont fa-trash-alt icon-btn"
                                title="删除轮播图"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },

    ];

    //编辑轮播图
    editBannerModal = bannerInfo => {
        this.setState({
            modalVisible: true,
            type: 'edit',
            bannerInfo: bannerInfo,
        });
    };

    //删除轮播图通过id
    deleteBannerById = async id => {
        let resData = await post('wxdc/banner/remove/' + id);
        if (!resData.code){
            message.error(resData.data || '删除失败');
            return;
        }
        this.getTableData({isDelete: true});
        message.success('删除成功')
    };
    addBannerModal =() => {
        this.setState({
            modalVisible: true,
            bannerInfo: {},
            type: 'add',
        });
    };
    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
        const { form } = this.formRef.props;
        //重置表单
        form.resetFields();
    };
    //获取表格数据
    getTableData = (params = {}) => {
        console.log('111111')
        this.baseTable && this.baseTable.getTableData(params);
    };
    handleCreate = () => {
        const { form, type, bannerInfo} = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            //重置表单
            form.resetFields();
            let urlStr = 'wxdc/banner/save';
            let params = values;
            const resultTips = type === 'add' ? '添加' : '修改';
            if (type === 'edit'){
                urlStr = 'wxdc/banner/update';
                params.id = bannerInfo.id;
            } 
            //请求参数
            let resData = await post(urlStr, params);
            if(!resData.code){
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getTableData();
            message.success(resultTips + '成功');
            //保存参数
            this.setState({ modalVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getButtonList = () => {
        return (
            <Button
                title="新增轮播图"
                className="nms-button"
                onClick={this.addBannerModal}
            >
                <i className="fa kdfont fa-plus"/> 增加
            </Button>
        );
    };
    render() {
        const {
            modalVisible,
            type,
            bannerInfo,
        } = this.state;
        console.log('aaa', this.props)
        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="banner-manager">
                    <BaseTable
                        height="100%"
                        onRef={ref => (this.baseTable = ref)}
                        url={bannerListUrl}
                        axiosType="get"
                        allColumn={this.columns}
                        onRow={record => {
                            return {
                                onDoubleClick: () => this.editBannerModal(record),
                            };
                        }}
                    />
                    <BannerModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={modalVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        bannerInfo={bannerInfo}
                        type={type}
                    />
                </div>
            </InnerPage>
        );
    }
}


export default BannerManager;