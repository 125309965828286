import React from "react";
import allComponents from "@/routers/allComponent";
import otherRouters from "@/routers/otherRouters";
import routesMaps from "@/routers/routesMaps";
import queryString from "query-string";
import IframeComp from "@/components/IframeComp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCookie, getPort } from "@/utils";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

// 当找不到组件时的替代组件；
const defaultPage = () => {
    return <div>页面未开发</div>;
};

const formaterRouter = (menus) => {
    let targetMenus =
        menus &&
        menus.map((element) => {
            let { attributes, text } = element;
            let { url, icon, helpText } = attributes;
            return element.children.length
                ? {
                      key: url,
                      title: text,
                      icon: "fa kdfont " + icon,
                      helpText: helpText ? helpText : "",
                      subs: formaterRouter(element.children),
                  }
                : {
                      key: url,
                      title: text,
                      icon: "fa kdfont " + icon,
                      helpText: helpText ? helpText : "",
                      component: getComponentByKey(url),
                  };
        });
    return targetMenus;
};

const getComponentByKey = (key) => {
    let routerMap = routesMaps.find((item) => {
        return item.key === key;
    });
    return routerMap && routerMap.component;
};

// 映射状态到属性
const mapStateToProps = (state) => {
    let { menus } = state.userManager.userInfo;
    return {
        menus,
    };
};

// 映射dispatch到属性
const mapDispachToProps = (dispach) => ({
    // pageChange: bindActionCreators(pageChange, dispach),
});

/**
 * @feature 客户端登路由
 * @class Client
 * @extends {React.Component}
 */
@withRouter
@connect(mapStateToProps, mapDispachToProps)
class Client extends React.Component {
    requireLogin = (Component, merge) => {
        // 根据cookie里面的token来判断是否登录
        let token = getCookie('token-' + getPort());
        // 解构页面改变方法
        // 判断是否是首页
        // let { component } = merge;
        // if (component === "Home") {
        //     // this.props.pageChange(true);
        // } else {
        //     this.props.pageChange(false);
        // }

        // 验证是否登录
        return !token ? (
            <Redirect to="/client/login" />
        ) : (
                <Component {...merge} />
            );
        // return <Component {...merge} />;
    };

    route(r, pr) {
        if (/http/.test(r.key)) {
            let path;
            // 区别于iframe菜单是否在子菜单
            if (/^http/.test(r.key)) {
                //非子菜单
                path = `/client/iframe/${r.key}`;
            } else {
                //在子菜单
                path = r.key;
            }
            return (
                <Route
                    exact
                    key={r.key}
                    path={path}
                    render={(props) => {
                        return this.requireLogin(IframeComp, {
                            ...r,
                            ...props,
                            title: r.title,
                        });
                    }}
                />
            );
        } else {
            let Component = allComponents[r.component] || defaultPage;
            return (
                <Route
                    key={r.route || r.key}
                    path={r.route || r.key}
                    render={(props) => {
                        let reg = /\?\S*/g;
                        // 匹配?及其以后字符串
                        let queryParams = window.location.hash.match(reg);
                        // 去除?的参数
                        let { params } = props.match;
                        Object.keys(params).forEach((key) => {
                            params[key] = params[key] && params[key].replace(reg, "");
                        });
                        props.match.params = { ...params };

                        let merge = {
                            ...props,
                            ...r,
                            query: queryParams ? queryString.parse(queryParams[0]) : {},
                        };
                        return this.requireLogin(Component, merge);
                    }}
                />
            );
        }
    }

    render() {
        let { menus } = this.props;
        if (!menus) {
            return (
                <Switch>
                    <Route render={() => <Redirect to="/client/login" />} />
                </Switch>
            );
        }
        let targetMenus = formaterRouter(menus);

        // 单独处理非左侧菜单导航
        targetMenus = targetMenus.concat(otherRouters);
        return (
            <Switch>
                {targetMenus.map((r) => {
                    return r.subs
                        ? r.subs.map((sr) => this.route(sr, r))
                        : this.route(r);
                })}
                {/* <Route render={() => <Redirect to="/client/404" />} /> */}
            </Switch>
        );
    }
}

export default Client;
