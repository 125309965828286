import React, { useEffect, useState, useRef } from 'react'
import BaseTable from '@/pages/ProcessManager/WorkSheet/ComPonents/BaseTable'
import { get, post } from '@/requests'
import { Button, message, Popconfirm } from 'antd'
import ResizeableTitle from '@/components/ResizeableTitle/ResizeableTitle'
import YzmModal from './YzmModal'
import { formatterDate } from '@/utils'
export default function Yzm(props) {
    const { type, saveList, allDictInfo } = props
    const [condition, setCondition] = useState({ monitorTemplateId: type })
    const [visible, setVisible] = useState(false)
    const [record, setRecord] = useState()
    const [operation, setoperation] = useState()
    const ref = useRef()
    const column = [
        {


            title: '序号',
            dataIndex: 'index',
            // align: 'center',
            width: 55,
            defaultShow: true,
            fixed: 'left',
            render: (name, record, index) => `${index + 1} `,
        },
        {
            title: '图片路径',
            width: 600,
            fixed: 'left',
            dataIndex: 'filePath',
            defaultShow: true,
            span: 24,
            render: (value, record, index) => {
                return value ?? '--'
            },
        },
        {
            title: '识别结果',
            span: 24,
            dataIndex: 'result',
            defaultShow: true,
            render: (value, record, index) => {
                return value ?? '--'
            },
        },
        {
            title: '验证码状态',
            span: 24,
            dataIndex: 'status',
            defaultShow: true,
            render: (value, record, index) => {
                return value === 0 ? (
                    <span style={{ color: '#e74848' }}>未处理</span>
                ) : value === 1 ? (
                    <span style={{ color: '#23ed23' }}>已处理</span>
                ) : (
                    '已验证'
                )
            },
        },
        {
            title: '创建时间',
            span: 24,
            dataIndex: 'createTime',
            defaultShow: true,
            render: formatterDate,
        },
        {
            title: '操作',
            align: 'right',
            render: record => {
                return (
                    <>
                        {record.status === 0 ? (
                            <i
                                title="修改"
                                className="fa kdfont fa-edit icon-btn"
                                style={{ color: '#00ccde' }}
                                onClick={() => {
                                    setRecord(record)
                                    setVisible(true)
                                    setoperation('edit')
                                }}
                            ></i>
                        ) : (
                            ''
                        )}
                        <Popconfirm
                            title="确定删除?"
                            onConfirm={e => {
                                e.stopPropagation()
                                handleDelete(record)
                            }}
                        >
                            <i
                                title="删除"
                                className="fa kdfont fa-trash-alt icon-btn"
                                style={{ color: '#00ccde' }}
                            ></i>
                        </Popconfirm>
                    </>
                )
            },
        },
    ]
    const fields = [
        {
            name: 'status',
            title: '验证码状态',
            type: 'string',
            span: 6,
        },
    ]
    const handleDelete = async record => {
        const res = await get(`kd/center/yzm/remove/${record.id}`)
        if (res.code !== 1) {
            return message.error(res?.data ?? '删除失败')
        }
        message.success('删除成功')
        setCondition({ ...condition })
    }
    const onSearch = vals => {
        setCondition({
            ...condition,
            ...vals,
        })
    }
    const components = {
        header: {
            cell: ResizeableTitle,
        },
    }

    return (
        <div className="table-warp">
            {/* <div className="rightTop-btn-box" style={{ 'margin-top': '20px' }}>
                <Button
                    size="middle"
                    onClick={() => {
                        setVisible(true)
                    }}
                >
                    新增模板
                </Button>
            </div> */}
            <BaseTable
                columns={column}
                components={components}
                rowKey={record => record.id}
                query={condition}
                axiosType="post"
                axiosUrl="kd/center/yzm/page"
            ></BaseTable>
            <YzmModal
                operation={operation}
                typeId={type}
                setRecord={setRecord}
                setCondition={setCondition}
                condition={condition}
                saveList={saveList}
                allDictInfo={allDictInfo}
                visible={visible}
                setVisible={setVisible}
                column={column}
                record={record}
            ></YzmModal>
        </div>
    )
}
