import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import ConfigTree from "./ConfigTree";
import { Form } from "@ant-design/compatible";
import { Input, message } from "antd";
import { specialCharacters } from "@/utils/validateReg";
import { connect } from "react-redux";
// 映射状态到组件
const mapStateToProps = state => {
    let { dictionaryManager } = state;
    return dictionaryManager;
};

/**
 * @feature 添加或编辑公告弹窗
 * @export DictionaryModal
 * @class DictionaryModal
 * @extends {React.Component}
 */
@connect(mapStateToProps, null)
@Form.create()
class DictionaryModal extends React.Component {
    onCancel = () => {
        let { closeModal, form } = this.props;
        closeModal && closeModal();
        // 清空表单
        form.resetFields();
    };
    onOk = () => {
        let { onOk, form, editKey } = this.props;
        let { validateFields } = form;

        validateFields((error, value) => {
            if (error) return;
            if (editKey) {
                message.error("请先完成编辑");
                return;
            }
            onOk && onOk(value);
            // configValue = [{ "title": "运维系统", "key": "system_name$system_name$k1", "children": [{ "title": "运维子系统", "key": "system_name$system_name$k1$k1_1" }] }, { "title": "成都分系统", "key": "system_name$system_name$k2", "children": [{ "title": "数据处理分系统", "key": "system_name$system_name$k2$system_name$k2_1" }] }]
        });
    };

    render() {
        let { addDictionaryVisible, dictionaryInfo, dictionaryTree } = this.props;
        console.log('dictionaryTree',dictionaryTree)
        let { getFieldDecorator } = this.props.form;
        let formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 },
        };
        let { status, configKey } = dictionaryInfo;
        return (
            <DragModal
                title={Object.keys(dictionaryInfo).length ? "修改字典" : "添加字典"}
                visible={addDictionaryVisible}
                width="30%"
                height={550}
                onOk={this.onOk}
                onCancel={this.onCancel}
                className="dictionary-modal"
            >
                <Form className="nms-form">
                    <Form.Item label="字典名称" {...formItemLayout}>
                        {getFieldDecorator("configName", {
                            initialValue: dictionaryInfo.configName,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入字典名称",
                                },
                                {
                                    max: 10,
                                    message: "字典名称不超过10个字符",
                                },
                                {
                                    pattern: specialCharacters,
                                    message: "仅支持特殊字符 - _ ( )",
                                },
                            ],
                        })(
                            <Input
                                disabled={!!status}
                                className="nms-input-bottom"
                                placeholder="请输入字典名称"
                                allowClear
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="字典KEY" {...formItemLayout}>
                        {getFieldDecorator("configKey", {
                            initialValue: dictionaryInfo.configKey,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入字典KEY",
                                },
                                {
                                    max: 20,
                                    message: "字典KEY不能超过20个字符",
                                },
                                {
                                    pattern: "^[A-Za-z][A-Za-z_]*$",
                                    message: "以字母开头且仅包含字母或下划线",
                                },
                                {
                                    pattern: specialCharacters,
                                    message: "仅支持特殊字符 - _ ( )",
                                },
                            ],
                        })(
                            <Input
                                disabled={!!status}
                                className="nms-input-bottom"
                                placeholder="请输入字典KEY"
                                allowClear
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="字典描述" {...formItemLayout}>
                        {getFieldDecorator("configDesc", {
                            initialValue: dictionaryInfo.configDesc,
                            rules: [
                                {
                                    max: 50,
                                    message: "字典描述不能超过50个字符",
                                },
                            ],
                        })(
                            <Input
                                className="nms-input-bottom"
                                placeholder="请输入字典描述"
                                allowClear
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="字典值" {...formItemLayout}>
                        {getFieldDecorator("configValue", {
                            initialValue: dictionaryTree,
                            rules: [
                                {
                                    required: true,
                                    message: "请添加字典值",
                                },
                            ],
                        })(<ConfigTree configKey={configKey} />)}
                    </Form.Item>
                </Form>
            </DragModal>
        );
    }
}


export default DictionaryModal;
