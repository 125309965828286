/*
 * @Author: zhangq
 * @Date: 2019-09-26 11:23:18
 * @Last Modified by: yek
 * @Last Modified time: 2020-01-02 11:18:27
 */
import React from 'react';
import noFoundPng from '@/static/images/404.gif';
import { Button } from 'antd';
import './notFound.less';

/**
 * @feature 404页面
 * @class NotFound
 * @extends {React.Component}
 */
class NotFound extends React.Component {
    toHome = () => {
        this.props.history.push('/client/home');
    };
    render() {
        return (
            <div className="not-found-wraper">
                <div className="not-found-img">
                    <img src={noFoundPng} alt="404.png" />
                </div>
                <div className="not-found-word">sorry,页面走丢了</div>
                <div className="not-found-btns">
                    <Button
                        size="large"
                        className="nms-button"
                        type="primary"
                        onClick={this.toHome}
                    >
                        返回首页
                    </Button>
                    <Button
                        style={{ marginLeft: 15 }}
                        className="nms-button"
                        size="large"
                        type="primary"
                        onClick={() => this.props.history.goBack()}
                    >
                        返回上级
                    </Button>
                </div>
            </div>
        );
    }
}

export default NotFound;
