const SELECTED_NODE = 'SELECTED_NODE';

// 初始状态
const initialState = {
    selectedNode: {},
};

const homeMaphighLight = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_NODE:
            return {
                ...state,
                selectedNode: action.selectedNode,
            };
        default:
            return { ...state };
    }
};

export const setNode = node => {
    // console.log("reduxAction:", viewFileName)
    return {
        type: SELECTED_NODE,
        selectedNode: node,
    };
};

export default homeMaphighLight