export default [
    {
        component: "UserManager",
        key: "/client/home"
    },
    // {
    //     icon: "fa-server",
    //     component: "CabinetDetail",
    //     key: "/client/center/CabinetDetail",
    //     title: '机房管理'
    // }
];