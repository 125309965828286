import React from 'react';
import Item from './Item';
import SubMenu from './SubMenu';
import './menu.less';

/**
 * @feature 菜单组件
 * @class Menu
 * @extends {React.Component}
 */
class Menu extends React.Component {
    static Item = Item;
    static SubMenu = SubMenu;
    timer = 0;
    onMouseEnter = e => {
        let { collapsed, setHoverUnfold } = this.props;
        if (collapsed) {
            this.timer = setTimeout(() => {
                setHoverUnfold(true);
            }, 300);
        }
    };
    onMouseLeave = e => {
        const { setHoverUnfold } = this.props;
        clearTimeout(this.timer);
        setHoverUnfold(false);
    };
    render() {
        return (
            <ul
                className={[
                    'nav-menu',
                    this.props.hoverUnfold ? 'nav-menu-unfold' : '',
                ].join(' ')}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {this.props.children}
            </ul>
        );
    }
}

export default Menu;
