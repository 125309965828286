import React from 'react';
import ReactDOM from 'react-dom';
import intl from 'react-intl-universal';
import zh from "@/i18n/zh-CN";
import en from "@/i18n/en-US";
import App from './App';
import * as serviceWorker from './serviceWorker';

// 默认是脱密环境
intl.init({
    currentLocale: 'zh',
    locales: {
        zh,
        en,
    },
});

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
