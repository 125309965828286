/*
 * @Author: zhangq 
 * @Date: 2019-09-30 13:08:10 
 * @Last Modified by: zhangq
 * @Last Modified time: 2019-10-08 16:27:39
 */
import React from 'react';

/**
 * @feature 菜单元素
 * @export Item
 * @class Item
 * @extends {React.Component}
 */
export default class Item extends React.Component {
    isActive() {
        const { name, pathname } = this.props;
        const reg = new RegExp(name, 'gi')
        return pathname.match(reg) ? 'active' : '';
    }

    render() {
        return (
            <li className={this.isActive()}>
                {this.props.children}
            </li>
        );
    }
}