import { createStore, combineReducers, applyMiddleware } from 'redux';
import reducer from '@/redux/index';
import thunk from 'redux-thunk';
import webSocket from '@/webSocket/webSocket';

// 仓库中间件
const middlewares = [thunk];

// reducer映射集合
const reducers = combineReducers(reducer);
console.log('reducer', reducer)

// 导出仓库
export default createStore(reducers, applyMiddleware(...middlewares));