import React from "react";
import errorCatch from "@/utils/errorCatch";
import InnerPage from "@/components/InnerPage/InnerPage";
import { Spin, Button } from "antd";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    const { user } = state.userManager.userInfo;
    return {
        user,
    };
};

@connect(mapStateToProps)
class IframeComp extends React.Component {
    state = {
        spinning: true,
    };
    componentDidMount() {
        window.addEventListener("message", this.handleMessage);
    }
    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessage);
    }

    componentWillReceiveProps(nextProps) {
        const { title } = this.props;
        if (nextProps.title !== title) {
            this.setState({
                spinning: true,
            });
        }
    }
    handleMessage = ({ data }) => {
        if (data?.code === "machineroom") {
            this.props.history.push({
                pathname: "/client/config/machineroom",
                state: {
                    cabinetId: 0,
                    roomId: data.roomId,
                },
            });
        }
    };

    iframeLoaded = (e) => {
        this.setState({
            spinning: false,
        });
    };

    goTopoManager = () => {
        this.props.history.push("/client/center/topomanager");
    };

    render() {
        const {
            location: { pathname },
            user,
        } = this.props;
        let iframeUrl = pathname.slice(pathname.lastIndexOf("http"));
        iframeUrl = decodeURIComponent(iframeUrl);
        // 阵地视图 单独处理
        if (iframeUrl && iframeUrl.indexOf("93.1.6.8:30080") > -1) {
            iframeUrl += "?userId=" + user.id;
        }
        return (
            <InnerPage
                {...this.props}
                buttonList={
                    <Button
                        className="nms-button"
                        onClick={() => {
                            this.goTopoManager();
                        }}
                    >
                        全景监视视图
                    </Button>
                }
            >
                <Spin spinning={this.state.spinning} onClick={this.iframeLoaded}>
                    <iframe
                        key={iframeUrl}
                        src={iframeUrl}
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        onLoad={this.iframeLoaded}
                    />
                </Spin>
            </InnerPage>
        );
    }
}

export default errorCatch(IframeComp);
