/*
 * @Author: yek
 * @Date: 2019-10-23 10:27:33
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-12 14:27:47
 */
import { post } from '@/requests';
import { message } from 'antd';
// action serverMonitor
// 更新监控统计图
const UPDATE_SERVER_MONITOR_CHART = 'UPDATE_SERVER_MONITOR_CHART';
// 初始状态
const initialState = {
    // 统计图状态管理
    chartConfig: [
        {
            url: 'monitor/software/statistic',
            query: {},
            type: 'bar',
            chartData: [],
            mainTitle: '服务监控统计',
        },
    ],
};

// 更新统计信息
export const updateMonitorChart = chartConfig => async dispatch => {
    let newChartConfig = [...chartConfig];

    if (chartConfig[0]) {
        let chartResData = await post(chartConfig[0].url, chartConfig[0].query);
        if (!chartResData.code) {
            message.error('查询统计数据失败');
            return;
        }
        newChartConfig[0].chartData = chartResData.data;
    }
    if (chartConfig[1]) {
        let chartResData2 = await post(
            chartConfig[1].url,
            chartConfig[1].query
        );
        if (!chartResData2.code) {
            message.error('查询统计数据失败');
            return;
        }
        newChartConfig[1].chartData = chartResData2.data;
    }

    dispatch({
        type: UPDATE_SERVER_MONITOR_CHART,
        chartConfig: newChartConfig,
    });
};

// 统计reducer
const serverMonitor = (state = initialState, action) => {
    if (!action) return state;
    switch (action.type) {
        case UPDATE_SERVER_MONITOR_CHART:
            return {
                ...state,
                chartConfig: action.chartConfig,
            };
        default:
            return { ...state };
    }
};
export default serverMonitor