import React from 'react';
export default function MainFloor({ onToggleCollapsed }) {
    return (
        <>
            <div style={{ zIndex: 20, height: '30px' }}>
                <p
                    style={{
                        width: '50%',
                        height: '100%',
                        lineHeight: '30px',
                        textAlign: 'center',
                        margin: 'auto',
                        size: 20,
                    }}
                >
                    © 后台管理系统
                    <a href="http://beian.miit.gov.cn" target="_blank">
                        备案号:{window.appConfig.beiAnHao}
                    </a>
                </p>
            </div>
        </>
    )
}
