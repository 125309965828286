/*
 * @Author: zhangq
 * @Date: 2020-01-07 14:07:55
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-27 17:33:28
 */
import React from "react";
import { Checkbox, Input, Button } from "antd";
import "./tableColumnsList.less";

// 格式化选中的数据
const formatCheckData = (data) => {
    let result = [];

    data &&
        data.map((ele) => {
            const { dataIndex, defaultShow } = ele;
            // 排除索引列和操作列
            if (!defaultShow) {
                result.push(dataIndex);
            }
            return ele;
        });
    return result;
};
/**
 * @feature 表格列设置
 * @export TableColumnsList
 * @class TableColumnsList
 * @extends {React.Component}
 * @props  {
 *    1、pageType:类型
 *    2、setTableColumns 设置行
 * }
 */
class TableColumnsList extends React.Component {
    state = {
        isExpandedColumnsList: false, // 展开收缩
        fieldList: [], // 格式化后的所有数据,显示在列表的数据
        checkedList: [], // 选择的数据
        searchText: "",
        indeterminate: false, //设置 indeterminate 状态，只负责样式控制
        checkAll: false,
    };
    // 主要用于筛选处，缓存数据
    beforeFieldList = [];
    // 主要用于重置时，缓存数据
    beforeCheckList = [];
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { allColumn, checkList } = nextProps;
        // 格式化列数据
        let fieldList = this.formatColumnData(allColumn);
        // 格式化选中的数据
        let checkedList = formatCheckData(checkList);
        // 将所有数据缓存起来，请于筛选
        this.beforeFieldList = fieldList;
        this.beforeCheckList = checkedList;
        let checkAll = checkedList.length === fieldList.length;
        this.setState({
            fieldList,
            checkedList,
            checkAll,
            indeterminate:
                !!checkedList.length && checkedList.length < fieldList.length,
        });
    }

    componentDidMount() {
        // 关闭筛选列表
        document.addEventListener("click", this.closeColumnsList);
    }

    componentWillUnmount() {
        // 关闭筛选列表
        document.removeEventListener("click", this.closeColumnsList);
    }
    // 格式化传过来的列数据
    formatColumnData = (data) => {
        let { searchText } = this.state;
        let result = [];

        data &&
            data.map((ele) => {
                const { title, dataIndex, defaultShow } = ele;
                // 排除索引列和操作列
                if (!defaultShow && title.indexOf(searchText) !== -1) {
                    result.push({
                        label: title,
                        value: dataIndex,
                    });
                }
                return ele;
            });
        return result;
    };
    // 展开筛选列列表
    expandedColumnsList = async (e) => {
        e.nativeEvent.stopImmediatePropagation();
        let { isExpandedColumnsList } = this.state;

        // 展开字段筛选框
        this.setState({
            isExpandedColumnsList: !isExpandedColumnsList,
        });
    };

    // 关闭筛选列列表
    closeColumnsList = (e) => {
        this.setState({
            isExpandedColumnsList: false,
        });
    };

    // 阻止筛选框收起
    stopCloseColumnsList = (e) => {
        e.nativeEvent.stopImmediatePropagation();
    };

    //搜索列配置内容
    searchSelectList = (val) => {
        let value = "";
        if (typeof val === "string") {
            value = val;
        } else {
            value = val.target.value;
        }
        let { beforeFieldList } = this;
        let columns = [];
        beforeFieldList.forEach((item) => {
            if (item.label.indexOf(value) !== -1) {
                columns.push(item);
            }
        });
        this.setState({ fieldList: columns, searchText: value });
    };

    // 复选框组选择
    fieldListChange = (checkedList) => {
        let { setTableColumns } = this.props;
        let { fieldList } = this.state;
        let checkAll = checkedList.length === fieldList.length;

        this.setState({
            checkedList,
            indeterminate:
                !!checkedList.length && checkedList.length < fieldList.length,
            checkAll: checkAll,
        });
        // 选择的回调
        setTableColumns && setTableColumns(checkedList);
    };

    // 保存列数据
    saveColumnsList = async () => {
        let { checkedList } = this.state;
        let { saveColumnsList } = this.props;

        // 保存列数据
        saveColumnsList && saveColumnsList(checkedList);
    };

    // 全选事件
    onCheckAllChange = (e) => {
        let { fieldList } = this.state;
        let allCheckedList = [];
        fieldList.forEach((item) => {
            allCheckedList.push(item.value);
        });

        let checkedList = e.target.checked ? allCheckedList : [];

        // 更新表格中的列
        this.fieldListChange(checkedList);

        this.setState({
            fieldList,
            checkedList,
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };

    // 重置表格列
    resetColumns = () => {
        let { fieldList } = this.state;
        // 上传保存的数据
        let { beforeCheckList } = this;
        let checkAll = beforeCheckList.length === fieldList.length;

        // 更新表格中的列
        this.fieldListChange(beforeCheckList);

        this.setState({
            checkedList: beforeCheckList,
            indeterminate:
                !!beforeCheckList.length && beforeCheckList.length < fieldList.length,
            checkAll,
        });
    };

    render() {
        let {
            isExpandedColumnsList,
            fieldList,
            checkedList,
            checkAll,
            indeterminate,
            searchText,
        } = this.state;
        let active = isExpandedColumnsList ? "active" : "";

        return (
            <div className="table-columns">
                <i
                    className="table-column-switch fa kdfont fa-ellipsis-v"
                    onClick={this.expandedColumnsList}
                />

                <div
                    className={"table-column-list " + active}
                    onClick={this.stopCloseColumnsList}
                >
                    <Input.Search
                        size="small"
                        className="nms-input-bottom"
                        onChange={this.searchSelectList}
                        onSearch={this.searchSelectList}
                        allowClear={true}
                        value={searchText}
                        placeholder="输入搜索"
                    />

                    <div className="selecte-box">
                        {fieldList.length ? (
                            <Checkbox.Group
                                onChange={this.fieldListChange}
                                value={checkedList}
                                options={fieldList}
                            />
                        ) : (
                            <div className="empty-wraper ">没有动态配置项</div>
                        )}
                    </div>

                    <div className="checkBtns">
                        <Checkbox
                            indeterminate={indeterminate}
                            onChange={this.onCheckAllChange}
                            checked={checkAll}
                            disabled={!fieldList.length}
                        >
                            全选
                        </Checkbox>
                        <Button
                            size="small"
                            className="nms-button"
                            style={{ margin: "0px 10px" }}
                            onClick={this.resetColumns}
                            disabled={!fieldList.length}
                        >
                            重置
                        </Button>
                        <Button
                            size="small"
                            className="nms-button"
                            onClick={this.saveColumnsList}
                            disabled={!fieldList.length}
                        >
                            保存
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableColumnsList;
