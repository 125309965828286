import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Table } from 'antd'
import { useTableFetch } from './useTableFetch'

// 分页高度
const PAGINATION_HEIGHT = 51

// 表头高度
const TABLE_HEIGHT = 40

export default function BaseTable(props) {
    const {
        tableRef,
        className,
        columns,
        rowKey,
        onRow,
        selectRowByClick = true,
        hasPage = true,
        axiosType = 'post',
        axiosUrl,
        query,
        highLightRowKey,
        rowClassName,
        callback,
        height,
        formatterData,
        hideOnSinglePage,
        defaultPageSize = 20,
        finishCallback,
        selectedId,
    } = props
    const tableBoxRef = useRef()
    const [tableBoxHeight, setTableBoxHeight] = useState(0)
    const [currentRowId, setCurrentRowId] = useState('')
    const [reactiveColumns, setReactiveColumns] = useState([])

    useEffect(() => {
        if (selectedId) {
            setCurrentRowId(selectedId)
        }
    }, [selectedId])

    const {
        page,
        total,
        tableData,
        loading,
        getTableData,
        updateCurrentPage,
        onPageChange,
        onPageSizeChange,
    } = useTableFetch({
        axiosType,
        axiosUrl,
        query,
        callback,
        formatterData,
        defaultPageSize,
        finishCallback,
    })
    useImperativeHandle(
        tableRef,
        () => ({
            tableData,
            updateCurrentPage, // 这个方法给删除/编辑用
            getTableData, // 这个方法给新增用
            setCurrentRowId, // 选中行、清除选中行 使用
        }),
        [tableData, updateCurrentPage, getTableData],
    )

    const handleResize =
        index =>
        (e, { size }) => {
            const nextColumns = [...reactiveColumns]
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            }
            setReactiveColumns(nextColumns)
        }

    // 行样式
    const rowStyle = (record, index) => {
        if (rowClassName) return rowClassName(record, index)
        const rowIds = (rowKey && rowKey(record)) ?? record.id
        let hasHighLightRowKey = 'highLightRowKey' in props
        if (hasHighLightRowKey ? rowIds === highLightRowKey : rowIds === currentRowId) {
            return 'row-selected'
        } else {
            return index % 2 === 0 ? 'row-even' : 'row-odd'
        }
    }

    // 获取横向滚动宽度
    const getScrollXWidth = () => {
        // 获取父盒子的宽
        const tableBox = tableBoxRef.current

        if (!tableBox) return {}

        // 获取表格计算后的宽度并转为数字
        const { width } = getComputedStyle(tableBox)
        const tableWidth = parseFloat(width)

        // 获取所有列宽度和
        const allColunmsWidth = getAllColumnsWidth(tableWidth)

        if (parseInt(tableWidth) < parseInt(allColunmsWidth)) {
            return { x: allColunmsWidth }
        }
        return {}
    }

    // 获取所有列宽度和
    const getAllColumnsWidth = tableWidth => {
        // 所有列宽进行累加
        const totalWidth = columns.reduce((total, item) => {
            const { width } = item
            let itemWidth
            if (!width) {
                itemWidth = 100
            }
            if (typeof width === 'string') {
                if (width.endsWith('%')) {
                    itemWidth = (tableWidth * parseFloat(width)) / 100
                } else {
                    itemWidth = parseFloat(width)
                }
            }
            if (typeof width === 'number') {
                itemWidth = width
            }
            return total + itemWidth
        }, 0)
        return totalWidth
    }

    // 表格行事件
    const onRowEvent = record => {
        // 组件传过来的事件
        const rowId = (rowKey && rowKey(record)) ?? record.id

        if (!onRow && selectRowByClick) {
            return {
                onClick: () => {
                    setCurrentRowId(rowId)
                },
            }
        }

        // props本身传过来的onRow
        const propsOnRow = onRow(record)
        const { onClick } = propsOnRow

        // 将组件传进来的行方法合并
        const newRowEvent = Object.assign({}, propsOnRow, {
            onClick: (...e) => {
                onClick && onClick(...e)
                selectRowByClick && setCurrentRowId(rowId)
            },
        })
        return newRowEvent
    }

    // 分页
    const pagination = hasPage => {
        if (hasPage) {
            return {
                className: 'nms-pagination',
                defaultPageSize,
                pageSizeOptions: ['10', '20', '100', '200', '1000'],
                showSizeChanger: true,
                total,
                hideOnSinglePage: !!hideOnSinglePage,
                showTotal: total => `总共： ${total} 条`,
                current: page,
                onChange: onPageChange,
                onShowSizeChange: onPageSizeChange,
            }
        }
        return false
    }
    useEffect(() => {
        const computedSize = () => {
            const tableBox = tableBoxRef.current
            if (!tableBox) return
            const tableBoxHeight = tableBox.offsetHeight
            const antTable = tableBox.querySelector('.ant-table')
            const paginationHeight = axiosUrl ? PAGINATION_HEIGHT : 0
            setTableBoxHeight(tableBoxHeight - paginationHeight - TABLE_HEIGHT)
        }
        computedSize()
        window.addEventListener('divresize', computedSize)
    }, [axiosUrl])

    // table基础参数
    const baseProps = {
        ...props,

        rowClassName: rowStyle,
        scroll: { ...getScrollXWidth(), y: tableBoxHeight },
        onRow: onRowEvent,
    }

    // table请求参数
    const fetchProps = {
        dataSource: props.dataSource ?? tableData,
        loading,
        pagination: pagination(hasPage),
    }

    const tableProps = axiosUrl
        ? { ...baseProps, ...fetchProps }
        : { ...baseProps, pagination: false }

    useEffect(() => {
        // 混合列数据
        columns.forEach(item => {
            const { title } = item
            const newItem = reactiveColumns.find(i => i.title === title)
            if (newItem) item.width = newItem.width
            if (!item.render) item.render = val => val || '--'
            item.ellipsis = true
        })
        setReactiveColumns(columns)
    }, [columns])
    const renderColumns = reactiveColumns?.map((col, index) => ({
        ...col,
        onHeaderCell: column => {
            let { width } = column
            if (width && Object.prototype.toString.call(width) === '[object String]') {
                if (width.endsWith('px')) {
                    width = parseFloat(width)
                } else if (width.endsWith('%')) {
                    const tableBox = tableBoxRef.current
                    // 获取表格计算后的宽度并转为数字
                    const { width: tableWidthStr } = getComputedStyle(tableBox)
                    const tableWidthNum = parseFloat(tableWidthStr)
                    width = (tableWidthNum * parseFloat(width)) / 100
                }
            }
            return {
                width,
                onResize: handleResize(index),
            }
        },
    }))
    return (
        <div
            ref={ref => (tableBoxRef.current = ref)}
            style={{ height: height ? height : '100%', position: 'relative' }}
        >
            <Table
                rowKey={(record, index) => {
                    return record.id || index
                }}
                {...tableProps}
                columns={renderColumns}
                className={`${className} ${hasPage ? '' : 'ant-table-nopagition'} nms-table`}
            />
        </div>
    )
}
