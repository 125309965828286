// action类型
const SAVE_USER_INFO = 'SAVE_USER_INFO';

// 初始状态
const initialState = {
    userInfo: {}
};

// 保存用户信息 action creator
export const saveUserInfo = userInfo => dispatch => {

    dispatch({
        type: SAVE_USER_INFO,
        userInfo
    });
};

// 用户管理reducer
export const userManager = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case SAVE_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            };
        default:
            return { ...state };
    }
};