/*
 * @Author: yek
 * @Date: 2019-10-30 14:59:41
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-10-22 10:32:14
 */
// 主要用来验证表单的正则

// IP
export const IpReg =
    '^(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5]|())$';

// port
export const portReg =
    '^([1-9]|[1-9]\\d{1,3}|[1-5]\\d{4}|6[0-4]\\d{4}|65[0-4]\\d{2}|655[0-2]\\d|6553[0-5]|())|()$';

// 电话号码
export const telReg = '^0?1[3|4|5|8][0-9]\\d{8}$';

// 特殊字符()_-小括号，下划线、中划线除外
export const specialCharacters = /^[^`!@#%&*"';.: <>=·，,；：“”‘’￥……~——！、。？（）【】《》\?\[\]\/\\\|\+\$\^\{\}]+$/;

// 字母开始 。包换数字。下划线
export const keyValidata = /^[a-zA-Z][a-zA-Z0-9_]*$/;

// 仅包含字母和数字
export const letterAndNum = /^[a-zA-Z0-9]+$/;

//  零或者非零开头的整数
export const integer = /^(0|[1-9][0-9]*)$/;

//  以1开头的整数
export const integer2 = /^([1-9][0-9]*)$/;


// 正整数
export const OnlyNum = /^[0-9]*[1-9][0-9]*$/

// 方位角 Az
export const Az = /^(([0-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9])(\.[0-9]+)?|360)$/

// 俯仰角度 El
export const El = /^(([0-9]|[1-9][0-9]|1[0-7][0-9])(\.[0-9]+)?|180)$/
