/*
 * @Author: yek
 * @Date: 2019-12-18 10:45:39
 * @Last Modified by: yek
 * @Last Modified time: 2020-04-28 14:35:39
 */
import React from 'react';
import InnerPage from '@/components/InnerPage/InnerPage';
import DictionarySearchForm from './DictionarySearchForm';
import DictionaryModal from './DictionaryModal';
import BaseTable from '@/components/BaseTable/BaseTable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDictionaryTree } from '@/redux/dictionaryManager/dictionaryManager';
import { Button, message, Popconfirm } from 'antd';
import { post } from '@/requests';
import { formatterDate } from '@/utils';
import './dictionaryManager.less';

// 映射组件dispatch
const mapDispatchToProps = (dispatch) => ({
    // 获取告警趋势数据
    setDictionaryTree: bindActionCreators(setDictionaryTree, dispatch),
});
/**
 * @feature 字典管理模块 全局字典
 * @export DictionaryManager
 * @class DictionaryManager
 * @extends {React.Component}
 */
@connect(null, mapDispatchToProps)
class DictionaryManager extends React.Component {
    state = {
        addDictionaryVisible: false,
        dictionaryInfo: {}, //添加 编辑字典modal数据,
        query: {},
    };
    // 表格列字典
    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            width: '5%',
            render: (value, record, index) => `${index + 1}`,
        },
        {
            title: '字典名称',
            width: '12.5%',
            dataIndex: 'configName',
        },

        {
            title: '字典KEY',
            width: '12.5%',
            dataIndex: 'configKey',
        },
        {
            title: '字典值',
            width: '20%',
            dataIndex: 'configValue',
            render(value, record) {
                let configValue;
                try {
                    configValue = JSON.parse(value);
                } catch (error) {
                    return '--';
                }
                return configValue[0].title;
            },
        },
        {
            title: '字典分组',
            width: '10%',
            dataIndex: 'status',
            render: (value, record) => {
                return value === 1 ? '系统字典' : '自定义字典';
            },
        },
        {
            title: '字典描述',
            width: '20%',
            dataIndex: 'configDesc',
        },
        {
            title: '创建时间',
            width: '10%',
            dataIndex: 'createTime',
            render: formatterDate,
        },
        {
            title: '操作',
            width: '10%',
            dataIndex: 'actions',
            render: (value, record, index) => {
                //  是否是系统属性
                let isDel = record.status;
                return (
                    <div className="actions-buttons">
                        <i
                            className="fa kdfont fa-edit icon-btn"
                            title="编辑"
                            onClick={this.handleEdit.bind(this, record)}
                        />
                        {isDel ? (
                            ''
                        ) : (
                                <Popconfirm
                                    title="确认删除该条字典"
                                    onConfirm={() => this.handleDelete(record.id)}
                                >
                                    <i className="fa kdfont fa-trash-alt icon-btn" />
                                </Popconfirm>
                            )}
                    </div>
                );
            },
        },
    ];

    getButtonList = () => {
        return (
            <div>
                <Button className="nms-button" onClick={this.handleAdd}>
                    添加字典
                </Button>
            </div>
        );
    };

    //添加字典
    handleAdd = () => {
        let { setDictionaryTree } = this.props;
        this.setState({
            addDictionaryVisible: true,
            dictionaryInfo: {},
        });
        setDictionaryTree && setDictionaryTree([]);
    };
    //编辑字典
    handleEdit = record => {
        let { setDictionaryTree } = this.props;
        let newRecord = { ...record };
        let configValue = '';
        // 树状数据
        try {
            configValue = JSON.parse(newRecord.configValue);
            console.log('configValue',configValue)
        } catch (error) {
            message.error('解析JSON数据出错');
            configValue = [];
        }

        this.setState({
            addDictionaryVisible: true,
            dictionaryInfo: newRecord,
        });
        setDictionaryTree && setDictionaryTree(configValue);
        console.log('setDictionaryTree',setDictionaryTree)
    };
    //删除字典
    handleDelete = async id => {
        let res = await post(`config/common/remove/${id}`);
        if (!res.code) {
            message.error(res.data || '删除失败');
            return;
        }

        message.success('删除字典成功');
        // 刷新表格
        this.baseTable && this.baseTable.getTableData();
    };
    // 获取表格数据
    getTableData = params => {
        // 获取设备数据
        this.baseTable && this.baseTable.getTableData(params);
    };
    // 查询按钮事件
    getSearchData = async params => {
        // 分割检索字段
        await this.setState({
            query: params,
        });
        // 刷新表格
        this.getTableData({ page: 1 });
    };

    // 关闭弹窗
    dictionaryModalCancel = () => {
        this.setState({
            addDictionaryVisible: false,
        });
    };
    // 格式化数据的key
    formaterKey = (configValue, configKey) => {
        configValue.forEach(e => {
            if (!e.key.startsWith(configKey)) {
                e.key = configKey + "$" + e.key;
            }
            if (e.children && e.children.length) {
                this.formaterKey(e.children, configKey);
            } else {
                delete e.children;
            }
        });
    };
    // 确定关闭弹窗
    dictionaryModalOk = async formValues => {
        let { configName, configKey, configValue, configDesc } = formValues;
        let { dictionaryInfo } = this.state;
        // 格式化数据
        this.formaterKey(configValue, configKey);
        let params = {
            configName,
            configKey,
            configDesc,
            configValue: JSON.stringify(configValue),
        };
        let { id } = dictionaryInfo;
        let url = 'config/common/save';
        if (id) {
            //编辑字典
            params.id = dictionaryInfo.id;
            url = 'config/common/update';
        }
        let resData = await post(url, params);
        if (!resData.code) {
            message.error(resData.data || '操作失败');
            return;
        }
        // 编辑成功提示
        message.success('操作成功');
        // 刷新表格
        this.getTableData();
        //关闭弹窗
        this.setState({
            addDictionaryVisible: false,
        });
    };
    render() {
        let { addDictionaryVisible, dictionaryInfo, query } = this.state;
        const { icon, title } = this.props;

        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="knowledge-search-form">
                    <DictionarySearchForm getTableList={this.getSearchData} />
                </div>
                <BaseTable
                    onRef={ref => (this.baseTable = ref)}
                    url="config/common/page"
                    axiosType="get"
                    query={query}
                    height="calc(100% - 58px)"
                    allColumn={this.columns}
                />
                <DictionaryModal
                    addDictionaryVisible={addDictionaryVisible}
                    onOk={this.dictionaryModalOk}
                    closeModal={this.dictionaryModalCancel}
                    dictionaryInfo={dictionaryInfo}
                />
            </InnerPage>
        );
    }
}

export default DictionaryManager;
