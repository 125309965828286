/*
 * @Author: zhangq
 * @Date: 2019-09-29 13:39:16
 * @Last Modified by: zhangq
 * @Last Modified time: 2019-10-25 14:05:16
 */
import React from "react";
import { Popover } from "antd";
import styled from "styled-components";
import HelpIcon from "./HelpIcon";
import "./innerPage.less";

const HelpContent = styled.div`
    color: #fff;
    font-size: 14px;
    word-break: break-all;
    white-space: pre-line;
`;

/**
 * @feature 内页通用样式
 * @export InnerPage
 * @params1 {icon} 'fa-xxx'
 * @params2 {title} String || React.dom
 * @class InnerPage
 * @extends {React.Component}
 */
export default class InnerPage extends React.Component {
    state = {
        visible: false,
    };

    render() {
        let {
            icon,
            title,
            buttonList,
            children,
            height = "calc(100% - 50px)",
            helpText,
            topButton,
        } = this.props;
        // 没有title时 隐藏innerPage头部
        let isHidden = topButton || !(title || buttonList);
        // let isHidden = !title;
        return (
            <div className={"inner-page " + (this.props.className ?? "")}>
                <div className="inner-header" style={{ height: isHidden ? 0 : 50 }}>
                    <i className={"fa kdfont " + icon} />
                    <span style={{ position: "relative", paddingRight: "20px" }}>
                        {title}

                        <HelpIcon helpText={helpText} />
                        {/* 页面帮助 */}
                        {/* {
                            helpText && <Popover
                                content={<HelpContent>{helpText}</HelpContent>}
                                trigger="click"
                                placement="bottomLeft"
                                visible={this.state.visible}
                                onVisibleChange={this.handleVisibleChange}
                            >
                                <span className="" style={{ paddingLeft: '8px' }}>
                                    <i className="fa kdfont fa-question-circle"></i>
                                </span>
                            </Popover>
                        } */}
                    </span>
                    {buttonList ? (
                        <div className={`button-list ${topButton ? "top50" : ""}`}>
                            {buttonList}
                        </div>
                    ) : null}
                </div>
                <div
                    id="innerContent"
                    className="inner-content"
                    style={{ height: isHidden ? "100%" : height }}
                >
                    {children}
                </div>
            </div>
        );
    }
}
