/*
 * @Author: yek
 * @Date: 2019-09-11 15:56:05
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-10-22 20:35:34
 * 路由和组件的对应关系
 */

export default [{
    key: '/client/home',
    component: 'Home'
},

// 本地运维
{
    key: '/client/center/assetmanager',
    component: 'AssetManagerMain'
},
{
    key: '/client/center/hardwaremonitor',
    component: 'HardwareMonitor'
}, // 硬件监控
{
    key: '/client/center/equipmentMonitor',
    component: 'EquipmentMonitor'
}, // 设备监控（含硬件监控、服务监控）
{
    key: '/client/center/alarmMonitor',
    component: 'AlarmMonitor'
}, // 告警监控（含告警监视、告警策略）
{
    key: '/client/center/monitorroom',
    component: 'MonitorRoom'
}, //
{
    key: '/client/center/servermonitor',
    component: 'ServerMonitor'
}, // 服务监控
// 参数模板
{
    key: '/client/config/paramTemplate',
    component: 'ParamTemplate'
},
{
    key: '/client/center/logmonitor',
    component: 'LogMonitor'
},
{
    key: '/client/center/systemMonitor',
    component: 'SystemMonitor'
}, // 系统自监控
{
    key: '/client/center/alarmconfig',
    component: 'AlarmManager'
},
{
    key: '/client/center/alarmRulesConfig',
    component: 'AlarmRelusConfig'
},
{
    key: '/client/center/workOrder',
    component: 'WorkOrder'
}, // 工单绩效
{
    key: '/client/center/partsmanagement',
    component: 'PartsManagement'
}, // 配置管理
{
    key: '/client/center/linkmanager',
    component: 'LinkManager'
},
{
    key: '/client/center/linkCanvasMonitor',
    component: 'LinkCanvasMonitor'
},


// 全景监视
{
    key: '/client/center/topomanager',
    component: 'TopoManager'
},
//健康管理
{
    key: '/client/center/health',
    component: 'HealthManager'
},
// 
{
    key: '/client/center/systemmanaget',
    component: 'SystemPage'
},
// 全景运维
{
    key: '/client/node/assetcasemanage',
    component: 'AssetCaseManage'
},
{
    key: '/client/node/taskstatusmonitor',
    component: 'TaskStatusMonitor'
},
{
    key: '/client/node/usermonitor',
    component: 'UserMonitor'
},
{
    key: '/client/node/warningmonitor',
    component: 'WarningMonitor'
}, // 告警监视
{
    key: '/client/node/roommonitor',
    component: 'ComputerRoomMonitor'
},
{
    key: '/client/node/linkMonitor',
    component: 'LinkMonitor'
},
{
    key: '/client/node/linkMonitor2',
    component: 'LinkMonitor2'
},
{
    key: '/client/node/taskmonitor',
    component: 'TaskMonitor'
}, // 组网任务监视
{
    key: '/client/node/watchFar',
    component: 'WatchFar'
}, // 远程监控
{
    key: '/client/node/zdTask',
    component: 'ZdTask'
}, // 远程监控
{
    key: '/client/node/datamonitor',
    component: 'DataMonitor'
}, // 数据监视

// 日常巡检
{
    key: '/client/inspection/report',
    component: 'InspectionReport'
},
{
    key: '/client/inspection/plan',
    component: 'InspectionPlan'
},
{
    key: '/client/inspection/rwInspection',
    component: 'RwInspection'
},
// 频谱巡检
{
    key: '/client/inspection/signal/plan',
    component: 'SignalPlan'
},

// 统计报表
{
    key: '/client/statisticalreport/list',
    component: 'ReportList'
},
{
    key: '/client/statisticalreport/config',
    component: 'ReportConfig'
},
//用户绩效视图
{
    key: '/client/posture/work',
    component: 'WorkReport'
},
//rw绩效视图
{
    key: '/client/posture/rw',
    component: 'RwReport'
},

// 指示演训
{
    key: '/client/knowledge/base',
    component: 'Knowledge'
}, //知识演训
{
    key: '/client/knowledge/player',
    component: 'KnowledgePlayer'
},
{
    key: '/client/knowledge/document/management',
    component: 'DocumentManagement',
},

// 查询检索
{
    key: '/client/query/alarm',
    component: 'QueryAlarm'
},
{
    key: '/client/query/operationlog',
    component: 'OperationLog'
},
{
    key: '/client/query/queryLife',
    component: 'QueryLife'
},


// 用户权限
{
    key: '/client/systemManager/menuManager',
    component: 'MenuManager'
},
{
    key: '/client/systemManager/userManager',
    component: 'UserManager'
},
{
    key: '/client/systemManager/roleManager',
    component: 'RoleManager'
},
{
    key: '/client/systemManager/deptManager',
    component: 'DeptManager'
},

// 系统管理
{
    key: '/client/config/assettemplateconfig',
    component: 'AssetTemplateManager',
}, // 配置管理
{
    key: '/client/config/partsmanagement',
    component: 'PartsManagement',
}, // 配置管理
{
    key: '/client/config/nodeConfig',
    component: 'MapNodeConfig'
},
{
    key: '/client/config/versionConfig',
    component: 'VersionConfig'
},
{
    key: '/client/config/dictionaryManager',
    component: 'DictionaryManager'
},
{
    key: '/client/config/machineroom',
    component: 'CenterMachineroom'
},
{
    key: '/client/config/faulttree',
    component: 'FaultTree'
},
{
    key: '/client/config/remoteManager',
    component: 'RemoteManager',
}, //设备远控操作配置
{
    key: '/client/config/announcementManager',
    component: 'AnnouncementManager',
}, //公告管理
{
    key: '/client/config/commandManager',
    component: 'CommandManager',
}, //命令配置
{
    key: '/client/config/serialMachine',
    component: 'SerialMachine',
}, //串口设备配置管理
{
    key: '/client/posture/businessprocess',
    component: 'BusinessProcess'
},
{
    key: '/client/config/faultAllot',
    component: 'FaultAllot'
}, // 故障级别
{
    key: '/client/config/database',
    component: 'Database'
}, // 数据备份
{
    key: '/client/work/approvalstatistic',
    component: 'ApplyStatistic'
}, // 审批绩效
{
    key: '/client/work/approval',
    component: 'ApplyCenter'
}, // 审批中心
{
    key: '/client/work/remote',
    component: 'WorkRemote'
}, // 远控工单
{
    key: '/client/work/whiltelist',
    component: 'WhilteList'
}, // 远控白名单
{
    key: '/client/center/statellite',
    component: 'Statellite'
}, // 卫星管理
{
    key: '/client/config/maintenance',
    component: 'Maintenance'
}, // 保养管理
{
    key: '/client/center/config/prognostics',
    component: 'Prognostics'
}, // 趋势预测
{
    key: '/client/center/frequency',
    component: 'Frequency'
}, // 趋势预测
{
    key: '/client/center/network',
    component: 'Network'
}, // 网络监视
{
    key: '/client/center/linkmonitor',
    component: 'ZbMonitor'
}, // 载波监视
{
    key: '/client/config/zbmonitor',
    component: 'ZbMonitorManager'
}, // 载波监视管理
{
    key: '/client/config/docker',
    // component: 'Docker'
    component: 'HealthControl'
}, // 服务容器管理
{
    key: '/client/iframe/',
    component: 'IframeComp'
},

// 暂时未使用
{
    key: '/client/alarm/alarmWhiteList',
    component: 'AlarmWhiteList'
}, // 告警白名单
// 动态监视

{
    key: '/client/posture/system',
    component: 'SystemProcess'
},// 系统流程
{
    key: '/client/center/device/center',
    component: 'DeviceManager'
},// 日常维护 设备借出 归还 维修
{
    key: '/client/config/taskManager',
    component: 'TaskManager'
},// RW管理
{
    key: '/client/processManager',
    component: 'ProcessManager'
},// 流程管理

//新增页面
{
    key: '/client/business/yzm',
    component: 'Yzm'
},// 验证码管理
{
    key: '/client/business/banner',
    component: 'BannerManager'
},// 轮播图管理
{
    key: '/client/business/shop',
    component: 'ShopManager'
},// 商家管理
{
    key: '/client/business/category',
    component: 'CategoryManager'
},// 分类管理
{
    key: '/client/business/goods',
    component: 'GoodsManager'
},// 商品管理
{
    key: '/client/business/customer',
    component: 'CustomerManager'
},// 顾客管理
{
    key: '/client/business/order',
    component: 'OrderManager'
},// 订单管理
];