/**
 * Created by chenll
 * DATE: 2020/05/13
 * Description: React错误边界组件
 */

import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center', color: '#b40b0b', height: '32px' }}>
                        <CloseCircleOutlined /><span style={{paddingLeft: '5px'}}>组件内部错误，请联系管理员...</span>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;