import React from "react";
import store from "./store";
import Router from "@/routers/Router";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import "antd/dist/antd.css";
import "reset-css";
import "@/static/css/kdfont.css";
import "@/static/css/animate.css";
import "@ant-design/compatible/assets/index.css";
import "./app.less";
import "moment/locale/zh-cn";
import "intro.js/introjs.css"; //全局引入新手指引intro样式

// 全局设置div响应事件
window.divResize = new Event("divresize");

// window resize 时触发div resize 事件
window.addEventListener("resize", e => {
    setTimeout(() => {
        window.dispatchEvent(window.divResize);
    }, 0);
});

/**
 * @feature 全局页面入口
 * @export App
 * @class App
 * @extends {React.Component}
 */

class App extends React.Component {
    render() {
        return (
            <ConfigProvider locale={zh_CN}>
                <Provider store={store}>
                    <Router />
                </Provider>
            </ConfigProvider>
        );
    }
}

export default App;
