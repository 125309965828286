import React, { useState } from "react";
import {
    Form,
    Input,
    Select,
    message,
    Upload,
    Button,
    TreeSelect,
    DatePicker,
} from "antd";
import { get, post } from "@/requests";
import { transTreeData } from "@/utils";
import _ from "lodash";

const { TextArea } = Input;
// interface CusFormItem {
//     value ?: form.value;
//     onChange ?: (value)=>void;
// }
function getFileName(url) {
    if (url) {
        let m = url.match(/^.*\/(.+\.\w+)/i);
        if (m && m.length > 1) {
            return m[1];
        }
    }

    return "";
}

export class CustomUpload extends React.PureComponent {
    state = {
        fileList: [],
        showResult: false, // 是否显示结果
        result: "", // 上传结果成功或者失败
        errorList: [], // 失败列表,
        url: "",
    };

    static getDerivedStateFromProps(props) {
        if (props.value) {
            const { value: url } = props;
            let urls = url.split(";");
            console.log(url, getFileName(url));

            let fileList = urls.map((i) => {
                return {
                    uid: "i",
                    name: getFileName(i),
                    status: "done",
                    response: {
                        code: 1,
                        data: {
                            url: i,
                        },
                    },
                    url: i,
                };
            });
            return {
                fileList,
            };
        }
        return { url: props.value };
    }
    componentDidUpdate(preProps) {
        if (preProps.value && this.props.value === "") {
            this.clear();
        }
    }

    clear = () => {
        this.setState({
            fileList: [],
            showResult: false,
            result: "",
            errorList: [],
        });
    };

    //导入
    uploadOnChange = (info) => {
        this.setState(
            {
                fileList: info.fileList,
                errorList: [],
            },
            () => {
                const { onChange = () => {} } = this.props;
                const { fileList } = this.state;
                let url = "";

                if (fileList.length > 0) {
                    url = fileList
                        .filter((i) => i?.response?.data?.fileLocal)
                        .map((i) => i.response.data.fileLocal)
                        .join(";");
                }
                onChange(url);
            }
        );
        if (info.file.status !== "uploading") {
            // console.log(info.file, info.fileList);
        }
        //上传完成
        if (info.file.status === "done") {
            this.setState({
                showResult: true,
            });
            if (info.file.response.code === 0) {
                //上传失败
                this.setState({
                    result: "error",
                    errorList: info.file.response.data,
                });
                message.error(info.file.response.data);
                this.clear();
            } else {
                //上传成功
                this.setState({
                    result: "success",
                });

                //刷新表格
                // updateTable && updateTable();
            }
        } else if (info.file.status === "error") {
            message.error(`上传失败`);
        }
    };

    // 删除文件的回调
    onRemove = () => {
        // 初始状态
        this.setState({
            showResult: false, // 是否显示结果
            result: "", // 上传结果成功或者失败
            errorList: [], // 失败列表
        });
        return true;
    };
    render() {
        const { fileList } = this.state;
        return (
            <Upload
                className="asset-manager-upload"
                action="/api/common/file/save"
                onChange={(info) => {
                    this.uploadOnChange(info);
                }}
                fileList={fileList}
                multiple={false}
                onRemove={this.onRemove}
            >
                <Button className="nms-button" disabled={fileList.length > 0}>
                    <i className="fa kdfont fa-upload" type="upload" />
                    上传
                </Button>
            </Upload>
        );
    }
}

// interface CustomSelect {
//     value ?: form.value;
//     onChange ?: (value)=>void;
//     aboverValue ?: any // 级联上一级值
//     url?:'',
//     options?:[],
//     hasAbover:boolean
// }

export class CustomSelect extends React.PureComponent {
    state = {
        options: [],
    };
    componentDidMount() {
        if (this.props.url) {
            this.getOptions(this.props.url);
        }
        if (this.props.options) {
            this.setState({
                options: this.props.options,
            });
        }

        if (this.props.defaultValue) {
            this.props.onChange(this.props.defaultValue);
        }
    }
    componentDidUpdate(preProps) {
        if (preProps.params !== this.props.params) {
            this.getOptions(this.props.url);
        }
    }

    getOptions = async () => {
        //extraOptions 格外强插选项
        const {
            url,
            onChange,
            hasAbover,
            keys = {},
            extraOptions,
            params = {},
        } = this.props;
        const {
            method = "get",
            label = "label",
            value = "value",
            listKeys = [],
        } = keys;
        let request = method === "get" ? get : post;
        if (url) {
            const data = await request(url, { ...params });
            if (data.data) {
                let _list = data.data || [];
                if (listKeys.length > 0) {
                    listKeys.forEach((key) => {
                        _list = _list[key];
                    });
                }
                if (extraOptions) {
                    let options = _list.map((i) => ({
                        ...i,
                        label: _.get(i, label),
                        value: _.get(i, value),
                    }));
                    options = [...extraOptions, ...options];
                    this.setState({
                        options,
                    });
                } else {
                    this.setState({
                        options: _list.map((i) => ({
                            ...i,
                            label: _.get(i, label),
                            value: _.get(i, value),
                        })),
                    });
                }
            } else {
                this.setState({
                    options: [],
                });
            }
        } else {
            this.setState(
                {
                    options: [],
                },
                () => {
                    onChange("");
                }
            );
        }
    };
    render() {
        const {
            value,
            onChange,
            disabled,
            showSearch,
            placeholder = "请选择",
        } = this.props;
        const { options } = this.state;
        if (showSearch) {
            return (
                <Select
                    {...this.props}
                    value={value}
                    onChange={onChange}
                    className="nms-select-bottom"
                    placeholder={placeholder}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {options.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            );
        }
        return (
            <Select
                {...this.props}
                value={value}
                onChange={onChange}
                className="nms-select-bottom"
                placeholder={placeholder}
            >
                {options.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}

export class CusTreeSelect extends React.PureComponent {
    state = {
        options: [],
    };
    componentDidMount() {
        // 设备类型
        if (this.props.filedName === "devTypeId") {
            return this.getDevTypeId();
        }
        if (this.props.filedName === "templateNo") {
            return this.getTypeTemplate();
        }
        if (this.props.url) {
            this.getOptions(this.props.url);
        }
        if (this.props.options) {
            this.setState({
                options: this.props.options,
            });
        }
    }

    // 格式化树形菜单
    formatterData = (data) => {
        if (!data) return [];
        data.forEach((ele) => {
            ele.value = ele.templateNo;
            ele.title = ele.templateName;
            ele.pId = ele.parentTemplateNo;
            return ele;
        });
        // 树形化数据
        return transTreeData(data, 0);
    };
    getDevTypeId = async (params = {}) => {
        const { data } = await post("resource/typeTemplate/list", params);
        // 获取硬件模板
        const hardwareTemplate = data.filter((item) => item.typeValue === 1);
        // 服务模板
        //  const softwareTemplate = data.filter(item => item.typeValue === 2);
        this.setState({ options: this.formatterData(hardwareTemplate) });
    };
    getTypeTemplate = async (params = {}) => {
        const { data } = await post("resource/typeTemplate/list", params);
        // 获取硬件模板
        // const hardwareTemplate = data.filter(item => item.typeValue === 1);
        // 服务模板
        //  const softwareTemplate = data.filter(item => item.typeValue === 2);
        this.setState({ options: this.formatterData(data) });
    };
    getOptions = async (url, params = {}) => {
        const { keys = {} } = this.props;
        const { method = "get", label = "label", value = "value" } = keys;
        let request = method === "get" ? get : post;
        let res = await request(url, params);
        if (!res || res.code !== 1) return;
        let options = typeof res.data === "string" ? JSON.parse(res.data) : res.data;

        //字典获取 特殊处理 映射 label =>value
        this.setState({ options: options.map((i) => ({ ...i, value: i.title })) });
    };
    onChange = (e) => {
        let { onChange } = this.props;

        onChange && onChange(e);
    };

    onSearch = (val) => {
        let { url } = this.props;
        // if (this.props.filedName === "devTypeId") {
        //     // return this.getDevTypeId({templateName: val})
        // } else {
        //     // this.getOptions(url, { templateName: val })
        // }
    };

    filterTreeNode = (input, node) => {
        if (typeof node.title === "string") {
            if (node.title.indexOf(input) !== -1) {
                return true;
            } else {
                return false;
            }
        } else {
            if (node.name.indexOf(input) !== -1) {
                return true;
            } else {
                return false;
            }
        }
    };
    render() {
        let { value, disabled, showSearch } = this.props;
        const { options } = this.state;
        //回填 bug
        if (options.length > 0) {
        } else {
            value = "";
        }
        return (
            <TreeSelect
                className="nms-select-bottom asset-manager-select"
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                    minWidth: 300,
                }}
                allowClear
                filterTreeNode={this.filterTreeNode}
                treeData={options}
                value={value}
                onSearch={this.onSearch}
                showSearch={showSearch}
                disabled={disabled}
                labelInValue={false}
                onChange={this.onChange}
                placeholder="请选择"
            />
        );
    }
}
const DynamicFromItem = function (props) {
    const {
        type,
        value,
        onChange = () => {},
        url,
        suffix,
        disabled = false,
        placeholder = "请输入",
    } = props;
    let Item = <Input placeholder={placeholder} className="nms-input-bottom" />;
    switch (type) {
        case "String":
            Item = (
                <Input
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className="nms-input-bottom"
                    suffix={suffix}
                />
            );
            break;
        case "TextArea":
            Item = (
                <TextArea
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    autoSize={{ minRows: 5, maxRows: 12 }}
                    placeholder={placeholder}
                />
            );
            break;
        case "Select":
            // Item = <Input
            //     value={value}
            //     onChange={onChange}
            //     placeholder=""
            //     className="nms-input-bottom"
            // />
            Item = (
                <CustomSelect {...props} value={value} onChange={onChange} url={url} />
            );
            break;
        case "TreeSelect":
            Item = (
                <CusTreeSelect {...props} value={value} onChange={onChange} url={url} />
            );
            break;
        case "File":
            Item = (
                <CustomUpload disabled={disabled} value={value} onChange={onChange} />
            );
            break;

        case "Date":
            Item = (
                <DatePicker
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    style={{ width: "100%" }}
                />
            );
        default:
            break;
    }

    return Item;
};

//级联选择
const CusCascader = (props) => {
    const {
        item,
        formItemLayout,
        item: { children, filedName },
        form,
        wrap,
    } = props;
    const childrenItem = children[0];
    const [childParams, setChildParams] = useState({});
    const onChange = (v) => {
        if (form) {
            form.setFieldsValue({
                [childrenItem.filedName]: "",
            });
        }
        const { parentKey = {} } = childrenItem;
        if (!v) {
            return setChildParams({});
        }
        setChildParams({
            [parentKey[filedName]]: v,
        });
        // if (childrenItem.url.indexOf("?") > -1) {
        //     setChildParams({
        //         [parentKey[filedName]]: v,
        //     });
        //     // setUrl(`${childrenItem.url}&value=${v}`)
        // } else {
        //     setChildParams({
        //         [parentKey[filedName]]: v,
        //     });
        //     // setUrl(`${childrenItem.url}?value=${v}`)
        // }
    };
    if (wrap) {
        return (
            <>
                <wrap.dom key={item.filedName} {...wrap.props}>
                    <Form.Item
                        label={item.labelName}
                        name={item.filedName}
                        {...formItemLayout}
                        key={item.labelName}
                        rules={[
                            {
                                required: item.require === "true",
                                message: "请选择",
                            },
                        ]}
                    >
                        <CustomSelect {...item} onChange={onChange} />
                    </Form.Item>
                </wrap.dom>

                {childrenItem && (
                    <wrap.dom key={item.filedName} {...wrap.props}>
                        <Form.Item
                            label={childrenItem.labelName}
                            name={childrenItem.filedName}
                            {...formItemLayout}
                            key={childrenItem.labelName}
                            rules={[
                                {
                                    required: childrenItem.require === "true",
                                    message: "请选择",
                                },
                            ]}
                        >
                            <CustomSelect
                                {...childrenItem}
                                hasAbover={true}
                                params={{ ...childrenItem.params, ...childParams }}
                            />
                        </Form.Item>
                    </wrap.dom>
                )}
            </>
        );
    }

    return (
        <>
            <Form.Item
                label={item.labelName}
                name={item.filedName}
                {...formItemLayout}
                key={item.labelName}
                rules={[
                    {
                        required: item.require === "true",
                        message: "请选择",
                    },
                ]}
            >
                <CustomSelect {...item} onChange={onChange} />
            </Form.Item>
            {childrenItem && (
                <Form.Item
                    label={childrenItem.labelName}
                    name={childrenItem.filedName}
                    {...formItemLayout}
                    key={childrenItem.labelName}
                    rules={[
                        {
                            required: childrenItem.require === "true",
                            message: "请选择",
                        },
                    ]}
                >
                    <CustomSelect
                        {...childrenItem}
                        hasAbover={true}
                        params={{ ...childrenItem.params, ...childParams }}
                    />
                </Form.Item>
            )}
        </>
    );
};

export function renderFormItem(li, formItemLayout, form, disabled, wrap) {
    if (li.children) {
        return (
            <CusCascader
                item={li}
                formItemLayout={formItemLayout}
                form={form}
                key={li.labelName}
                wrap={wrap}
            ></CusCascader>
        );
    }

    let rule = [];
    if (li.require === "true") {
        rule.push({
            required: true,
            message: "请输入",
        });
    }
    if (li?.rule && li.rule.length > 0) {
        rule = li.rule;
    }
    const item = (
        <Form.Item
            label={li.labelName}
            name={li.filedName}
            {...formItemLayout}
            key={li.labelName}
            rules={rule}
        >
            <DynamicFromItem {...li} disabled={disabled} />
        </Form.Item>
    );
    return item;
}

export default DynamicFromItem;
