import React from 'react';
import Menu from '@/components/Menu/Menu';
import routesMaps from '@/routers/routesMaps';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'antd';
import './siderCustom.less';
const { Item, SubMenu } = Menu;

const { Search } = Input;


const mapStateToProps = state => {
    const { menus } = state.userManager.userInfo;
    return {
        menus,
    };
};
/**
 * @export SiderCustom
 * @class SiderCustom
 * @extends {React.Component}
 */
@connect(mapStateToProps)
@withRouter
class SiderCustom extends React.Component {
    state = {
        hoverUnfold: false,
        showSearchInput: true,
        menus: [],
        inputValue: ''
    };
    count = 1;
    filter(node, input) {

        if (node.title.indexOf(input) !== -1) {
            return true
        } else {
            return false
        }

    }
    filterMenus(menu, input) {
        if (this.filter(menu, input)) {

            return menu
        }
        if (menu.subs.length > 0) {
            let subs = menu.subs.filter(i => this.filter(i, input))
            if (subs.length > 0) {
                return {
                    ...menu,
                    subs
                }
            }
        }

        return ''
    }

    searchNav = (value) => {
        let { menus, collapsed } = this.props;
        menus = this.formaterRouter(menus);
        if (value) {
            let _menus = menus.map(menu => this.filterMenus(menu, value)).filter(menu => menu)
            return _menus
        }

        return menus

    }
    formaterRouter = menus => {
        const targetMenus = menus.map(element => {
            const { attributes, text } = element;
            const { url, icon, visible, openType } = attributes;
            if (!visible) return;
            return element.children.length
                ? {
                    key: url,
                    title: text,
                    icon: 'fa kdfont ' + icon,
                    openType,
                    subs: this.formaterRouter(element.children),
                }
                : {
                    key: url,
                    title: text,
                    icon: 'fa kdfont ' + icon,
                    openType,
                    component: this.getComponentByKey(url),
                };
        });
        return targetMenus;
    };

    getComponentByKey = key => {
        const routerMap = routesMaps.find(item => {
            return item.key === key;
        });
        return routerMap && routerMap.component;
    };

    renderMenuList(menus) {
        let {
            location: { pathname },
            collapsed,
        } = this.props;
        // 过滤 undefined
        menus = menus.filter(item => item)
        return menus.map(menu => {

            if (/^http/.test(menu.key)) {
                menu.key = `/client/iframe/${menu.key}`;
            }
            if (typeof menu.subs === 'undefined') {
                return <Item
                    key={menu.key}
                    pathname={pathname}
                    name={menu.key}
                    collapsed={collapsed}
                >
                    {menu.openType === 0 ?
                        <Link to={menu.key} >
                            <i className={menu.icon}></i>
                            <span className="menu-word">{menu.title}</span>
                        </Link>
                        :
                        menu.key.indexOf('http') > -1 ?
                            <a onClick={() => window.open(menu.key.slice(menu.key.indexOf('http')))} title='新窗口打开'>
                                <i className={menu.icon} />
                                <span className="menu-word">{menu.title}</span>
                                <i className='fa kdfont fa-reply' />
                            </a>
                            :
                            <a onClick={() => window.open(`${window.location.origin}/#${menu.key}`)} title='新窗口打开'>
                                <i className={menu.icon}></i>
                                <span className="menu-word">{menu.title}</span>
                                <i className='fa kdfont fa-reply' />
                            </a>
                    }
                </Item>
            } else {
                return <SubMenu
                    key={menu.key}
                    title={menu.title}
                    icon={menu.icon}
                    pathname={pathname}
                    name={menu.key}
                    collapsed={collapsed}
                    hoverUnfold={this.state.hoverUnfold}
                    setHoverUnfold={this.setHoverUnfold}
                >
                    {this.renderMenuList(menu.subs)}
                </SubMenu>
            }
        });
    }

    setHoverUnfold = unfold => {
        this.setState({
            hoverUnfold: unfold,
        });
    };

    render() {
        let { menus, collapsed } = this.props;
        let { showSearchInput, inputValue } = this.state
        // menus = this.formaterRouter(menus);
        menus = this.searchNav(inputValue)

        return (
            <>
                {!collapsed && (
                    <div style={{
                        height: '45px', padding: '8px 4px 4px',
                        background: '#15264d'
                    }}>
                        <Search
                            className="list-search-input nms-input-bottom"
                            allowClear
                            placeholder="请输入"

                            onSearch={inputValue => {
                                this.setState({
                                    inputValue
                                })
                            }}
                        />
                    </div>

                )}
                <Menu
                    collapsed={collapsed}
                    hoverUnfold={this.state.hoverUnfold}
                    setHoverUnfold={this.setHoverUnfold}
                >
                    {this.renderMenuList(menus)}
                </Menu>
            </>

        );
    }
}

export default SiderCustom;
