/*
 * @Author: yek 
 * @Date: 2019-09-20 11:13:07 
 * @Last Modified by: yek
 * @Last Modified time: 2019-09-20 11:51:30
 */
/**
 * 对页面上输入的密码进行加密传输给后台进行验证，对返回的数据进行解密，在页面展示
 */
import CryptoJS from 'crypto-js'; // 引入AES源码js

/*
* 对密码进行加密，传输给后台进行验证
* @param {String}   word  需要加密的密码
* @param {String}   keyStr  对密码加密的秘钥
* @return {String}   加密的密文
* */
export const encrypt = (word, keyStr) => { // 加密
    keyStr = keyStr ? keyStr : 'ABGHNJHGSHUYG12';
    return CryptoJS.AES.encrypt(word, keyStr).toString()
}
/*
* 对加密之后的密文在页面上进行解密，以便用户进行修改
* @param {String}   word  需要加密的密码
* @param {String}   keyStr  对密码加密的秘钥
* @return {String}   解密的明文
* */
export const decrypt = (word, keyStr) => { // 解密
    keyStr = keyStr ? keyStr : 'ABGHNJHGSHUYG12';
    // Decrypt，将解密后的内容赋值给密码
    const bytes = CryptoJS.AES.decrypt(word, keyStr);
    return bytes.toString(CryptoJS.enc.Utf8);
}