/*
 * @Author: lsl
 * @Date: 2023-09-18 11:25:40
 * @Last Modified by: lsl
 * @Last Modified time: 2023-09-18 11:25:40
 */
import React from 'react';
import ShopModal from './ShopModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import BaseTable from '@/components/BaseTable/BaseTable';
import DragModal from '@/components/DragModal/DragModal';
import { Form } from '@ant-design/compatible';
import { message, Button, Input, Popconfirm } from 'antd';
import { post, get } from '@/requests/index';
import { formatterDate } from '@/utils';
import './shopManager.less';

const shopListUrl = 'wxdc/shop/page';
@Form.create()
class ShopManager extends React.Component{
    state = {
        modalVisible: false, //modal是否可见
        type: 'add',
        shopInfo: {}
    };
    //当前正在编辑的商店id
    currentEditShopId = '';
    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            render: (name, record, index) =>`${index + 1}`,
            width: '5%',   
        },
        {
            title: 'name',
            dataIndex: 'name',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '5%',   
        },
        {
            title: '商家二维码',
            dataIndex: 'ewmUrl',
            align: 'center',
            render: (value, record, index) =>{return (<img style={{width:"40px",height:"40px"}} src={ value ? value : 'http://localhost/404.png'}></img>)},
            width: '10%',   
        },
        {
            title: '地址',
            dataIndex: 'address',
            align: 'center',
            render: (value, record, index) => `${value || '--'}`,
            width: '5%',   
        },
        {
            title: '经度',
            dataIndex: 'longitude',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '4%',   
        },
        {
            title: '纬度',
            dataIndex: 'latitude',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '4%',   
        },
        {
            title: '联系电话',
            dataIndex: 'linkPhone',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '是否展示联系方式',
            dataIndex: 'linkPhoneShow',
            align: 'center',
            render: (value, record, index) =>`${value ? '显示' : '不显示'}`,
            width: '10%',   
        },
        {
            title: '开业时间',
            dataIndex: 'openingHours',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: 'wifi账号',
            dataIndex: 'wifiSsid',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: 'wifi密码',
            dataIndex: 'wifiPass',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: 'wifi物理地址',
            dataIndex: 'wifiBssid',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: value => `${value ? '正常' : '禁用'}`,
            width: '5%',   
        },
        {
            title: '工作状态',
            dataIndex: 'workStatus',
            align: 'center',
            render: value => `${value ? '营业中' : '打烊了'}`,
            width: '10%',   
        },
        {
            title: '创建用户',
            dataIndex: 'userId',
            align: 'center',
            render: (value, record, index) =>`${value || '--'}`,
            width: '10%',   
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
            render: value => formatterDate(value),
            width: '10%',   
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            align: 'center',
            render: value => formatterDate(value),
            width: '10%',   
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '10%',
            fixed: 'right',
            render: (value, record, index) => {
                return (
                    <div className="actions-buttons">
                        <i
                            className="fa kdfont fa-edit icon-btn"
                            title="编辑商店"
                            onClick={this.editShopModal.bind(this, record)}
                        />
                        
                        <a
                            target="_blank"
                            title="点击下载"
                            download
                            href={`${window.location.protocol}//${window.location.host}/api/wxdc/shop/getShareQr/` +
                            record.id}
                            className="operation"
                        >
                            <i
                                className="fa kdfont fa-download icon-btn"
                                title="下载二维码"
                            ></i>
                        </a>
                        <Popconfirm
                            title="确定删除该商店?"
                            onConfirm={() => this.deleteShopById(record.id)}
                        >
                            <i
                                className="fa kdfont fa-trash-alt icon-btn"
                                title="删除商店"
                            />
                        </Popconfirm>
                        <Popconfirm
                            title="确定创建该商店二维码?"
                            onConfirm={() => this.createEwm(record.id)}
                        >
                            <i
                                className="fa kdfont fa-cogs icon-btn"
                                title="创建二维码"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },

    ];

    //编辑商店
    editShopModal = shopInfo => {
        this.setState({
            modalVisible: true,
            type: 'edit',
            shopInfo: shopInfo,
        });
    };

    //删除商店通过id
    deleteShopById = async id => {
        let resData = await post('wxdc/shop/remove/' + id);
        if (!resData.code){
            message.error(resData.data || '删除失败');
            return;
        }
        this.getTableData({isDelete: true});
        message.success('删除成功')
    };
    //创建商店二维码
    createEwm = async id => {
        let resData = await get('wxdc/shop/createEwm/' + id);
        if (!resData.code){
            message.error(resData.data || '生成失败');
            return;
        }
        this.getTableData({isDelete: true});
        message.success('生成成功')
    };
    addShopModal =() => {
        this.setState({
            modalVisible: true,
            shopInfo: {},
            type: 'add',
        });
    };
    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
        const { form } = this.formRef.props;
        //重置表单
        form.resetFields();
    };
    //获取表格数据
    getTableData = (params = {}) => {
        console.log('111111')
        this.baseTable && this.baseTable.getTableData(params);
    };
    handleCreate = () => {
        const { form, type, shopInfo} = this.formRef.props;
        //填上开业时间
        form.validateFields(async (err, values) => {
            if (err) {
                console.log('value', values)
                console.log('test', err)
                return;
            }
            //重置表单
            form.resetFields();
            let urlStr = 'wxdc/shop/save';
            let time = values.openingHoursArray;
            let params = {
                ...values,
                openingHours: time[0].format('HH:mm') + '-' + time[1].format('HH:mm') 
            }
            const resultTips = type === 'add' ? '添加' : '修改';
            if (type === 'edit'){
                urlStr = 'wxdc/shop/update';
                params.id = shopInfo.id;
            } 
            //请求参数
            let resData = await post(urlStr, params);
            if(!resData.code){
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getTableData();
            message.success(resultTips + '成功');
            //保存参数
            this.setState({ modalVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getButtonList = () => {
        return (
            <Button
                title="新增商店"
                className="nms-button"
                onClick={this.addShopModal}
            >
                <i className="fa kdfont fa-plus"/> 增加
            </Button>
        );
    };
    render() {
        const {
            modalVisible,
            type,
            shopInfo,
        } = this.state;
        console.log('aaa', this.props)
        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="shop-manager">
                    <BaseTable
                        height="100%"
                        onRef={ref => (this.baseTable = ref)}
                        url={shopListUrl}
                        axiosType="get"
                        allColumn={this.columns}
                        onRow={record => {
                            return {
                                onDoubleClick: () => this.editShopModal(record),
                            };
                        }}
                    />
                    <ShopModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={modalVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        shopInfo={shopInfo}
                        type={type}
                    />
                </div>
            </InnerPage>
        );
    }
}


export default ShopManager;