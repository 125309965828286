/**
 * Created by chenll
 * DATE: 2020/05/13
 * Description: React错误边界处理的高阶函数
 */
import React from 'react';
import ErrorBoundary from '../components/ErrorBoundary';

const errorCatch = (Target) => {
    class Wrap extends React.Component {
        render() {
            return (
                <ErrorBoundary>
                    <Target {...this.props}/>
                </ErrorBoundary>
            )
        }
    }
    return Wrap;
}

export default errorCatch;