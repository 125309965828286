import React from 'react';
import DragM from 'dragm';
import { Modal } from 'antd';
import './dragModal.less';

class ModalTitle extends React.Component {
    updateTransform = transformStr => {
        // 可视区的宽高
        let clientWidth = document.documentElement.clientWidth;
        let clientHeight = document.documentElement.clientHeight;
        if (!this.initPositionInfo) return;
        let { left, top, right, bottom } = this.initPositionInfo;
        let regexp = /translate\((-?\w+)px\,(-?\w+)px\)/gi;
        if (regexp.test(transformStr)) {
            let translateX = RegExp.$1;
            let translateY = RegExp.$2;
            // 获取元素针对可视区的便宜
            // 左边
            if (translateX < -left) {
                translateX = -left;
            }
            // 上
            if (translateY < -top) {
                translateY = -top;
            }
            // 右
            if (translateX > clientWidth - right) {
                translateX = clientWidth - right;
            }
            // 底部
            if (translateY > clientHeight - bottom) {
                translateY = clientHeight - bottom;
            }
            this.modalDom.style.left = translateX + 'px';
            this.modalDom.style.top = translateY + 'px';
        }
    };

    componentDidMount() {
        const { dragClass } = this.props;
        if (!dragClass) return;
        this.modalDom = document
            .getElementsByClassName(dragClass)[0]
            .querySelector('.ant-modal-content');
        this.initPositionInfo =
            this.modalDom && this.modalDom.getBoundingClientRect();
    }

    render() {
        const { title } = this.props;
        return (
            <DragM updateTransform={this.updateTransform}>
                <div>{title}</div>
            </DragM>
        );
    }
}

export default class DragModal extends React.Component {
    state = {
        dragClass: 'drag-' + new Date().getTime(),
    };

    onCancel = e => {
        e.stopPropagation();
        let { onCancel } = this.props;
        let { dragClass } = this.state;
        let modalDom = document
            .getElementsByClassName(dragClass)[0]
            .querySelector('.ant-modal-content');
        onCancel && onCancel();
        if (modalDom) {
            setTimeout(() => {
                modalDom.style.left = '0px';
                modalDom.style.top = '0px';
            }, 500);
        };
    };
    componentDidMount() {
        this.setState({
            contentHeight: this.getContentHeight(),
        });
        window.addEventListener('resize', this.resizeModal);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeModal);
    }
    resizeModal = () => {
        this.setState({
            contentHeight: this.getContentHeight(),
        });
    };
    onOk = () => {
        let { onOk } = this.props;
        let { dragClass } = this.state;
        let modalDom = document
            .getElementsByClassName(dragClass)[0]
            .querySelector('.ant-modal-content');
        onOk && onOk();
        if (modalDom) {
            setTimeout(() => {
                modalDom.style.left = 0 + 'px';
                modalDom.style.top = 0 + 'px';
            }, 500);
        }
    };
    // 获取高度
    getContentHeight = () => {
        let { height, footer = true } = this.props;
        // 浏览器可视高度
        let documentHeight = document.documentElement.clientHeight;
        let footerHeight = footer ? 57 : 0;
        const headerHeight = 40;
        if (!height) return 'auto';
        if (typeof height === 'number') {
            return height - footerHeight - headerHeight + 'px';
        }
        if (typeof height === 'string') {
            if (height.search('%') > -1)
                return (
                    (parseFloat(height) * documentHeight) / 100 -
                    footerHeight -
                    headerHeight +
                    'px'
                );
            if (height.search('px') > -1)
                return parseFloat(height) - footerHeight - headerHeight + 'px';
        }
        return 'auto';
    };
    render() {
        const { contentStyle = {} } = this.props
        const { dragClass, contentHeight } = this.state;
        const title = (
            <ModalTitle dragClass={dragClass} title={this.props.title} />
        );
        return (
            <Modal
                wrapClassName={'nms-modal ' + dragClass}
                {...{ maskClosable: false, ...this.props }}
                onCancel={this.onCancel}
                onOk={this.onOk}
                title={title}
                closeIcon={<i className="fa kdfont fa-times"></i>}
                destroyOnClose={true}
            >
                <div style={{ height: contentHeight, ...contentStyle }} className="modal-body-content">
                    {this.props.children}
                </div>
            </Modal>
        );
    }
}
