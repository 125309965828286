import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";

// 分页高度
const PAGINATION_HEIGHT = 50;

// 表头高度
const TABLE_HEIGHT = 40;

/**
 * @feature 响应式表格
 * @export ResponsiveTable
 * @class ResponsiveTable
 * @extends {React.Component}
 */
class ResponsiveTable extends React.Component {
    state = {
        tableBoxHeight: 0,
        tableBoxWidth: 0,
        selectedRowIds: [],
        // 自定义表头宽度
        reactiveColumns: [],
        newColumns: [],
    };

    // 记录shift是否按住
    isShiftKey = false;
    // shift 第一次选中的行
    firstSelectRowIndex;
    // shift 第二次选中的行
    secondSelectRowIndex;

    componentDidMount() {
        let { onRef } = this.props;
        this.computedSize();
        onRef && onRef(this);
        window.addEventListener("divresize", () => {
            this.computedSize();
        });
    }

    componentDidUpdate(preProps, proState) {
        // console.log("this", preProps);
        // if(preProps.columns)
        // console.log("new", proState.newColumns);
        // if (this.state.newColumns !== proState.newColumns) {
        //     this.setState({
        //         newColumns: preProps.newColumns,
        //     });
        // }
    }
    componentWillReceiveProps(nextProps) {
        // this.setState({ newColumns: nextColumns });
    }

    computedSize = () => {
        let { tableBox } = this;
        let { pagination } = this.props;
        if (!tableBox) return;
        let tableBoxHeight = tableBox.offsetHeight;
        let antTable = tableBox.querySelector(".ant-table");
        let paginationHeight = 0;
        paginationHeight = pagination ? PAGINATION_HEIGHT : 0;

        antTable.style.height = tableBox.offsetHeight - paginationHeight + "px";
        this.setState({
            tableBoxHeight: tableBoxHeight - paginationHeight - TABLE_HEIGHT,
        });
    };

    rowStyle = (record, index) => {
        let { rowKey, rowHighlight } = this.props;
        let rowId = (rowKey && rowKey(record)) || record.id;
        // 高亮选择
        if (rowHighlight && rowHighlight(record)) {
            return "row-selected";
        }
        if (this.state.selectedRowIds.includes(rowId)) {
            return "row-selected";
        } else {
            return index % 2 === 0 ? "row-even" : "row-odd";
        }
    };
    // 获取横向滚动宽度
    getScrollXWidth = () => {
        // 获取父盒子的宽
        let { tableBox } = this;
        if (!tableBox) return {};
        let wraperStyle = getComputedStyle(tableBox);
        let { width: wraperWidthStr } = wraperStyle;
        let wraperWidth = parseFloat(wraperWidthStr);
        let allColunmsWidth = this.getAllColumnsWidth(wraperWidth);
        if (parseInt(wraperWidth) < parseInt(allColunmsWidth)) {
            return { x: allColunmsWidth };
        } else {
            return {};
        }
    };
    // 获取所有列的宽度
    getAllColumnsWidth = (wraperWidth) => {
        let { columns } = this.props;
        // let { newColumns } = this.state;
        // 获取所有列的宽
        let allColunmsWidth = columns.reduce((total, item) => {
            let { width } = item;
            let itemWidth;
            if (!width) {
                // 如果没有传宽度，给一个默认的宽
                itemWidth = 100;
            }
            if (typeof width === "string") {
                // 如果是字符串 默认是百分比类型
                if (isNaN(parseFloat(width))) {
                    itemWidth = 0;
                } else {
                    if (width.endsWith("%")) {
                        itemWidth = (wraperWidth * parseFloat(width)) / 100;
                    } else {
                        itemWidth = parseFloat(width);
                    }
                }
            }
            if (typeof width === "number") {
                itemWidth = width;
            }
            return total + itemWidth;
        }, 0);

        return allColunmsWidth;
    };
    selectRow = (id, index) => {
        let { selectedRowIds } = this.state;
        let { singleSelect = true } = this.props;
        let newSelectedIds = [];
        if (selectedRowIds.includes(id)) {
            newSelectedIds = selectedRowIds.filter((item) => item !== id);
            this.firstSelectRowIndex = undefined;
        } else {
            // 单选模式下
            if (singleSelect) {
                newSelectedIds = [id];
                // 按住了shiftkey 并且之前有一个选中的项
                if (this.isShiftKey && this.firstSelectRowIndex !== undefined) {
                    this.secondSelectRowIndex = index;
                    // 获取中间的数据
                    newSelectedIds = this.getBetweenRecords(
                        this.firstSelectRowIndex,
                        this.secondSelectRowIndex
                    );
                }
            } else {
                newSelectedIds = [...selectedRowIds, id];
            }
            this.firstSelectRowIndex = index;
        }
        // 点击同一行取消选择
        this.setState({
            selectedRowIds: newSelectedIds,
        });
    };
    /**
     *first second
     */
    getBetweenRecords = (firstIndex, secondIndex) => {
        // 选中的id集合
        const selectIds = [];
        if (firstIndex === undefined || secondIndex === undefined) return selectIds;
        const { dataSource = [] } = this.props;
        // 索引排序 从小到大排序
        const distanceArr = [firstIndex, secondIndex].sort((a, b) => a - b);
        dataSource.forEach((item, index) => {
            // 找到该区间的数据
            if (
                (index > distanceArr[0] || index === distanceArr[0]) &&
                (index < distanceArr[1] || index === distanceArr[1])
            ) {
                selectIds.push(item.id);
            }
        });
        return selectIds;
    };
    //表格选中样式
    onClickRow = (record, index) => {
        // 组件传过来的事件
        let { onRow, rowKey, selectRowByClick } = this.props;
        let rowId = (rowKey && rowKey(record)) || record.id;
        if (!onRow && selectRowByClick) {
            return {
                onClick: (e) => {
                    this.selectRow(rowId);
                },
            };
        }
        let rowEventObj = onRow(record);
        // // 将单机事件提出来
        let { onClick } = rowEventObj;
        // 将组件传进来的行方法合并
        let newRowEvent = Object.assign({}, rowEventObj, {
            onClick: (e) => {
                this.isShiftKey = e.shiftKey;
                onClick && onClick();
                selectRowByClick && this.selectRow(rowId, index);
            },
        });
        return newRowEvent;
    };

    handleResize = (index) => (e, { size }) => {
        // console.log(size);
        let { columns } = this.props;
        let { newColumns } = this.state;
        if (newColumns.length) {
            columns = newColumns;
        }
        const nextColumns = [...columns];
        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };
        this.setState({ newColumns: nextColumns });
    };
    getColumns = () => {
        let { columns } = this.props;
        let { newColumns } = this.state;
        if (newColumns.length) {
            columns = newColumns;
        }
        return columns.map((ele, index) => {
            ele.ellipsis = true;
            return {
                ...ele,
                onHeaderCell: (column) => {
                    let { width } = column;
                    if (
                        width &&
                        Object.prototype.toString.call(width) === "[object String]"
                    ) {
                        if (width.endsWith("px")) {
                            width = parseFloat(width);
                        } else if (width.endsWith("%")) {
                            const tableBox = this.tableBox;
                            if (!tableBox) {
                                return {};
                            }
                            // 获取表格计算后的宽度并转为数字
                            const { width: tableWidthStr } = getComputedStyle(tableBox);
                            const tableWidthNum = parseFloat(tableWidthStr);
                            width = (tableWidthNum * parseFloat(width)) / 100;
                        }
                    }
                    return {
                        width,
                        onResize: this.handleResize(index),
                    };
                },
            };
        });
    };
    render() {
        let { tableBoxHeight } = this.state;
        let { className } = this.props;
        className = "nms-table " + className;
        let scrollX = this.getScrollXWidth();
        let combineScroll = Object.assign({}, scrollX, { y: tableBoxHeight });

        return (
            <div
                ref={(ref) => {
                    this.tableBox = ref;
                }}
                style={{ height: "100%", position: "relative" }}
            >
                <Table
                    {...this.props}
                    columns={this.getColumns()}
                    // columns={this.state.columns}
                    className={className}
                    scroll={combineScroll}
                    rowClassName={this.rowStyle}
                    onRow={this.onClickRow}
                />
            </div>
        );
    }
}

export default ResponsiveTable;

// 参数验证
ResponsiveTable.propTypes = {
    // 是否通过点击选择行
    selectRowByClick: PropTypes.bool,
};
// 默认值
ResponsiveTable.defaultProps = {
    selectRowByClick: true,
    // 点击选中行  默认只选中一行
    singleSelect: true,
};
