export default {
    "XH": "XH",
    "BW": "BW",
    "ZD": "ZD",
    "ZB": "ZB",
    "ZC": "ZC",
    "WX": "WX",
    "RW": "RW",
    "KS": "KS",
    "QS": "QS",
};
