/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-13 15:21:24
 */
import React from 'react';
import DragModal from '@/components/DragModal/DragModal';
import { Form } from '@ant-design/compatible';
import { CustomUpload } from "@/components/FormItem/DynamicFromItem"
import SelectIcon from './SelectIcon';

import { Input, Tooltip, Select, Switch } from 'antd';

class MenuModal extends React.Component {
    state = {
        showIconBox: false,
    };

    selectIcon = icon => {
        this.props.form.setFieldsValue({
            icon: 'fa-' + icon
        })
        this.setState({
            showIconBox: false
        });
    };

    inputIcon = ({ target }) => {
        console.log(target);
    };

    render() {
        const {
            visible,
            onCancel,
            onCreate,
            form,
            menuInfo,
            type,
        } = this.props;
        const { showIconBox } = this.state;
        const { getFieldDecorator } = form;
        const titleObj = {
            add: '新增',
            edit: '编辑',
            addNextMenu: '新增子',
        };
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        let url = menuInfo?.attributes?.url || ""
        if (url.indexOf("http") > -1) {
            url = decodeURIComponent(menuInfo.attributes.url)
        }

        return (
            <div>
                <DragModal
                    className="menu-manager over-no-hidden"
                    title={(titleObj[type] || '新增') + '菜单'}
                    visible={visible}
                    // height={450}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Form layout="horizontal" className="nms-form">
                        <Form.Item label="菜单名称" {...formItemLayout}>
                            {getFieldDecorator('menuName', {
                                initialValue: menuInfo.text,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入菜单名称',
                                    },
                                    {
                                        max: 10,
                                        message: '菜单名称不能超过10个字符',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入菜单名称"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="地址" {...formItemLayout}>
                            {getFieldDecorator('url', {
                                initialValue: url
                                ,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入菜单地址',
                                    },
                                    {
                                        whitespace: true,
                                        max: 500,
                                        message: '地址不能超过500个字符',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入地址"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Tooltip
                            placement='left'
                            title={<div
                                style={{ fontSize: 12 }}
                            >
                                输入路径（如：“/xxx/xxx”）则匹配项目内部组件。
                                <br />
                                输入路径（如：“http://xxx.xxx或https://xxx.xxx”）则在本项目‘根’菜单下用iframe来展示该路径信息。（如：“/client/center/http://xxx.xxx或/client/center/https://xxx.xxx”）则在本项目‘对应’路由菜单下用iframe来展示该路径信息。
                                </div>
                            }
                        >
                            <i className='menu-font-question fa kdfont fa-question-circle' />
                        </Tooltip>
                        <Form.Item label="权限标识" {...formItemLayout}>
                            {getFieldDecorator('perms', {
                                initialValue: menuInfo.status,
                                rules: [
                                    {
                                        max: 20,
                                        message: '权限字段不超过20个字符',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入权限标识"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="排序" {...formItemLayout}>
                            {getFieldDecorator('orderNum', {
                                initialValue:
                                    menuInfo.attributes &&
                                    menuInfo.attributes.orderNum,
                                rules: [
                                    {
                                        whitespace: true,
                                        max: 99,
                                        min: 1,
                                        transform(value) {
                                            if (value) {
                                                return Number(value);
                                            }
                                        },
                                        type: 'integer',
                                        message: '请输入1 ~ 99的数字',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入1 ~ 99的数字"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="图标" {...formItemLayout}>
                            {getFieldDecorator('icon', {
                                initialValue:
                                    menuInfo.attributes &&
                                    menuInfo.attributes.icon,
                                rules: [
                                    {
                                        max: 50,
                                        message: '图标字段不超过20个字符',
                                    },
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请选择图标',
                                    }
                                ],
                            })(
                                <Input
                                    placeholder="请选择图标"
                                    className="nms-input-bottom"
                                    onInput={this.inputIcon}
                                    onFocus={() => this.setState({ showIconBox: true })}
                                >
                                </Input>
                            )}
                            <span className={`custom-icon kdfont fa-chevron-down ${showIconBox ? 'open-icon' : ''}`} />
                            <SelectIcon
                                visible={showIconBox}
                                currentIcon={menuInfo.attributes ? menuInfo.attributes.icon : ''}
                                closeIcon={() => this.setState({ showIconBox: false })}
                                onSelect={this.selectIcon}
                            />
                        </Form.Item>
                        {menuInfo.children && menuInfo.children.length === 0 && <Form.Item
                            label='打开方式'
                            {...formItemLayout}
                        >
                            {getFieldDecorator('openType', {
                                initialValue: menuInfo.attributes && `${menuInfo.attributes.openType}`,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请选择打开方式',
                                    }
                                ]
                            })(
                                <Select
                                    className='nms-select-bottom'
                                    placeholder='请选择打开方式'
                                >
                                    <Select.Option key='0'>内嵌打开</Select.Option>
                                    <Select.Option key='1'>新窗口打开</Select.Option>
                                </Select>
                            )}
                        </Form.Item>}

                        <Form.Item label="在线帮助" {...formItemLayout}>
                            {getFieldDecorator('helpText', {
                                initialValue:  menuInfo?.attributes?.helpText,
                                rules: [
                                    {
                                        required: false,
                                        whitespace: true,
                                        message: '请输入帮助说明',
                                    },
                                ],
                            })(
                                <CustomUpload
                                    placeholder="请输入菜单名称"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label='是否可见'
                            {...formItemLayout}
                        >
                            {getFieldDecorator('visible', {
                                initialValue: menuInfo.attributes && menuInfo.attributes.visible,
                                valuePropName: 'checked'
                            })(
                                <Switch
                                    style={{ marginTop: 6 }}
                                    checkedChildren="是" unCheckedChildren="否"
                                />
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        );
    }
}
export default Form.create()(MenuModal);
