import React from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Tree, Button, message } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    setDictionaryTree,
    setEditKey,
} from '@/redux/dictionaryManager/dictionaryManager';
import { specialCharacters } from '@/utils/validateReg';

const { TreeNode, DirectoryTree } = Tree;

// 映射状态到组件
const mapStateToProps = state => {
    let { dictionaryManager } = state;
    return dictionaryManager;
};

// 映射组件dispatch
const mapDispatchToProps = dispatch => ({
    // 设置字典树
    setDictionaryTree: bindActionCreators(setDictionaryTree, dispatch),
    // 设置编辑行id
    setEditKey: bindActionCreators(setEditKey, dispatch),
});

/**
 * @feature 添加或编辑公告弹窗
 * @export VauleTree
 * @class VauleTree
 * @extends {React.Component}
 */

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
class VauleTree extends React.Component {
    // 添加节点
    treeAdd = (e, parentId) => {
        e.stopPropagation();
        let {
            dictionaryTree,
            setDictionaryTree,
            setEditKey,
            onChange,
        } = this.props;
        let newDictionaryTree = [...dictionaryTree];
        if (!parentId) {
            // 获取最后值的索引
            const lastItemIndex = Number(this.getLastIndex(newDictionaryTree));
            let nodeKey = 'k_' + (lastItemIndex + 1);
            newDictionaryTree.push({
                title: '',
                key: nodeKey,
                children: [],
                isNew: true,
            });
            setEditKey(nodeKey);
        } else {
            // 目标节点
            this.editChildrenNode(newDictionaryTree, parentId, { type: 'add' });
        }
        // 更新数据
        setDictionaryTree && setDictionaryTree(newDictionaryTree);
        onChange && onChange(newDictionaryTree);
    };
    // 获取最后一个值的索引
    getLastIndex = data => {
        if (!data) return;
        if (!data.length) return 0;
        const lastItem = data[data.length - 1];
        // 根据$分割
        const lastItemBysplit = lastItem.key.split('$');
        const lastKey = lastItemBysplit[lastItemBysplit.length - 1];
        // 获取索引值 
        const keyArr = lastKey.split('_');
        const keyIndex = Number(keyArr[keyArr.length - 1])
        return keyIndex;
    };
    // 编辑节点
    treeEdit = (e, nodeKey) => {
        let { setEditKey } = this.props;
        setEditKey(nodeKey);
    };
    // 删除树节点
    treeDelete = (e, nodeKey) => {
        e.stopPropagation();
        let { dictionaryTree, setDictionaryTree, onChange } = this.props;
        let newDictionaryTree = [...dictionaryTree];
        // 找到节点并删除节点
        this.editChildrenNode(newDictionaryTree, nodeKey, { type: 'delete' });
        setDictionaryTree && setDictionaryTree(newDictionaryTree);
        onChange && onChange(newDictionaryTree);
    };
    // 添加子节点
    editChildrenNode = function (treeData, parentId, option) {
        let result, item;
        let { setEditKey } = this.props;
        for (let i = 0; i < treeData.length; i++) {
            item = treeData[i];
            if (parentId === item.key) {
                let { type } = option;
                switch (type) {
                    case 'add':
                        if (!item.children) {
                            item.children = [];
                        }
                        const lastItemIndex = Number(
                            this.getLastIndex(item.children)
                        );
                        let nodeKey =
                            parentId +
                            '$' +
                            parentId +
                            '_' +
                            (lastItemIndex + 1); // 获取最后值的索引
                        item.children.push({
                            title: '',
                            key: nodeKey,
                            children: [],
                            isNew: true,
                        });
                        setEditKey(nodeKey);
                        break;
                    case 'delete':
                        treeData.splice(i, 1);
                        break;
                    case 'edit':
                        // 先判断同级重复
                        let { nodeTitle } = option;
                        let sameNameNode = treeData.find(
                            e => e.title === nodeTitle
                        );
                        if (sameNameNode) {
                            return 'exist';
                        }

                        delete item.isNew;
                        item.title = nodeTitle;
                        break;
                    default:
                        break;
                }
                return true;
            }
            if (item.children) {
                result = this.editChildrenNode(item.children, parentId, option);
                if (result) return result;
            }
        }
    };
    // 编辑节点确认
    nodeCheck = (e, nodeKey) => {
        e.stopPropagation();
        let { form, dictionaryTree, onChange, setEditKey } = this.props;
        let newDictionaryTree = [...dictionaryTree];
        let { validateFields } = form;
        validateFields((err, values) => {
            if (err) return;
            // 找到节点并编辑节点
            const result = this.editChildrenNode(newDictionaryTree, nodeKey, {
                type: 'edit',
                nodeTitle: values[nodeKey],
            });
            if (result === 'exist') {
                message.error('名称重复');
                return;
            }
            setDictionaryTree && setDictionaryTree(newDictionaryTree);
            onChange && onChange(newDictionaryTree);
            setEditKey('');
        });
    };
    // 取消编辑节点
    nodeUndo = (e, nodeInfo) => {
        e.stopPropagation();
        let {
            dictionaryTree,
            setDictionaryTree,
            setEditKey,
            onChange,
        } = this.props;
        let newDictionaryTree = [...dictionaryTree];
        let { isNew, key } = nodeInfo;
        setEditKey('');
        // 是新增的节点则删除
        if (isNew) {
            // 找到节点并编辑节点
            this.editChildrenNode(newDictionaryTree, key, {
                type: 'delete',
            });
            setDictionaryTree && setDictionaryTree(newDictionaryTree);
            onChange && onChange(newDictionaryTree);
        }
    };
    getNodeTitle = (nodeInfo, showAddIcon = true) => {
        let { form, editKey, dictionaryTree } = this.props;
        let nodeKey = nodeInfo.key;
        let { getFieldDecorator } = form;
        return (
            <>
                <span title={nodeInfo.title}>
                    {nodeKey === editKey ? (
                        <Form className="nms-form">
                            <Form.Item>
                                {getFieldDecorator(nodeKey, {
                                    initialValue: nodeInfo.title,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: '请输入值',
                                        },
                                        {
                                            max: 20,
                                            message: '值不能超过20个字符',
                                        },
                                        {
                                            pattern: specialCharacters,
                                            message: '仅支持特殊字符 - _ ( )',
                                        },
                                    ],
                                })(
                                    <Input
                                        className="nms-input-bottom"
                                        placeholder="请输入值"
                                        allowClear
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    ) : (
                            nodeInfo.title
                        )}
                </span>
                <div className="tree-icon-group">
                    {nodeKey === editKey ? (
                        <>
                            <Button
                                type="link"
                                title="确定"
                                onClick={event =>
                                    this.nodeCheck(event, nodeKey)
                                }
                            >
                                <i className="fa kdfont fa-check icon-btn"></i>
                            </Button>
                            <Button
                                type="link"
                                onClick={event =>
                                    this.nodeUndo(event, nodeInfo)
                                }
                                title="取消"
                            >
                                <i className="fa kdfont fa-reply icon-btn"></i>
                            </Button>
                        </>
                    ) : (
                            <>
                                {// 判断是否能增加子节点 当showAddIcon 为true 时候显示增加子节点
                                    showAddIcon ? (
                                        <Button
                                            disabled={!!editKey}
                                            type="link"
                                            title="增加子节点"
                                            onClick={event =>
                                                this.treeAdd(event, nodeKey)
                                            }
                                        >
                                            <i className="fa kdfont fa-plus icon-btn"></i>
                                        </Button>
                                    ) : (
                                            ''
                                        )}

                                <Button
                                    disabled={!!editKey}
                                    type="link"
                                    title="编辑节点"
                                    onClick={event => this.treeEdit(event, nodeKey)}
                                >
                                    <i className="fa kdfont fa-edit icon-btn"></i>
                                </Button>
                                <Button
                                    disabled={!!editKey}
                                    type="link"
                                    title="删除节点"
                                    onClick={event =>
                                        this.treeDelete(event, nodeKey)
                                    }
                                >
                                    <i className="fa kdfont fa-trash-alt icon-btn"></i>
                                </Button>
                            </>
                        )}
                </div>
            </>
        );
    };
    renderTreeNodes = (data, showAddIcon = true) =>
        data.map(item => {
            // let { configKey } = this.props;
            if (item.children) {
                // let isShowAdd = true;
                // if (configKey === 'system_name') {
                //     isShowAdd = false;
                // }
                return (
                    <TreeNode
                        title={this.getNodeTitle(item, showAddIcon)}
                        key={item.key}
                        dataRef={item}
                    >
                        {this.renderTreeNodes(item.children, false)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    key={item.key}
                    title={this.getNodeTitle(item, showAddIcon)}
                />
            );
        });
    render() {
        let { dictionaryTree, editKey } = this.props;
        if (!dictionaryTree) return null;
        return (
            <div>
                <Button
                    className="nms-button add-node-btn"
                    type="dashed"
                    disabled={!!editKey}
                    block
                    onClick={event => this.treeAdd(event)}
                >
                    <i className="fa kdfont fa-plus"></i>添加
                </Button>
                <DirectoryTree
                    blockNode
                    selectable={false}
                    showIcon={false}
                    className="nms-menu-tree tree-with-control"
                >
                    {this.renderTreeNodes(dictionaryTree)}
                </DirectoryTree>
            </div>
        );
    }
}

export default VauleTree;
