import { get } from '@/requests'
import { message } from 'antd'

const UPDATE_CABINET_STATISTIC_INFO = "UPDATE_CABINET_STATISTIC_INFO"
const UPDATE_ANTENNA_STATISTIC_INFO = "UPDATE_ANTENNA_STATISTIC_INFO"
const ALL_DATA = "ALL_DATA"

// 初始状态
const initialState = {
    cabinetInfo: [],
    antennaInfo: [],
}

// 操作数据
// 更新机房统计数据
const updateCabinetInfo = () => async dispatch => {
    debugger
    let res = await await get("resource/room/center/getRoomCabinetHardWare");
    if (!res?.code) {
        return message.error(res?.data ?? "获取机房统计数据失败！");
    }
    const { data = [] } = res;
    let CabCount = 0,
        deviceCount = 0,
        alarmCount = 0,
        dataList = [];
    CabCount = data?.length;
    data.forEach((item) => {
        deviceCount += item.hardwareCount;
        alarmCount += item.alarmCount;
    });
    dataList = [
        {
            name: "机房个数",
            num: CabCount,
        },
        {
            name: "设备个数",
            num: deviceCount,
        },
        {
            name: "告警个数",
            num: alarmCount,
        },
    ]
    dispatch({
        type: UPDATE_CABINET_STATISTIC_INFO,
        cabinetInfo: dataList,
    });
}

// 更新天线统计数据
const updateAntennaInfo = () => async dispatch => {
    const res = await get("statistics/hardware/getAntennaList");
    if (!res?.code) {
        return message.error(res?.data ?? "获取机房统计数据失败！");
    }

    const { data = [] } = res;
    let AntennaCount = 0,
        // 维护个数
        maintainCount = 0,
        alarmCount = 0,
        dataList = [];
    AntennaCount = data?.length;
    data.forEach((item) => {
        if (item.antennaStatus === 2) {
            alarmCount++;
        } else if (item.antennaStatus === 3) {
            maintainCount++;
        }
    });
    dataList = [
        {
            name: "天线个数",
            num: AntennaCount,
        },
        {
            name: "维护个数",
            num: maintainCount,
        },
        {
            name: "告警个数",
            num: alarmCount,
        },
    ]
    dispatch({
        type: UPDATE_ANTENNA_STATISTIC_INFO,
        antennaInfo: dataList,
    })
}

const topoCanvas = (state = initialState, action) => {
    if (!action) return state
    switch (action.type) {
        case UPDATE_CABINET_STATISTIC_INFO:
            return {
                ...state,
                cabinetInfo: action.cabinetInfo
            };
        case UPDATE_ANTENNA_STATISTIC_INFO:
            return {
                ...state,
                antennaInfo: action.antennaInfo
            }
        case ALL_DATA:
            return {
                ...state,
                cabinetInfo: action.cabinetInfo,
                antennaInfo: action.antennaInfo
            }
        default:
            return { ...state }
    }
}
export default topoCanvas