import React from 'react';

/**
 * @feature 头部导航星星装饰
 * @export
 * @class Stars
 * @extends {React.Component}
 */
export default class Star extends React.Component {

    render() {

        return (
            <span
                style={{
                    width: '1px',
                    height: '1px',
                    backgroundColor: '#1d89b6',
                    position: 'absolute',
                    top: this.props.top,
                    left: this.props.left,
                    zIndex: 0,
                    borderRadius: '50%',
                }}
            />
        );
    }
}