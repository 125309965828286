/*
 * @Author: zhangq
 * @Date: 2019-11-14 16:42:01
 * @Last Modified by: yek
 * @Last Modified time: 2020-07-02 14:48:23
 */
import React from 'react';
import DragModal from '@/components/DragModal/DragModal';
import MenuTreeSelect from '@/components/MenuTreeSelect/MenuTreeSelect';
import { Form } from '@ant-design/compatible';
import { Input, Select } from 'antd';

const { Option } = Select;
/**
 * @feature 添加首页页面表单
 * @export AddHomeView
 * @class AddHomeView
 * @extends {React.Component}
 */
@Form.create()
class AddHomeView extends React.Component {
    state = {
        layoutType: 1,
    };
    layoutTypeChange = val => {
        this.setState({
            layoutType: val,
        });
    };
    render() {
        let { form } = this.props;
        let { getFieldDecorator } = form;
        let { layoutType } = this.state;
        let formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        };
        return (
            <DragModal title="新建页面" {...this.props} height="260px">
                <Form className="nms-form">
                    <Form.Item label="页面名称" {...formItemLayout}>
                        {getFieldDecorator('layoutName', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '请输入页面名称',
                                },
                                {
                                    max: 20,
                                    message: '页面名称不超过20个字符',
                                },
                            ],
                        })(
                            <Input
                                className="nms-input-bottom"
                                placeholder="请输入页面名称"
                                allowClear
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="页面类型" {...formItemLayout}>
                        {getFieldDecorator('layoutType', {
                            initialValue: layoutType,
                        })(
                            <Select
                                className="nms-select-bottom"
                                onChange={this.layoutTypeChange}
                            >
                                <Option value={1}>自定义视图</Option>
                                <Option value={2}>功能视图</Option>
                                <Option value={3}>外部链接</Option>
                            </Select>
                        )}
                    </Form.Item>
                    {layoutType === 2 ? (
                        <Form.Item label="显示页面" {...formItemLayout}>
                            {getFieldDecorator('pageUrl', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择页面',
                                    },
                                ],
                            })(<MenuTreeSelect />)}
                        </Form.Item>
                    ) : (
                            ''
                        )}
                    {layoutType === 3 ? (
                        <Form.Item label="页面地址" {...formItemLayout}>
                            {getFieldDecorator('pageUrl', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写页面地址',
                                    },
                                ],
                            })(<Input className="nms-input-bottom" />)}
                        </Form.Item>
                    ) : (
                            ''
                        )}
                </Form>
            </DragModal>
        );
    }
}

export default AddHomeView;
