/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-13 15:19:17
 */
import React from 'react';
import DragModal from '@/components/DragModal/DragModal';
import { Form } from '@ant-design/compatible';

import { Input } from 'antd';
@Form.create()
class DeptModal extends React.Component {
    render() {
        const {
            visible,
            onCancel,
            onCreate,
            form,
            deptInfo,
            type,
        } = this.props;
        const { getFieldDecorator } = form;
        const titleObj = {
            add: '新增',
            edit: '编辑',
            addNextDept: '新增子',
        };
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="dept-manager"
                    title={(titleObj[type] || '新增') + '部门'}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Form layout="horizontal" className="nms-form">
                        <Form.Item label="部门名" {...formItemLayout}>
                            {getFieldDecorator('deptName', {
                                initialValue: deptInfo.text,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入部门名',
                                    },
                                    {
                                        max: 10,
                                        message: '部门名不能超过10个字符',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入部门名"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="排序" {...formItemLayout}>
                            {getFieldDecorator('orderNum', {
                                initialValue:
                                    deptInfo.attributes &&
                                    deptInfo.attributes.orderNum,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 99,
                                        min: 1,
                                        transform(value) {
                                            if (value) {
                                                return Number(value);
                                            }
                                        },
                                        type: 'integer',
                                        message: '请输入1 ~ 99的数字',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入1 ~ 99的数字"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="状态" {...formItemLayout}>
                            {getFieldDecorator('deptSign', {
                                initialValue: deptInfo.status,
                                rules: [
                                    {
                                        max: 20,
                                        message: '状态字段不超过20个字符',
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入状态"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        );
    }
}
export default DeptModal;
