/*
 * @Author: yek
 * @Date: 2019-09-16 17:06:23
 * @Last Modified by: yek
 * @Last Modified time: 2020-03-06 16:59:59
 */

import React from 'react';
import DeptModal from './DeptModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable';
import { message, Button, Popconfirm } from 'antd';
import { post, get } from '@/requests/index';
import './deptManager.less';

class DeptManager extends React.Component {
    state = {
        data: [],
        loading: false,
        deptManagerVisible: false, // 编辑和新增模态框
        type: 'add',
        deptInfo: {},
    };
    parentId = ''; // 父级部门的id
    columns = [
        {
            title: '部门名',
            dataIndex: 'text',
            width: '40%',
        },
        {
            title: '排序',
            dataIndex: 'attributes',
            width: '20%',
            render: (value, record, index) => `${value.orderNum}`,
        },
        {
            title: '状态',
            dataIndex: 'deptSign',
            width: '20%',
            render: (value, record, index) => `${value || '--'}`,
        },
        {
            title: '操作',
            width: '20%',
            dataIndex: 'actions',
            render: (value, record, index) => (
                <div className="actions-buttons">
                    <i
                        title="编辑部门"
                        className="fa kdfont fa-edit icon-btn"
                        onClick={this.editDeptModal.bind(this, record)}
                    />
                    {record.isDel === 1 ? (
                        ''
                    ) : (
                        <i
                            title="添加下级"
                            className="fa kdfont fa-plus icon-btn"
                            onClick={this.addNextDept.bind(this, record.id)}
                        />
                    )}
                    <Popconfirm
                        title="确定删除该部门?"
                        onConfirm={() => this.deleteDeptById(record.id)}
                    >
                        <i
                            className="fa kdfont fa-trash-alt icon-btn"
                            title="删除部门"
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    componentDidMount() {
        this.getDeptData();
    }

    // 编辑部门
    editDeptModal = deptInfo => {
        this.setState({
            deptManagerVisible: true,
            type: 'edit',
            deptInfo: deptInfo,
        });
    };
    addNextDept = id => {
        this.setState({
            deptManagerVisible: true,
            deptInfo: {},
            type: 'addNextDept',
        });
        this.parentId = id;
    };
    // 删除部门 通过id
    deleteDeptById = async id => {
        let resData = await post('auth/dept/remove/' + id);
        if (!resData.code) {
            message.error(resData.data || '删除失败');
            return;
        }
        // 刷新表格
        this.getDeptData();
        message.success('删除成功');
    };
    getDeptData = async () => {
        this.setState({ loading: true });
        let resData = await get('auth/dept/tree');
        if (!resData.code) {
            message.error(resData.data || '请求数据失败');
            return;
        }
        const { children } = resData.data;
        this.setState({
            loading: false,
            data: children,
        });
    };
    addDeptModal = () => {
        this.setState({
            deptManagerVisible: true,
            deptInfo: {},
            type: 'add',
        });
    };
    handleCancel = () => {
        this.setState({
            deptManagerVisible: false,
        });
        const { form } = this.formRef.props;
        // 重置表单
        form.resetFields();
    };

    handleCreate = () => {
        const me = this;
        const { form, type, deptInfo } = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            // 重置表单
            form.resetFields();
            let urlStr = 'auth/dept/save';
            let params = values;
            const resultTips = type === 'edit' ? '修改' : '添加';
            // 编辑部门
            if (type === 'edit') {
                urlStr = 'auth/dept/update';
                params.id = deptInfo.id;
            }
            // 增加子部门
            if (type === 'addNextDept') {
                params.parentId = me.parentId;
            }
            // 请求参数
            let resData = await post(urlStr, params);
            if (!resData.code) {
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getDeptData();
            message.success(resultTips + '成功');
            // 保存参数
            this.setState({ deptManagerVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getIcons = props => {
        if (!props.record.children.length) {
            return <span className="indent-span"></span>;
        }
        const className = props.expanded ? 'fa-angle-down' : 'fa-angle-right';
        return (
            <i
                className={'fa kdfont ' + className}
                style={{ fontSize: 16 }}
                onClick={e => props.onExpand(props.record, e)}
            ></i>
        );
    };
    getButtonList = () => {
        return (
            <Button
                title="新增部门"
                className="nms-button"
                onClick={this.addDeptModal}
            >
                <i className="fa kdfont fa-plus"></i>
                增加
            </Button>
        );
    };
    render() {
        const {
            data,
            loading,
            deptManagerVisible,
            type,
            deptInfo,
        } = this.state;
        const parentId = this.parentId;
        return (
            <InnerPage
            {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="dept-manager table-wraper">
                    <div style={{ height: 'calc(100% - 52px)' }}>
                        <ResponsiveTable
                            className="dept-table"
                            columns={this.columns}
                            rowKey={record => record.id}
                            dataSource={data}
                            pagination={false}
                            expandIcon={this.getIcons}
                            loading={loading}
                        />
                    </div>
                    <DeptModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={deptManagerVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        deptInfo={deptInfo}
                        type={type}
                        parentId={parentId}
                    />
                </div>
            </InnerPage>
        );
    }
}
export default DeptManager;
