import React from 'react'
import Login from '@/pages/Login/Login'
import NotFound from '@/pages/NotFound/NotFound'
import IndexRoute from '@/components/IndexRoute/IndexRoute'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

/**
 * @export Router
 * @class Router
 * @extends {React.Component}
 */
export default class RouterComponent extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {window.appConfig.isSinglePage ? (
            <Route
              exact
              path="/"
              render={() => <Redirect to="/client/center/linkCanvasMonitor" />}
            />
          ) : (
            <Route
              exact
              path="/"
              render={() => <Redirect to="/client/home" />}
            />
          )}

          <Route exact path="/client/login" component={Login} />
          <Route exact path="/client/404" component={NotFound} />
          <Route path="/client" component={IndexRoute} />
          <Route render={() => <Redirect to="/client/404" />} />
        </Switch>
      </Router>
    )
  }
}
