/*
 * @Author: yek
 * @Date: 2019-09-16 17:06:23
 * @Last Modified by: yek
 * @Last Modified time: 2020-09-11 16:31:16
 */

import React from 'react';
import MenuModal from './MenuModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable';
import { message, Button, Popconfirm } from 'antd';
import { post, get } from '@/requests/index';
import { saveUserInfo } from '@/redux/userManager/userManager';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './menuManager.less';

// 映射dispatch
const mapDispatchToProps = dispatch => ({
    // 更新机房列表
    saveUserInfo: bindActionCreators(saveUserInfo, dispatch),
});

@connect(null, mapDispatchToProps)
class MenuManager extends React.Component {
    state = {
        data: [],
        loading: false,
        menuManagerVisible: false, // 编辑和新增模态框
        type: 'add',
        menuInfo: {},
    };
    parentId = ''; // 父级菜单的id
    columns = [
        {
            title: '菜单名称',
            dataIndex: 'text',
            width: '14%',
        },
        {
            title: '图标',
            dataIndex: 'attributes',
            align: 'center',
            width: 80,
            render: (value, record, index) => {
                return value.icon ? (
                    <i className={'fa kdfont ' + value.icon}></i>
                ) : (
                        '--'
                    );
            },
        },
        {
            title: '地址',
            dataIndex: 'children',
            width: '30%',
            render: (value, record, index) => {
                let url = record?.attributes?.url || ""

                if (url.indexOf("http") > -1) {
                    url = decodeURIComponent(url)
                }
                return url

            }
        },
        {
            title: '权限标识',
            dataIndex: 'perms',
            width: '10%',
            render: (value, record, index) => `${value || '--'}`,
        },
        {
            title: '排序',
            dataIndex: 'attributes2',
            width: '10%',
            render: (value, record, index) =>
                `${record.attributes.orderNum || '--'}`,
        },
        {
            title: '可见',
            dataIndex: 'visible',
            align: 'center',
            width: 80,
            render: (value, record, index) => record.attributes.visible ? '是' : <span style={{ color: '#da5225' }}>否</span>
        },
        {
            title: '打开方式',
            dataIndex: 'openType',
            align: 'center',
            width: 100,
            render: (value, record, index) => {
                let openTypeStr = '';
                if (record.children.length > 0) {
                    openTypeStr = <span title='父菜单没有跳转目标，默认“内嵌”。'>内嵌</span>;
                } else {
                    openTypeStr = record.attributes.openType === 0 ? '内嵌' : '新窗口';
                };
                return openTypeStr;
            }
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'actions',
            render: (value, record, index) => (
                <div className="actions-buttons">
                    <i
                        title="编辑菜单"
                        className="fa kdfont fa-edit icon-btn"
                        onClick={e => {
                            e.stopPropagation();
                            this.editMenuModal(record);
                        }}
                    />
                    <i
                        title="添加下级"
                        className="fa kdfont fa-plus icon-btn"
                        onClick={e => {
                            e.stopPropagation();
                            this.addNextMenu(record.id);
                        }}
                    />
                    {record.isDel === 1 ? (
                        ''
                    ) : (
                            <Popconfirm
                                title="确定删除该菜单?"
                                onConfirm={e => {
                                    e.stopPropagation();
                                    this.deleteMenuById(record.id);
                                }}
                                onCancel={e => e.stopPropagation()}
                            >
                                <i
                                    className="fa kdfont fa-trash-alt icon-btn"
                                    title="删除菜单"
                                    onClick={e => e.stopPropagation()}
                                />
                            </Popconfirm>
                        )}
                </div>
            ),
        },
    ];
    componentDidMount() {
        this.getMenuData();
    }

    // 编辑菜单
    editMenuModal = menuInfo => {
        this.setState({
            menuManagerVisible: true,
            type: 'edit',
            menuInfo: menuInfo,
        });
    };
    addNextMenu = id => {
        this.setState({
            menuManagerVisible: true,
            menuInfo: {},
            type: 'addNextMenu',
        });
        this.parentId = id;
    };
    // 删除菜单 通过id
    deleteMenuById = async id => {
        let resData = await post('auth/menu/remove/' + id);
        if (!resData.code) {
            message.error(resData.data || '删除失败');
            return;
        }
        // 刷洗表格
        this.getMenuData();
        message.success('删除成功');
    };
    getMenuData = async () => {
        // 更新左侧菜单
        this.updateLeftMenus()
        this.setState({ loading: true });
        let resData = await get('auth/menu/tree');
        if (!resData.code) {
            message.error(resData.data || '请求数据失败');
            return;
        }
        let { children } = resData.data;
        // 将@-@转回#
        children = this.changeKeyword(children);

        this.setState({
            loading: false,
            data: children,
        });
    };
    changeKeyword = children => {
        return children.map(item => {
            return {
                ...item,
                attributes: {
                    ...item.attributes,
                    url: item.attributes.url.replace(/@-@/g, '#')
                },
                children: this.changeKeyword(item.children)
            };
        });
    };
    updateLeftMenus = async () => {
        try {
            let res = await get('auth/user/getCurrentUser');
            if (!res.code) return message.error(res.data || '请求菜单失败');
            this.props.saveUserInfo(res.data);
        } catch (error) {
            window.location.hash = '/client/login';
        }
    };
    addMenuModal = () => {
        this.setState({
            menuManagerVisible: true,
            menuInfo: {},
            type: 'add',
        });
    };
    handleCancel = () => {
        this.setState({
            menuManagerVisible: false,
        });
        const { form } = this.formRef.props;
        // 重置表单
        form.resetFields();
    };

    handleCreate = () => {
        const { form, type, menuInfo } = this.formRef.props;
        // form.getFieldsValue();
        form.validateFields(async (err, values) => {
            if (err) return;
            // values.url = values.url.replace(/#/g, '@-@');
            if (values.url.indexOf("http") > -1) {
                values.url = encodeURIComponent(values.url)
            }
            // 重置表单
            form.resetFields();
            let urlStr = 'auth/menu/save';
            let params = values;
            const resultTips = type === 'edit' ? '修改' : '添加';
            // 编辑菜单
            if (type === 'edit') {
                urlStr = 'auth/menu/update';
                params.id = menuInfo.id;
            }
            // 增加子菜单
            if (type === 'addNextMenu') {
                params.parentId = this.parentId;
            }
            // 请求参数
            let resData = await post(urlStr, params);
            if (!resData.code) {
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getMenuData();
            message.success(resultTips + '成功');
            // 保存参数
            this.setState({ menuManagerVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getIcons = props => {
        if (!props.record.children.length) {
            return <span className="indent-span"></span>;
        }
        const className = props.expanded ? 'fa-angle-down' : 'fa-angle-right';
        return (
            <i
                className={'fa kdfont ' + className}
                onClick={e => props.onExpand(props.record, e)}
            ></i>
        );
    };

    getButtonList = () => {
        return (
            <Button
                title="新增菜单"
                className="nms-button"
                onClick={this.addMenuModal}
            >
                <i className="fa kdfont fa-plus"></i>
                增加
            </Button>
        );
    };
    render() {
        const {
            data,
            loading,
            menuManagerVisible,
            type,
            menuInfo,
        } = this.state;
        const parentId = this.parentId;
        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="menu-manager table-wraper">
                    <div style={{ height: 'calc(100% - 52px)' }}>
                        <ResponsiveTable
                            bordered={false}
                            columns={this.columns}
                            rowKey={record => record.id}
                            dataSource={data}
                            pagination={false}
                            expandIcon={this.getIcons}
                            loading={loading}
                            expandRowByClick={true}
                            className="menu-tree"
                        />
                    </div>
                    <MenuModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={menuManagerVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        menuInfo={menuInfo}
                        type={type}
                        parentId={parentId}
                    />
                </div>
            </InnerPage>
        );
    }
}
export default MenuManager;
