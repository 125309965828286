/*
 * @Author: yek
 * @Date: 2020-01-06 09:57:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-13 16:01:10
 */

// import { post } from '@/requests';
// import { message } from 'antd';

// dictionaryManager
// 更新字典值
const SET_DICTIONARY_TREE = 'SET_DICTIONARY_TREE';
const SET_EDIT_KEY = 'SET_EDIT_KEY';

// 初始状态
const initialState = {
    editKey: '',
    // 字典值
    dictionaryTree: [],
};

export const setDictionaryTree = dictionaryTree  => {
    return {
        type: SET_DICTIONARY_TREE,
        dictionaryTree,
    };
};
export const setEditKey = key => {
    return {
        type: SET_EDIT_KEY,
        editKey: key,
    };
};

// 统计reducer
const dictionaryManager = (state = initialState, action) => {
    if (!action) return state;
    switch (action.type) {
        case SET_DICTIONARY_TREE:
            return {
                ...state,
                dictionaryTree: action.dictionaryTree,
            };
        case SET_EDIT_KEY:
            return {
                ...state,
                editKey: action.editKey,
            };
        default:
            return { ...state };
    }
};
export default dictionaryManager