/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-05-13 15:21:57
 */
import React from "react";
import DragModal from "@/components/DragModal/DragModal";
import RoleSelect from "@/components/RoleSelect/RoleSelect";
import { telReg } from "@/utils/validateReg";
import { Form } from "@ant-design/compatible";
import { get } from "@/requests/index";
import { Input, Radio, message, TreeSelect } from "antd";

class UserModal extends React.Component {
    state = {
        treeData: [], // 部门树数据
    };
    handOk = () => {
        this.props.onCreate();
        // 映射选中的角色id
        this.child.getSelectRoles();
    };
    componentDidMount() {
        this.getDeptTreeData();
    }
    // 获取部门数据
    getDeptTreeData = async () => {
        let resData = await get("auth/dept/tree");
        if (!resData.code) message.error(resData.data || "请求数据失败");
        const { children = [] } = resData.data;
        // 格式化数据
        const targetData = this.formatTreeData(children);
        this.setState({
            treeData: targetData,
        });
    };
    // 格式化数据
    formatTreeData = (data) => {
        let targetData = [];
        data &&
            data.forEach((ele) => {
                ele.key = ele.value = ele.id;
                ele.title = ele.text;
                if (ele.children && ele.children.length) {
                    this.formatTreeData(ele.children);
                }
                targetData.push(ele);
            });
        return targetData;
    };
    render() {
        const { treeData } = this.state;
        const { visible, onCancel, form, userInfo, type } = this.props;
        const passwordVisible = type === "add";
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <div>
                <DragModal
                    className="user-manager"
                    title={(type === "add" ? "新增" : "编辑") + "用户"}
                    visible={visible}
                    onCancel={onCancel}
                    onOk={this.handOk}
                >
                    <Form layout="horizontal" className="nms-form">
                        <Form.Item label="用户名" {...formItemLayout}>
                            {getFieldDecorator("userName", {
                                initialValue: userInfo.userName,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "请输入用户名",
                                    },
                                    {
                                        max: 10,
                                        message: "用户名长度不能超过10个字符",
                                    },
                                ],
                            })(
                                <Input
                                    type="text"
                                    placeholder="请输入用户名"
                                    className="nms-input-bottom"
                                    disabled={type === "edit" ? true : false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="姓名" {...formItemLayout}>
                            {getFieldDecorator("realName", {
                                initialValue: userInfo.realName,
                                rules: [
                                    {
                                        max: 10,
                                        message: "姓名长度不能超过10个字符",
                                    },
                                ],
                            })(
                                <Input
                                    placeholder="请输入姓名"
                                    className="nms-input-bottom"
                                />
                            )}
                        </Form.Item>
                        {passwordVisible ? (
                            <Form.Item
                                label="密码"
                                visible={passwordVisible}
                                {...formItemLayout}
                            >
                                {getFieldDecorator("password", {
                                    initialValue: userInfo.password,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "请输入密码",
                                        },
                                        {
                                            max: 10,
                                            message: "密码长度不超过10个字符",
                                        },
                                    ],
                                })(
                                    <Input
                                        type="password"
                                        placeholder="请输入密码"
                                        className="nms-input-bottom"
                                    />
                                )}
                            </Form.Item>
                        ) : null}
                        <Form.Item label="所属部门" {...formItemLayout}>
                            {getFieldDecorator("deptId", {
                                initialValue: userInfo?.deptId,
                                rules: [{ required: true, message: "请选择部门" }],
                            })(
                                <TreeSelect
                                    className="nms-select-bottom"
                                    style={{ width: "100%" }}
                                    allowClear={true}
                                    dropdownClassName="nms-tree-select-dropdown"
                                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                    treeData={treeData}
                                    placeholder="请选择部门"
                                    treeDefaultExpandAll
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="联系电话" {...formItemLayout}>
                            {getFieldDecorator("mobile", {
                                initialValue: userInfo.mobile,
                                rules: [
                                    {
                                        pattern: telReg,
                                        message: "请输入正确的电话号码",
                                    },
                                ],
                            })(
                                <Input
                                    type="text"
                                    className="nms-input-bottom"
                                    placeholder="请输入联系电话"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="角色" {...formItemLayout}>
                            {getFieldDecorator("roleIds", {
                                initialValue: [],
                                rules: [{ required: true, message: "请选择角色" }],
                            })(
                                <RoleSelect
                                    onRef={(targetCom) => {
                                        this.child = targetCom;
                                    }}
                                    userId={userInfo.id}
                                    width="100%"
                                    form={form}
                                    labelKey={"roleIds"}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="状态" {...formItemLayout}>
                            {getFieldDecorator("status", {
                                initialValue: userInfo.hasOwnProperty("status")
                                    ? userInfo.status
                                    : 1,
                            })(
                                <Radio.Group>
                                    <Radio className="nms-radio" value={1}>
                                        正常
                                    </Radio>
                                    <Radio className="nms-radio" value={0}>
                                        禁用
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>
                </DragModal>
            </div>
        );
    }
}
export default Form.create()(UserModal);
