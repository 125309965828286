import React, { useEffect, useState, useRef } from 'react'
import { Form, Row, Col, Input, Select, message } from 'antd'
import InnerPage from '@/components/InnerPage/InnerPage';
import DragModal from '@/components/DragModal/DragModal'
import { post } from '@/requests'
// import './Yzm.less'

const choices = ['A', 'B', 'C', 'D']
export default function YzmModal(props) {
    const {
        visible,
        setVisible,
        column,
        record,
        setRecord,
        setCondition,
        condition,
        operation,
    } = props
    const [form] = Form.useForm()
    const [colMap, setColMap] = useState([])
    const [code, setCode] = useState()
    const [dictList, setDictList] = useState([])
    const [category, setCategory] = useState()
    const [reocrdInfo, setReocrdInfo] = useState()

    useEffect(() => {
        if (record && operation === 'edit') {
            setReocrdInfo(record)
            form.setFieldsValue({
                ...record,
                monitorTemplateId: record.templateCategory,
                protocolName: record.monitorProtocolId,
            })
            categoryRef.current = record.templateCategory
            typeRef.current = record.templateType
        }
        let cols = []
        cols = column.filter(
            item => !['序号', '操作', '验证码状态', '创建时间'].includes(item.title),
        )
        console.log('cols', cols)
        setColMap(cols)
    }, [column, record])

    const onOk = () => {
        form.submit()
    }
    const onFinish = async params => {
        params.monitorProtocolId = params.protocolName
        params.monitorTemplateId = categoryRef.current
        params.templateType = typeRef.current
        params.id = reocrdInfo?.id
        delete params.protocolName
        let url
        record ? (url = 'kd/center/yzm/update') : (url = 'kd/center/yzm/save')
        const res = await post(url, { ...params })
        if (res.code !== 1) {
            return message.error(res?.data ?? '操作失败')
        }
        setCondition({ ...condition })
        message.success('操作成功')
        closeModal()
    }
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    }
    const closeModal = () => {
        form.resetFields()
        setCode()
        setRecord()
        setVisible(false)
    }
    const categoryRef = useRef()
    const typeRef = useRef()
    const handleChange = i => {
        const option = dictList
            .map(item => {
                if (item.id === i) return item
            })
            .filter(i => i !== undefined)
        categoryRef.current = option[0].dictId
        typeRef.current = option[0].id
    }

    return (
        <InnerPage>
                <div className="user-manager">
        <DragModal
            className="nms-modal"
            width={600}
            // height={'5%'}
            title={record ? '编辑模板' : '新增模板'}
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            mask={false}
        >
            <Form form={form} {...formItemLayout} onFinish={onFinish} className="nms-form">
                <Row>
                    {colMap?.map((item, index) => (
                        <Col span={item.span} key={index}>
                            {item.dataIndex === 'result' ? (
                                <div className="modal-select">
                                    <div className="red-star">*</div>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        label={item.title}
                                        style={{ width: '100%' }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                            }}
                                        >
                                            <Form.Item
                                                name="result"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `请选择${item.title}`,
                                                    },
                                                ]}
                                                noStyle
                                            >
                                                <Select placeholder={`请选择`} className="nms-select-bottom">
                                                    {choices?.map(item => (
                                                        <Select.Option key={item} value={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Form.Item>
                                </div>
                            ) : (
                                <Form.Item
                                    name={item.dataIndex}
                                    label={item.title}
                                    rules={[
                                        {
                                            required: item.dataIndex === 'result' ? true : true,
                                            message: `请选择${item.title}`,
                                        },
                                    ]}
                                >
                                    <img src={record?.filePath} alt="404.png" style={{color:'#fff'}}/>
                                </Form.Item>
                            )}
                        </Col>
                    ))}
                </Row>
            </Form>
        </DragModal>
        </div>
        </InnerPage>
    )
}
