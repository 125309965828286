import Login from '@/pages/Login/Login'; //登录页面
// import Home from '@/pages/Home/Home'; // 首页
import NotFound from '@/pages/NotFound/NotFound'; //404页面
import DeptManager from '@/pages/SystemManager/DeptManager/DeptManager'; //部门管理
import MenuManager from '@/pages/SystemManager/MenuManager/MenuManager'; //菜单管理
import RoleManager from '@/pages/SystemManager/RoleManager/RoleManager'; //角色管理
import UserManager from '@/pages/SystemManager/UserManager/UserManager'; //用户管理
// import AnnouncementManager from '@/pages/AnnouncementManager/AnnouncementManager'; //公告管理
// import CenterMachineroom from '@/pages/Center/CenterMachineroom/CenterMachineroom'; //中心机房
// import CabinetDetail from '@/pages/Center/CabinetDetail/CabinetDetail'; // 机柜详情页面
// import AssetManager from '@/pages/Center/AssetManager/AssetManager'; //中心设备管理
// import HardwareMonitor from '@/pages/Center/HardwareMonitor/HardwareMonitor'; //中心设备监控
// import ServerMonitor from '@/pages/Center/ServerMonitor/ServerMonitor'; //中心服务监控
// import AlarmManager from '@/pages/Center/AlarmManager/AlarmManager'; //中心硬件告警
// import LogMonitor from '@/pages/Center/LogMonitor/LogMonitor'; //中心日志监控
// import FaultTree from '@/pages/Center/FaultTree/FaultTree'; //中心故障树
// import BusinessProcess from '@/pages/Center/BusinessProcess/BusinessProcess'; //中心业务流程
// import AssetTemplateManager from '@/pages/ConfigManager/AssetTemplateManager/AssetTemplateManager'; //设备类型配置
// import ReportConfig from '@/pages/ReportManager/ReportConfig/ReportConfig'; //  报表配置
// import ReportList from '@/pages/ReportManager/ReportList/ReportList'; //  报表预览列表
// import Knowledge from '@/pages/Knowledge/KnowledgeBase/Knowledge'; //知识库
// import KnowledgePlayer from '@/pages/Knowledge/KnowledgePlayer/KnowledgePlayer'; //知识库
// import DocumentManagement from '@/pages/Knowledge/Document/DocumentManagement'; //文档管理
// import MapNodeConfig from '@/pages/ConfigManager/MapNodeConfig/MapNodeConfig'; //节点配置
// import VersionConfig from '@/pages/ConfigManager/VersionConfig/VersionConfig'; //版本配置
// import SystemMonitor from '@/pages/ConfigManager/SystemMonitor/SystemMonitor'; // 系统自监控
import DictionaryManager from '@/pages/ConfigManager/DictionaryManager/DictionaryManager'; //字典配置
// import AlarmRelusConfig from '@/pages/ConfigManager/AlarmRelusConfig/AlarmRelusConfig'; // 告警规则配置
// import InspectionReport from '@/pages/Inspection/InspectionReport/InspectionReport'; // 巡检报告
// import InspectionPlan from '@/pages/Inspection/InspectionPlan/InspectionPlan'; // 巡检计划
// import SignalPlan from '@/pages/Inspection/SignalPlan/SignalPlan'; // 频谱检测计划
// import AssetCaseManage from '@/pages/ZD/AssetCaseManage/AssetCaseManage'; //设备实例管理
// import TaskMonitor from '@/pages/TaskMonitor/TaskMonitor'; // 任务监视
// import DataMonitor from '@/pages/DataMonitor/DataMonitor'; // 数据监视
// import WarningMonitor from '@/pages/ZD/WarningMonitor/WarningMonitor'; // 告警监视
// import TaskStatusMonitor from '@/pages/ZD/TaskStatusMonitor/TaskStatusMonitor'; // 任务状态监视
// import UserMonitor from '@/pages/ZD/UserMonitor/UserMonitor'; //用户信息
// import ComputerRoomMonitor from '@/pages/ZD/ComputerRoomMonitor/ComputerRoomMonitor'; //机房监视
// import LinkMonitor from '@/pages/ZD/LinkMonitor/LinkMonitor'; // 链路监视
// import QueryAlarm from '@/pages/Query/QueryAlarm/QueryAlarm'; // 查询检索
// import QueryLife from '@/pages/Query/QueryLife/QueryLife'; // 查询检索
// import OperationLog from '@/pages/Query/OperationLog/OperationLog'; // 系统操作日志
// import WorkOrder from '@/pages/Alarm/WorkOrder/WorkOrder'; // 工单绩效
// import AlarmWhiteList from '@/pages/Alarm/AlarmWhiteList/AlarmWhiteList'; // 告警白名单
// import FaultAllot from '@/pages/Alarm/FaultAllot/FaultAllot'; // 故障分配
// import RwInspection from '@/pages/Inspection/RwInspection/RwInspection'; // 任务巡检
// import WatchFar from '@/pages/WatchFar/WatchFar'; // 任务巡检
// import IframeComp from '@/components/IframeComp'; // 通用内嵌iframe组件
// import MonitorRoom from '@/pages/Center/MonitorRoom/MonitorRoom'; // 本地机房监视
// import ZdTask from '@/pages/ZD/ZdTask/ZdTask'; // 链路监视待优化
// import EquipmentMonitor from '@/pages/Center/EquipmentMonitor/EquipmentMonitor'; // 设备监控（含硬件监控、服务监控）
// import AlarmMonitor from '@/pages/Center/AlarmMonitor/AlarmMonitor'; // 告警监控（含告警监视、告警策略）
// import PartsManagement from '@/pages/ConfigManager/PartsManagement'; // 告警监控（含告警监视、告警策略）
// import LinkManager from '@/pages/Center/LinkManager/LinkManager'; //链路管理
// import LinkCanvasMonitor from '@/pages/Center/LinkCanvasMonitor/LinkCanvasMonitor'; //链路管理
// // import LinkManagerMonitor from '@/pages/Center/LinkManagerMonitor/LinkManagerMonitor'; //链路管理
// import TopoManager from '@/pages/Center/TopoManager/TopoManager'; //链路管理
// import Maintenance from '@/pages/ConfigManager/Maintenance/Maintenance'; // 保养管理
// import ApplyCenter from '@/pages/Apply'; // 审批中心
// import ApplyStatistic from '@/pages/Apply/Statistic'; // 审批绩效
// import ParamTemplate from '@/pages/ConfigManager/ParamTemplate/ParamTemplate'; //参数模板
// import Prognostics from '@/pages/ConfigManager/Prognostics'; //趋势预测管理
// import Frequency from '@/pages/Center/FrequencyManager'; //趋势预测管理
// import HealthControl from '@/pages/Center/HealthControl'; //趋势预测管理健康管理
// import Network from '@/pages/Center/Network'; //网络监视
// import Docker from '@/pages/ConfigManager/Docker'; //服务容器管理
// import CommandManager from '@/pages/ConfigManager/CommandManager/CommandManager'; //服务容器管理
// import SerialMachine from '@/pages/ConfigManager/SerialMachine/SerialMachine'; //串口设备管理
// import ZbMonitor from '@/pages/Center/LinkMonitor'; // 载波监视
// import ZbMonitorManager from '@/pages/ConfigManager/ZbMonitor'; // 载波监视
// import WorkRemote from '@/pages/Work/WorkRemote'; // 远控工单
// import WhilteList from '@/pages/Work/WhilteList'; // 远控白名单
// import Statellite from '@/pages/Center/Statellite'; // 远控白名单
// import HealthManager from '@/pages/Center/Health'; // 天线健康管理
// import RemoteManager from '@/pages/ConfigManager/RemoteManager'; // 设备远控操作配置
// import WorkReport from '@/pages/ReportManager/Work'; // 用户绩效视图
// import RwReport from '@/pages/ReportManager/Work/rw'; // 用户绩效视图
// import Database from '@/pages/ConfigManager/Database'; //数据备份
// import SystemProcess from '@/pages/Posture/SystemProcess/SystemProcess'; //系统流程
// import SystemPage from '@/pages/Center/System'; //系统监控 集成页面
// import DeviceManager from '@/pages/Center/DeviceManager/DeviceManager'; // 日常维护 设备借出 归还 维修
// import TaskManager from '@/pages/ConfigManager/TaskManager/TaskManager'; // RW管理
// import ProcessManager from '@/pages/ProcessManager' // 流程管理
// import AssetManagerMain from '@/pages/Center/AssetManagerMain/AssetManagerMain';
import Yzm from '@/pages/Yzm/Yzm'; //字典配置

import BannerManager from '@/pages/Wxdc/BannerManager/BannerManager'; //轮播图管理
import ShopManager from '@/pages/Wxdc/ShopManager/ShopManager';
import CategoryManager from '@/pages/Wxdc/CategoryManager/CategoryManager';
import GoodsManager from '@/pages/Wxdc/GoodsManager/GoodsManager';
import CustomerManager from '@/pages/Wxdc/CustomerManager/CustomerManager';
import OrderManager from '@/pages/Wxdc/OrderManager/OrderManager';

export default {
    Login,
    // Home,
    NotFound,
    DeptManager,
    MenuManager,
    RoleManager,
    UserManager,
    // AnnouncementManager,
    // CommandManager,
    // CenterMachineroom,
    // CabinetDetail,
    // AssetManager,
    // HardwareMonitor,
    // ServerMonitor,
    // AlarmManager,
    // LogMonitor,
    // FaultTree,
    // BusinessProcess,
    // AssetTemplateManager,
    // ReportList,
    // ReportConfig,
    // Knowledge,
    // KnowledgePlayer,
    // DocumentManagement,
    // MapNodeConfig,
    // VersionConfig,
    DictionaryManager,
    // AlarmRelusConfig,
    // InspectionReport,
    // InspectionPlan,
    // AssetCaseManage,
    // TaskMonitor,
    // DataMonitor,
    // WarningMonitor,
    // TaskStatusMonitor,
    // UserMonitor,
    // ComputerRoomMonitor,
    // LinkMonitor,
    // QueryAlarm,
    // QueryLife,
    // OperationLog,
    // SystemMonitor,
    // WorkOrder,
    // AlarmWhiteList,
    // FaultAllot,
    // RwInspection,
    // WatchFar,
    // IframeComp,
    // MonitorRoom,
    // ZdTask,
    // EquipmentMonitor,
    // AlarmMonitor,
    // PartsManagement,
    // LinkManager,
    // // LinkManagerMonitor,
    // LinkCanvasMonitor,
    // TopoManager,
    // ApplyCenter,
    // Maintenance,
    // ParamTemplate,
    // Prognostics,
    // Frequency,
    // ApplyStatistic,
    // Network,
    // Docker,
    // HealthControl,
    // SignalPlan,
    // SerialMachine,
    // ZbMonitor,
    // ZbMonitorManager,
    // WorkRemote,
    // WhilteList,
    // Statellite,
    // RemoteManager,
    // HealthManager,
    // WorkReport,
    // RwReport,
    // Database,
    // SystemProcess,
    // SystemPage,
    // DeviceManager,
    // TaskManager,
    // ProcessManager,
    // AssetManagerMain
    Yzm,
    BannerManager,
    ShopManager,
    CategoryManager,
    GoodsManager,
    CustomerManager,
    OrderManager,
};