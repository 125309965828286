import React from 'react';
import DragModal from '@/components/DragModal/DragModal';
import { Row, Col } from 'antd';


import "./AboutUsModal.less"


function getMeta(metaName) {

    const metas = document.getElementsByTagName('meta')
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content')
        }
    }
    return ''
}



class DetailModal extends React.Component {
    state = {
        version: getMeta('version'),
        updateTime: getMeta('update-time'),
        dept: getMeta('dept'),
    }
    getAllData = () => {


    }

    onCancel = () => {
        this.props.onCancel && this.props.onCancel()
    }

    handOk = async () => {
        // This is intentional

    }
    render() {
        const { visible, form, info, type, title } = this.props;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 18 },
        };


        const { version, updateTime, dept } = this.state
        return (
            <DragModal
                className="about-us"
                title={title || '关于我们'}
                visible={visible}
                onCancel={this.onCancel}
                onOk={this.handOk}
                width={600}
                footer={null}
            >
                <div className="about-us-box">
                    <Row>
                        <Col span="6">
                            <div className="about-title">研制单位：</div>
                        </Col>
                        <Col span="18">
                            <div className="about-des">{dept}</div>
                        </Col>
                        <Col span="6">
                            <div className="about-title">当前版本号：</div>
                        </Col>
                        <Col span="18">
                            <div className="about-des">{version}</div>
                        </Col>
                        <Col span="6">
                            <div className="about-title">最后升级时间：</div>
                        </Col>
                        <Col span="18">
                            <div className="about-des">{updateTime}</div>
                        </Col>
                        <Col span="6">
                            <div className="about-title">使用手册：</div>
                        </Col>
                        <Col span="18">
                            <a className="about-des about-des-download" href="http://93.1.6.6/systemdoc/13%20%E4%B8%80%E4%BD%93%E5%8C%96%E5%B9%B3%E5%8F%B0%E8%BF%90%E7%BB%B4%E7%B3%BB%E7%BB%9F_%E8%BD%AF%E4%BB%B6%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.doc" taget="blank">下载</a>
                        </Col>
                        <Col span="6">
                            <div className="about-title">系统维护手册：</div>
                        </Col>
                        <Col span="18">
                            <a className="about-des about-des-download" href="http://93.1.6.6/systemdoc/12%20%E4%B8%80%E4%BD%93%E5%8C%96%E5%B9%B3%E5%8F%B0%E8%BF%90%E7%BB%B4%E7%B3%BB%E7%BB%9F_%E4%BD%BF%E7%94%A8%E5%92%8C%E7%BB%B4%E6%8A%A4%E6%89%8B%E5%86%8C.doc" taget="blank">下载</a>
                        </Col>
                    </Row>

                </div>
            </DragModal>
        );
    }
}
export default DetailModal;
