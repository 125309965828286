/*
 * @Author: yek 
 * @Date: 2019-09-12 11:25:40 
 * @Last Modified by: yek
 * @Last Modified time: 2019-11-26 19:10:06
 */
import React from 'react';
import { message } from 'antd';
import { get } from '@/requests';
import { Select } from 'antd';

const { Option } = Select;
export default class RoleSelect extends React.Component {
    state = {
        roleList: [],
        roles: []
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.userId === this.props.userId) {
            return
        }
        // 查询该用户的角色
        const userId = nextProps.userId;
        this.getRoleListByUserId(userId)
    }

    getRoleListByUserId = (id) => {
        get('auth/role/list' + (id ? '/' + id : '')).then(res => {
            const { code, data } = res;
            if (code) {
                // 格式化数据
                const rolesArr = this.formatterData(data);
                this.setState({
                    roleList: data,
                    roles: rolesArr
                }, () => {
                    this.props.onChange(rolesArr)
                })
            } else {
                message.error(data)
            }
        }).catch(res => {
            message.error(res.data)
        })
    }
    // 格式化数据 获取checked为true 的 节点的id
    formatterData = (data) => {
        let targetData = [];
        data && data.forEach(ele => {
            if (ele.checked) {
                targetData.push(ele.id);
            }
        })
        return targetData;
    }
    componentDidMount() {
        this.props.onRef(this);
        // 查询该用户的角色
        const userId = this.props.userId;
        this.getRoleListByUserId(userId)
    }
    onChange = (selectedKeys) => {
        this.setState({
            roles: selectedKeys
        })
        this.props.onChange(selectedKeys)
    }
    getSelectRoles = () => {
        this.props.onChange(this.state.roles)
    }
    render() {
        const { roleList, roles } = this.state;
        const { width = 12, value } = this.props;
        return (
            <Select className='nms-select-bottom' {...this.props} value={value} onChange={this.onChange} mode="multiple" placeholder='请选择角色' style={{ width: width }} >
                {
                    roleList.map(ele => (
                        <Option key={ele.id} value={ele.id}>{ele.roleName}</Option>
                    ))
                }
            </Select>
        )
    }
}
