import React, { Component } from "react";
import errorCatch from "@/utils/errorCatch";
import DragModal from "@/components/DragModal/DragModal";
import { message, Collapse, Checkbox, Input, Form, Spin } from "antd";
import ResponsiveTable from "@/components/ResponsiveTable/ResponsiveTable";
import { get, post } from "@/requests";
import _ from "lodash";

import "./roleManager.less";
import { load } from "react-intl-universal";

const { Panel } = Collapse;
const { Search } = Input;

let obj = {
    // zd: 'ZD',
    systems: "系统",
    hardware: "硬件",
    link: "链路",
    software: "服务",
    // dailyoperation: "日常运维",
};

class PowerModal extends Component {
    state = {
        panelObj: {},
        activeKey: ["0"],
        powers: {},
        loading: false,
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.visible) {
            // this.getCheckedPower(nextProps.roleInfo.id, true);
        }
    };

    // 获取已选择的权限列表
    getCheckedPower = async (id, isGetList) => {
        let res = await get(`auth/power/list?userId=${id}&userType=1`);
        if (!res || res.code !== 1)
            return message.error(res.data || "获取角色权限失败。");
        this.setState(
            {
                powerObj: res.data,
            },
            () => {
                if (isGetList) {
                    Object.keys(obj).forEach((key) => {
                        let typePowers = res.data[key] || [];

                        let typePowersMap = {};
                        typePowers.forEach((i) => {
                            typePowersMap[i.sourceId] = {
                                isControl: i.isControl || 0,
                                isLookOver: i.isLookOver || 0,
                                isUpdate: i.isUpdate || 0,
                            };
                        });
                        this.getList(key, typePowersMap);
                    });
                }
            }
        );
    };
    // 获取所有阵地列表
    getList = async (type, typePowersMap) => {
        let res = null,
            list = [];
        let { powerObj, panelObj, powers } = this.state;

        if (type === "systems") {
            res = await post("auth/power/getSystem");
            if (!res || res.code !== 1)
                return message.error(res.data || "获取列表失败。");
            list = res.data.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                };
            });
            powers[type] = {};
            list.forEach((i) => {
                if (typePowersMap[i.id]) {
                    powers[type][i.id] = {
                        ...typePowersMap[i.id],
                    };
                } else {
                    powers[type][i.id] = {
                        isControl: 0,
                        isLookOver: 0,
                        isUpdate: 0,
                    };
                }
            });
        }
        panelObj[type] = {
            list,
            key: type,
            title: obj[type],
            searchList: list,
            // selectedRowKeys: [...powerObj[type]],
        };
        this.setState({ panelObj, powers });
    };

    selecteRow = (selectedRows, type) => {
        const { panelObj } = this.state;
        panelObj[type].selectedRowKeys = selectedRows.map((item) => item.id);
        this.setState({ panelObj });
    };

    selecteAll = (e) => {
        let { powers } = this.state;
        let type = e.target.name;
        for (let i in powers[type]) {
            for (let j in powers[type][i]) {
                powers[type][i][j] = e.target.checked ? 1 : 0;
            }
        }

        this.setState({ powers });
    };

    searchList = ({ target: { name, value } }) => {
        let { panelObj } = this.state;
        let searchList = panelObj[name].list.filter((item) => {
            if (item.ip === undefined) {
                if (item.name.indexOf(value) !== -1) return item;
            } else {
                if (item.name.indexOf(value) !== -1 || item.ip.indexOf(value) !== -1) {
                    return item;
                }
            }

            return false;
        });
        panelObj[name].searchList = searchList;
        this.setState({ panelObj });
    };

    queryKeys = (record, type) => {
        let { panelObj } = this.state;
        let { selectedRowKeys } = panelObj[type];
        if (selectedRowKeys.indexOf(record.id) === -1) {
            selectedRowKeys.push(record.id);
        } else {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.id), 1);
        }
        panelObj[type].selectedRowKeys = selectedRowKeys;
        this.setState({ panelObj });
    };

    onOk = async () => {
        const { roleInfo, onOk } = this.props;
        const { powers } = this.state;
        this.setState({
            loading: true,
        });
        let list = [];
        for (let type in powers) {
            for (let i in powers[type]) {
                list.push({
                    ...powers[type][i],
                    sourceId: i,
                    resourceType: type,
                    userType: 1,
                    userId: roleInfo.id,
                });
            }
        }
        let data = {
            userId: roleInfo.id,
            powers: list,
        };
        let res = await post("auth/power/savePower", data);
        this.setState({
            loading: false,
        });
        if (res.code === 1) {
            message.success("修改成功");
            onOk && onOk();
        } else {
            message.error(res.data || "修改失败");
        }
    };

    async commitPower(params) {
        let res = await post("auth/power/update", params);
        if (!res || res.code !== 1)
            return message.error(
                `“${obj[params.resourceType]}”权限提交出错：${
                    res.data || "提交权限出错。"
                }`
            );
        message.success("提交成功。");
    }

    openPanel = (key) => {
        let activeKey = [];
        if (key !== undefined) {
            activeKey = [key];
        }
        this.setState({ activeKey });
    };
    //type 大类 id 权限id value 值
    handleCheckboxChange = (type, id, powerType, e) => {
        const { powers } = this.state;
        powers[type][id][powerType] = e.target.checked ? 1 : 0;
        this.setState({
            powers,
        });
    };
    // 单条全返选
    handleCheckboxAllChange = (type, id, e) => {
        const { powers } = this.state;
        let obj = powers[type][id];
        Object.keys(obj).forEach((key) => {
            powers[type][id][key] = e.target.checked ? 1 : 0;
        });
        this.setState({
            powers,
        });
    };

    render() {
        const { roleInfo } = this.props;
        const { panelObj, activeKey, powers, loading } = this.state;

        return (
            <DragModal
                {...this.props}
                className="power-modal"
                title={`【${roleInfo.roleName}】权限管理`}
                width="1000px"
                height="800px"
                // destroyOnClose={true}
                onOk={this.onOk}
                okButtonProps={{ loading }}
                cancelButtonProps={{ loading }}
            >
                <Spin spinning={loading}>
                    <Form className="nms-form">
                        <Collapse
                            className="nms-collapse"
                            accordion
                            activeKey={activeKey}
                            onChange={this.openPanel}
                        >
                            {Object.keys(panelObj).length &&
                                Object.keys(panelObj).map((item, index) => {
                                    let indeterminate = false;
                                    let checked = true;
                                    for (let i in powers[item]) {
                                        for (let j in powers[item][i]) {
                                            if (powers[item][i][j]) {
                                                indeterminate = true;
                                            } else {
                                                checked = false;
                                            }
                                        }
                                    }
                                    if (checked) indeterminate = false;
                                    return (
                                        <Panel
                                            key={index}
                                            header={panelObj[item].title}
                                            extra={
                                                <div
                                                    className="panel-search-checkbox"
                                                    onClick={(ev) =>
                                                        ev.stopPropagation()
                                                    }
                                                >
                                                    <Search
                                                        allowClear
                                                        name={panelObj[item].key}
                                                        onChange={this.searchList}
                                                        onFocus={() =>
                                                            this.setState({
                                                                activeKey: [`${index}`],
                                                            })
                                                        }
                                                        className="nms-input-bottom"
                                                        placeholder={`请输入搜索${panelObj[item].title}`}
                                                    />
                                                    <Checkbox
                                                        className="nms-checkbox"
                                                        name={item}
                                                        onChange={this.selecteAll}
                                                        indeterminate={indeterminate}
                                                        checked={checked}
                                                    >
                                                        全选
                                                    </Checkbox>
                                                </div>
                                            }
                                        >
                                            {panelObj[item].list.length ? (
                                                <ResponsiveTable
                                                    rowKey={({ id }) => id}
                                                    showHeader={false}
                                                    dataSource={
                                                        panelObj[item].searchList
                                                    }
                                                    scroll={true}
                                                    // rowSelection={{
                                                    //     selectedRowKeys,
                                                    //     onSelect: (record, selected, selectedRows, ev) => this.selecteRow(selectedRows, panelObj[item].key)
                                                    // }}
                                                    onRow={(record) => {
                                                        return {
                                                            // onClick: () => this.queryKeys(record, panelObj[item].key)
                                                        };
                                                    }}
                                                    pagination={{
                                                        className: "nms-pagination",
                                                        columnWidth: 40,
                                                        pageSize: 12,
                                                    }}
                                                    columns={[
                                                        {
                                                            title: "权限",
                                                            dataIndex: "id",
                                                            width: "80px",
                                                            render: (value, record) => {
                                                                let list = Object.values(
                                                                    powers[item][value]
                                                                );
                                                                let indeterminate = list.reduce(
                                                                    (a, b) => a + b
                                                                );
                                                                return (
                                                                    <div className="power-action-box">
                                                                        <Form.Item>
                                                                            <Checkbox
                                                                                className="nms-checkbox"
                                                                                indeterminate={
                                                                                    indeterminate <
                                                                                        3 &&
                                                                                    indeterminate >
                                                                                        1
                                                                                }
                                                                                checked={list.reduce(
                                                                                    (
                                                                                        a,
                                                                                        b
                                                                                    ) =>
                                                                                        a &&
                                                                                        b
                                                                                )}
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    this.handleCheckboxAllChange(
                                                                                        item,
                                                                                        value,
                                                                                        e
                                                                                    );
                                                                                }}
                                                                            ></Checkbox>
                                                                        </Form.Item>
                                                                    </div>
                                                                );
                                                            },
                                                        },
                                                        {
                                                            title: "名称",
                                                            dataIndex: "name",
                                                        },
                                                        {
                                                            title: "IP",
                                                            dataIndex: "ip",
                                                        },
                                                        {
                                                            title: "权限",
                                                            dataIndex: "id",
                                                            render: (value, record) => {
                                                                return (
                                                                    <div className="power-action-box">
                                                                        <Form.Item>
                                                                            {value !==
                                                                                "borrow" &&
                                                                                value !==
                                                                                    "maintain" && (
                                                                                    <Checkbox
                                                                                        className="nms-checkbox"
                                                                                        checked={
                                                                                            powers[
                                                                                                item
                                                                                            ][
                                                                                                value
                                                                                            ][
                                                                                                "isLookOver"
                                                                                            ]
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            this.handleCheckboxChange(
                                                                                                item,
                                                                                                value,
                                                                                                "isLookOver",
                                                                                                e
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        查看
                                                                                    </Checkbox>
                                                                                )}
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            {value ===
                                                                                "borrow" ||
                                                                            value ===
                                                                                "maintain" ? (
                                                                                <Checkbox
                                                                                    className="nms-checkbox"
                                                                                    checked={
                                                                                        powers[
                                                                                            item
                                                                                        ][
                                                                                            value
                                                                                        ][
                                                                                            "isUpdate"
                                                                                        ]
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        this.handleCheckboxChange(
                                                                                            item,
                                                                                            value,
                                                                                            "isUpdate",
                                                                                            e
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    审批
                                                                                </Checkbox>
                                                                            ) : (
                                                                                <Checkbox
                                                                                    className="nms-checkbox"
                                                                                    checked={
                                                                                        powers[
                                                                                            item
                                                                                        ][
                                                                                            value
                                                                                        ][
                                                                                            "isUpdate"
                                                                                        ]
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        this.handleCheckboxChange(
                                                                                            item,
                                                                                            value,
                                                                                            "isUpdate",
                                                                                            e
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    编辑
                                                                                </Checkbox>
                                                                            )}
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            {value !==
                                                                                "borrow" &&
                                                                                value !==
                                                                                    "maintain" && (
                                                                                    <Checkbox
                                                                                        className="nms-checkbox"
                                                                                        checked={
                                                                                            powers[
                                                                                                item
                                                                                            ][
                                                                                                value
                                                                                            ][
                                                                                                "isControl"
                                                                                            ] ===
                                                                                            1
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) => {
                                                                                            this.handleCheckboxChange(
                                                                                                item,
                                                                                                value,
                                                                                                "isControl",
                                                                                                e
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        远控
                                                                                    </Checkbox>
                                                                                )}
                                                                        </Form.Item>
                                                                    </div>
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                />
                                            ) : (
                                                <div className="custom-empty">
                                                    暂无数据
                                                </div>
                                            )}
                                        </Panel>
                                    );
                                })}
                        </Collapse>
                    </Form>
                </Spin>
            </DragModal>
        );
    }
}

export default errorCatch(PowerModal);
