import React, { useEffect, useState, useRef } from 'react'
import { get } from '@/requests'
import { message } from 'antd'

const enumWeek = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
}

const addZero = time => `${time}`.padStart(2, '0')

export default function MainHeaderTime() {
    const timeRef = useRef()
    const minuteRef = useRef()

    const [currentTime, setCurrentTime] = useState(null)

    const { year, month, day, hour, min, sec, week } = currentTime ?? {}

    useEffect(() => {
        initDateTime()
        minuteRef.current = setInterval(initDateTime, 60000 * 20)
        return () => {
            minuteRef.current && clearInterval(minuteRef.current)
            timeRef.current && clearInterval(timeRef.current)
        }
    }, [])
    // 获取服务器时间
    const initDateTime = async () => {
        timeRef.current && clearInterval(timeRef.current)
        const res = await get('system/time')
        if (!res.code) {
            return message.error(res?.data ?? '服务器时间获取失败！')
        }
        let dateTime = Number(res?.data ) ?? new Date()
        const getCurrentTime = time => {
            if (time) {
                dateTime += time
            }
            const now = new Date(dateTime ?? new Date())
            const year = now.getFullYear()
            const month = now.getMonth() + 1
            const day = now.getDate()
            const hour = now.getHours()
            const min = now.getMinutes()
            const sec = now.getSeconds()
            const week = now.getDay()
            setCurrentTime({
                year,
                month,
                day,
                hour,
                min,
                sec,
                week,
            })
        }
        getCurrentTime()
        timeRef.current = setInterval(() => {
            getCurrentTime(1000)
        }, 1000)
    }
    return (
        <>
            {currentTime && (
                <div className="main-header-time">
                    <div className="big-time">
                        {`${addZero(hour)}:${addZero(min)}:${addZero(sec)}`}
                    </div>
                    <div className="small-time">
                        {`${year}-${addZero(month)}-${addZero(day)} 星期${enumWeek[week]}`}
                    </div>
                </div>
            )}
        </>
    )
}
