/*
 * @Author: yek
 * @Date: 2019-09-12 11:25:40
 * @Last Modified by: yek
 * @Last Modified time: 2020-06-30 11:53:57
 */
import React from 'react';
import UserModal from './UserModal';
import InnerPage from '@/components/InnerPage/InnerPage';
import BaseTable from '@/components/BaseTable/BaseTable';
import DragModal from '@/components/DragModal/DragModal';
import { Form } from '@ant-design/compatible';
import { message, Button, Input, Popconfirm } from 'antd';
import { post } from '@/requests/index';
import './userManager.less';

const userListUrl = 'auth/user/page';
@Form.create()
class UserManager extends React.Component {
    state = {
        userManagerVisible: false, // 编辑和新增模态框
        type: 'add',
        userInfo: {},
        resetPwdModal: false, // 重置密码弹窗
    };
    // 当前正在编辑的用户id
    currentEditUserId = '';
    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            align: 'center',
            render: (name, record, index) => `${index + 1}`,
            width: '5%',
        },
        {
            title: '用户名',
            dataIndex: 'userName',
            width: '20%',
            render: (value, record, index) => `${value || '--'}`,
        },
        {
            title: '姓名',
            dataIndex: 'realName',
            render: (value, record, index) => `${value || '--'}`,
            width: '20%',
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
            width: '15%',
            render: (value, record, index) => `${value || '--'}`,
        },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            width: '10%',
            render: (value, record, index) => `${value || '--'}`,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: '10%',
            render: (value, record, index) => `${value ? '正常' : '禁用'}`,
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '15%',
            render: (value, record, index) => {
                return record.isDel === 1 ? (
                    <span title="超级管理员禁止编辑">--</span>
                ) : (
                        <div className="actions-buttons">
                            <i
                                title="重置密码"
                                className="fa kdfont fa-key icon-btn"
                                onClick={this.showResetPassword.bind(
                                    this,
                                    record.id
                                )}
                            />
                            <i
                                className="fa kdfont fa-edit icon-btn"
                                title="编辑用户"
                                onClick={this.editUserModal.bind(this, record)}
                            />
                            <Popconfirm
                                title="确定删除该用户?"
                                onConfirm={() => this.deleteUserById(record.id)}
                            >
                                <i
                                    className="fa kdfont fa-trash-alt icon-btn"
                                    title="删除用户"
                                />
                            </Popconfirm>
                        </div>
                    );
            },
        },
    ];
    showResetPassword = id => {
        this.setState({
            resetPwdModal: true,
        });
        this.currentEditUserId = id;
    };
    // 编辑用户
    editUserModal = userInfo => {
        this.setState({
            userManagerVisible: true,
            type: 'edit',
            userInfo: userInfo,
        });
    };
    // 删除用户 通过id
    deleteUserById = async id => {
        let resData = await post('auth/user/remove/' + id);
        if (!resData.code) {
            message.error(resData.data || '删除失败');
            return;
        }
        this.getTableData({ isDelete: true });
        message.success('删除成功');
    };
    addUserModal = () => {
        this.setState({
            userManagerVisible: true,
            userInfo: {},
            type: 'add',
        });
    };
    handleResetPwdCancel = () => {
        this.setState({
            resetPwdModal: false,
        });
    };
    handleResetPwdOk = () => {
        const me = this;
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            // 重置表单
            form.resetFields();
            let params = values;
            params.userId = me.currentEditUserId;
            // 请求参数
            let resData = await post('auth/user/adminResetPwd', params);
            if (!resData.code) {
                message.error(resData.data || '重置失败');
                return;
            }
            message.success('重置成功');
            // 关闭弹窗
            this.setState({ resetPwdModal: false });
        });
    };
    handleCancel = () => {
        this.setState({
            userManagerVisible: false,
        });
        const { form } = this.formRef.props;
        // 重置表单
        form.resetFields();
    };
    // 获取表格数据
    getTableData = (params = {}) => {
        // 获取设备数据
        this.baseTable && this.baseTable.getTableData(params);
    };
    handleCreate = () => {
        const { form, type, userInfo } = this.formRef.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            // 重置表单
            form.resetFields();
            let urlStr = 'auth/user/save';
            let params = values;
            const resultTips = type === 'add' ? '添加' : '修改';
            if (type === 'edit') {
                urlStr = 'auth/user/update';
                params.id = userInfo.id;
            }
            // 请求参数
            let resData = await post(urlStr, params);
            if (!resData.code) {
                message.error(resData.data || resultTips + '失败');
                return;
            }
            this.getTableData();
            message.success(resultTips + '成功');
            // 保存参数
            this.setState({ userManagerVisible: false });
        });
    };
    saveFormRef = formRef => {
        this.formRef = formRef;
    };
    getButtonList = () => {
        return (
            <Button
                title="新增用户"
                className="nms-button"
                onClick={this.addUserModal}
            >
                <i className="fa kdfont fa-plus"></i>
                增加
            </Button>
        );
    };
    render() {
        const {
            userManagerVisible,
            type,
            userInfo,
            resetPwdModal,
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };
        return (
            <InnerPage
                {...this.props}
                buttonList={this.getButtonList()}
            >
                <div className="user-manager">
                    <BaseTable
                        height="100%"
                        onRef={ref => (this.baseTable = ref)}
                        url={userListUrl}
                        axiosType="get"
                        allColumn={this.columns}
                        onRow={record => {
                            return {
                                onDoubleClick: () => this.editUserModal(record),
                            };
                        }}
                    />
                    <UserModal
                        wrappedComponentRef={this.saveFormRef}
                        visible={userManagerVisible}
                        onCancel={this.handleCancel}
                        onCreate={this.handleCreate}
                        userInfo={userInfo}
                        type={type}
                    />
                    <DragModal
                        className="user-manager"
                        title="重置密码"
                        visible={resetPwdModal}
                        onCancel={this.handleResetPwdCancel}
                        onOk={this.handleResetPwdOk}
                    >
                        <Form layout="horizontal" className="nms-form">
                            <Form.Item label="新密码" {...formItemLayout}>
                                {getFieldDecorator('pwdNew', {
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: '请输入新密码',
                                        },
                                    ],
                                })(
                                    <Input
                                        className="nms-input-bottom"
                                        type="password"
                                        placeholder="输入密码"
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </DragModal>
                </div>
            </InnerPage>
        );
    }
}

export default UserManager;
